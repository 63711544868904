[id^='checkbox-']{
  + label {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 32px;
    padding: 9px;
    border-radius: 50px;
    background-color: $white;
    &::after {
      font-size: 11px;
      line-height: 31px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-right: 6px;
      content: attr(off);
      text-align: right;
      color: $white;
      border-radius: 100px;
      background: $text-dark;
    }
    &::before {
      position: absolute;
      z-index: 300;
      top: 1px;
      left: 2px;
      width: 29px;
      height: 29px;
      content: ' ';
      border-radius: 100px;
      background: $white;
    }
  }
  &:checked + label::before {
    position: absolute;
    top: 1px;
    left: 32px;
    content: '';
    color: $white;
    border-radius: 100px;
  }
  &:checked + label.extended-width {
    &::before {
      left: 46px !important;
    }
  }
  &:checked + label::after {
    font-size: 11px;
    line-height: 31px;
    position: absolute;
    padding-left: 9px;
    content: attr(on);
    text-align: left;
    color: $white;
    background: $logo-light-blue;
  }
}

.checkbox + label {
  &::after,
  &::before {
    transition: all .1s ease-in;
  }
}

.checkbox{
  &.not-visible{
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    position: absolute !important;
    visibility: hidden !important;
    line-height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 0 !important;
  }
  & + label.checkbox-styled {
    position: relative;
    display: inline-block;
    font-weight: normal;
    margin-bottom: 0;

    &:before{
      content: "";
      border: 1px solid #d3d3d3;
      width: 12px;
      height: 12px;
      border-radius: 3px;
      margin-bottom: 2px;
      vertical-align: middle;
      display: inline-block;
    }
    span {
      margin-left: 5px;
      padding: 0;
    }
  }
  &:checked + label.checkbox-styled {
    &:before{
      background-color: #3b99fc;
      border: 0;
    }

    &:after{
      content: "";
      display: inline-block;
      position: absolute;
      border: 1.4px solid #fff;
      border-right: 0;
      border-top: 0;
      width: 8px;
      height: 4px;
      left: 2.5px;
      transform: rotateZ(-56deg) skewX(-15deg);
      top: 6.2px;
    }
  }
}
.list-table-container .checkbox + label.checkbox-styled {
  display: none;
}
.list-table-container .is_opened .checkbox + label.checkbox-styled {
  display: inline-block;
}