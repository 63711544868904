.cal-heatmap-container {
	.graph-rect {
  		fill: #c6c6c6;
  	}

  	.subdomain-text {
  		fill: $white;
  		font-size: 10px;
  	}

  	text.highlight-now {
  		fill: #333;
  	}

  	rect.highlight-now.now {
  		stroke: none;
  	}
}
