.filter-input {
  font-size: 14px;
  width: 220px;
  padding: 8px 15px;
  border: 1px solid $border-dark-gray;
  border-radius: 5px;
  outline: none;
  box-shadow: none;
}

.filter-input-wrap {
  position: relative;
  float: left;
  &::before {
    font: normal normal normal 16px/1 FontAwesome;
    position: absolute;
    top: 10px;
    left: 14px;
    width: 15px;
    height: 15px;
    content: '\f0b0';
    color: $border-light-gray;
  }
  .filter-input {
    padding-left: 38px;
    transition: width .2s linear;
  }
  &.collapsed .filter-input {
    width: 40px;
    padding-left: 0;
    &::-webkit-input-placeholder {
      color: transparent;
    }
  }
}

.content-top {
  position: fixed;
  z-index: 501;
  top: 65px;
  width: calc(100% - 210px);
  height: 65px;
  padding: 15px;
  padding-bottom: 10px;
  transition: width .2s linear;
  border-bottom: 1px solid $border-light-gray;
  background: $select-gray;
  .btn.pull-right {
    position: fixed;
    right: 15px;
  }
  .help-text {
    font-size: 12px;
    line-height: 32px;
    float: right;
    padding: 0 10px;
  }
  .controls {
    margin-top: 0;
    list-style-type: none;
    > li {
      display: inline-block;
      margin-left: 5px;
      padding: 0;
      a,
      button {
        font-size: 14px;
        float: left;
        padding-right: 10px;
        padding-left: 10px;
      }
      a:last-child,
      button:last-child {
        margin: 0;
      }
    }
  }
  .btn-invite {
    float: right;
    margin-right: 7px;
  }
  .actions {
    position: relative;
    &.opened .trigger {
      @include border-bottom-radius(0);
      width: 170px;
      margin-right: 0;
      padding-bottom: 7px;
      border-bottom: 1px solid $border-dark-gray;
      background-color: $white;
      &:focus {
        border-color: $border-dark-gray;
      }
    }
    &.opened .actions-container {
      display: block;
    }
  }
  .apply-btn {
    position: absolute;
    left: -135px;
  }
  .actions-container {
    @include border-bottom-radius(5px);
    position: absolute;
    top: 35px;
    left: 0;
    display: none;
    width: 100%;
    border: 1px solid $border-dark-gray;
    border-top: 0;
    background: $white;
    ul {
      margin: 4px;
    }
    .btn-default {
      line-height: 32px;
      width: 100%;
      height: 32px;
      margin: 0;
      padding: 0 12px;
      color: $text-gray;
      border: 0;
      border-radius: 0;
      background: none;
      &:enabled:hover {
        color: $white;
        background-color: #3875d7;
      }
    }
  }
  .trigger {
    width: 100%;
    margin-right: 7px;
    cursor: pointer;
    background-color: $white;
  }
  .index-btn-region,
  .nav-buttons-region {
    float: right;
  }
  .index-btn-region {
    margin-left: 5px;
  }
}

.content-top {
  .nav-tabs {
    float: left;
  }
  .filter-input-wrap {
    float: right;
  }
  .filter-input-wrap {
    margin-right: 10px;
  }
}

.monitor-page,
.node-conf-container,
.thiamis-conf-container,
.alerts-ct,
.configuration-page {
  .content-top {
    .select2-container {
      float: left;
      width: 253px;
      .select2-selection.select2-selection--single {
        line-height: 27px;
        margin: 0;
        text-align: center;
        border: 1px solid $border-dark-gray !important;
        background: $white;
        display: block;
        text-overflow: ellipsis;
        padding-right: 25px;
        padding-left: 10px;
      }
    }
    .select2-dropdown {
      margin-top: -5px;
      margin-left: 0;
      color: $text-gray;
      border: 1px solid $border-dark-gray;
      border-top: 0;
      background: $white;
    }
    .btn-group {
      .prev {
        @include border-right-radius(0);
        border-right: 0;
      }
      .next {
        @include border-left-radius(0);
        border-left: 0;
      }
    }
    li:last-child {
      margin-right: 225px;
      .back-to-nodes {
        margin-left: 15px;
      }
    }
    .form-group {
      margin-bottom: 0;
      margin-left: 5px;
      color: $text-color;
      border: 1px solid $border-dark-gray;
      border-radius: 5px;
      background: $white;
      i {
        padding: 5px 15px;
      }
    }
  }
  .handle .loader-icon {
    display: none;
  }
}
// .monitor-page .content-top .select2-container {
//   width: 144px;
// }