@import 'monitor/nav-tabs';

.thiamis-status, .sensor-name {
  .device-name {
    padding-left: 15px;
  }
}

.parameters-list-table, .alert-list-table-container .thiamis-list .thiamis-list {
  overflow-y: hidden;
  margin: 0;
  text-align: center;

  .table-wrap .table {
    .tr {
      height: 35px;
    }
  }
  .thead {
    border-bottom: 1px solid $border-light-gray;
    .th {
      font-size: 14px;
      font-weight: 400;
      flex: 100%;
      max-width: 140px;
      min-width: 140px;
      height: 40px;
      vertical-align: middle;
      color: $text-dark;
      div {
        text-align: center;
        white-space: nowrap;
      }
      &:nth-child(2){
        padding-left: $parameters-table-timestamp-width + 10px;
        max-width: 280px;
        min-width: 280px;
        width: 280px;
      }
      &:nth-child(2){
        &.mass-conc-total, &[class*="a-v-g"], &[class*="m-a-x"] {
          width: 340px;
          max-width: 340px;
        }
        &[class*="mass-conc-total"][class*="a-v-g"],
        &[class*="mass-conc-total"][class*="m-a-x"],
        &[class*="mass-conc-total"][class*="m-i-n"]{
          width: 440px;
          max-width: 440px;
        }
        &[class*="outside"]{
          width: 360px;
          max-width: 360px;
        }
      }
      &.mass-conc-total{
        width: 190px;
        max-width: 190px;
        min-width: 190px;
      }
      &[class*="a-v-g"], &[class*="m-i-n"], &[class*="m-a-x"] {
        width: 200px;
        max-width: 200px;
        min-width: 200px;
      }
      &[class*="mass-conc-total"][class*="a-v-g"],
      &[class*="mass-conc-total"][class*="m-i-n"],
      &[class*="mass-conc-total"][class*="m-a-x"]{
        width: 290px;
        max-width: 290px;
        min-width: 290px;
      }
      &[class*="outside"]{
        min-width: 225px;
        max-width: 225px;
        width: 225px;
      }
      &:first-child {
        line-height: 22px;
        position: absolute;
        z-index: 7;
        width: $parameters-table-timestamp-width;
        div a{
          top: 0px !important;
        }
      }
    }
  }
  .selected {
    color: #05479e;
  }
  .tbody {
    &:last-child{
      border-bottom: 1px solid $border-light-gray;
    }
    .tr {
      position: relative;
      top: 1px;
      cursor: default;
      &:hover {
        background: none !important;
      }
      .td {
        font-size: 14px;
        line-height: 34px;
        height: 35px;
        width: 140px;
        max-width: 140px;
        min-width: 140px;
        padding: 0;
        text-align: center;
        color: $icon-gray;
        border-top: 0;
        border-bottom: 1px solid $border-light-gray;
        &:first-child {
          position: absolute;
          width: $parameters-table-timestamp-width;
          background: $white;
          border-right: 1px solid $border-light-gray;
        }
      }
    }
  }
  .tbody,
  .thead {
    .td {
      width: 340px;
    }
    .td:nth-child(2){
      width: 285px;
      max-width: 285px;
      min-width: 285px;
      padding-left: $parameters-table-timestamp-width;
    }
    .td:nth-child(2){
      &.td.mass-conc-total, &[class*="a-v-g"], &[class*="m-i-n"], &[class*="m-a-x"] {
        min-width: 340px;
      }
      &.td[class*="mass-conc-total"][class*="a-v-g"], &[class*="mass-conc-total"][class*="m-i-n"], &[class*="mass-conc-total"][class*="m-a-x"] {
        min-width: 440px;
      }
      &.td[class*="outside"]{
        width: 360px;
        max-width: 360px;
      }
    }
    .td.mass-conc-total{
      width: 190px;
      min-width: 190px;
      max-width: 190px;
    }
    .td[class*="a-v-g"], .td[class*="m-i-n"], .td[class*="m-a-x"] {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }
    .td[class*="mass-conc-total"][class*="a-v-g"],
    .td[class*="mass-conc-total"][class*="m-i-n"],
    .td[class*="mass-conc-total"][class*="m-a-x"]{
      width: 290px;
      max-width: 290px;
      min-width: 290px;
    }
    .td[class*="outside"] {
      min-width: 225px;
      max-width: 225px;
      width: 225px;
    }
  }
  nav {
    width: 100%;
    height: $chart-box-fotter-height;
    border-top: 1px solid $border-light-gray;
    border-radius: 0;
    background: $light-gray;
    margin-top: -2px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 1;
    .pagination {
      margin: 0;
      li {
        &:first-child a {
          border-left: 0;
          border-radius: 0;
        }
        &:last-child a {
          border-right: 0;
        }
        a {
          font-weight: bold;
          padding: 10px 12px;
          cursor: pointer;
          color: $text-dark;
          border-top: 0;
          border-bottom: 0;
          background: $light-gray;
          &:hover {
            background: #e8e8e8;
          }
          i {
            padding: 0 3px;
          }
        }
        &.active a {
          cursor: default;
          border-color: $border-light-gray;
          background: $grey;
        }
        &.disabled a:hover {
          cursor: default;
          background: $light-gray;
        }
      }
      &.pagination-expanded {
        position: relative;
        display: flex;
        justify-content: center;
        li {
          &:first-child {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            position: absolute;
            top: 50%;
            right: calc(50% + 260px);
            transform: translateY(-50%);
            a {
              border-right: 0;
            }
          }
          &:last-child {
            flex: 1;
            display: flex;
            justify-content: flex-start;
            position: absolute;
            top: 50%;
            left: calc(50% + 260px);
            transform: translateY(-50%);
            a {
              border-left: 0;
            }
          }
        }
        a {
          min-width: 50px;
          user-select: none;
        }
      }
    }
  }
}

.monitor-page {
  overflow-y: auto;
  height: 100%;
  padding: 80px 0 0;
  background: $select-gray;
  .container {
    width: 100%;
    max-width: none;
    padding: 0 15px;
  }
  .location-caption {
    font-size: 12px;
    padding: 10px;
    border-top: 1px solid $border-light-gray;
    border-radius: 5px;
    background: $white;
    span {
      font-weight: 400;
      font-style: normal;
    }
  }
  .add-to-dashboard {
    padding-right: 13px;
    padding-left: 15px;
    margin-right: -7px;
    i {
      margin-right: 7px;
    }
  }
  .sensor-icon {
    float: right;
    width: 60px;
    margin-left: 15px;
  }
  .datapoints-region .device-name .truncated {
    padding-bottom: 2px;
    margin-top: -2px;
  }
  .datapoints-region .device-status i {
    top: -6px !important;
  }
  .sensor_widget {
    .info-captions {
      *[class*='rae'] {
        width: 65px;
        height: 60px;
        margin-left: 5px;
        .sensor-icon {
          width: 60px;
          height: 50px;
          margin-right: 10px;
          margin-left: 20px;
        }
      }
    }
    .sensors-region {
      .device-name {
        .truncated {
          max-width: 160px;
        }
        small {
          display: block;
          margin-left: 12px;
          margin-top: 5px;
        }
      }
      .row {
        margin-right: -7.5px;
        margin-left: -7.5px;
      }
      .col-sm-4 {
        width: auto;
        min-width: 250px;
        padding: 0 7.5px;
      }
    }
    .form-box {
      position: relative;
      min-height: 105px;
      .configuration-info .info-captions {
        padding: 0;
      }
      h4 {
        padding: 10px 0 0;
        margin-bottom: 0;
      }
    }
    .auto-zero {
      margin: 0 0 10px 50px;
      padding: 1px 15px;
    }
    .switch-button {
      position: absolute;
      left: 27px;
      bottom: 7px;
      label {
        width: 50px;
        height: 18px;
        &::before {
          width: 15px;
          height: 15px;
        }
        &::after {
          line-height: 17px;
        }
        &:checked + label::after {
          line-height: 17px;
        }
        &:checked + label::before {
          left: 31px;
        }
      }
    }
  }
  .thiamis-info {
    display: table;
    float: left;
    height: 60px;
    margin-top: 20px;
    b {
      display: inline-block;
      width: 60px;
      padding-right: 15px;
      text-align: right;
    }
    li {
      display: table-row;
      &:only-child {
        line-height: 50px;
      }
    }
  }
  .sensor-info-list {
    float: left;
    margin-left: 22px;
    margin-top: 8px;
    li {
      line-height: 22px;
    }
    b {
      display: inline-block;
      width: 30px;
    }
  }
  .thiamis-box {
    padding-bottom: 0;
    .form-control {
      display: none;
    }
    .location-caption {
      position: relative;
      z-index: 300;
      span {
        line-height: 32px;
      }
    }
    .settings {
      z-index: 300;
      top: 3px;
    }
    .auto-switch {
      font-size: 12px;
      font-weight: bold;
      line-height: 29px;
      display: none;
      float: right;
      padding: 0 0 0 10px;
      color: $font-7;
      border-left: 1px dashed $border-dark-gray;
      input {
        margin-right: 2px;
      }
    }
    .manual-address input {
      float: left;
      width: 84%;
    }
    .configuration-form {
      margin-top: 0;
    }
    .col-sm-8 {
      padding: 0;
    }
    form {
      border-top: 1px dashed $border-dark-gray;
      border-bottom: 1px dashed $border-dark-gray;
      .form-group {
        margin-bottom: 3px;
        border: 0;
        &:last-child {
          margin-bottom: 5px;
          padding: 0;
        }
        span {
          font-size: 14px;
          margin: 0;
          color: $text-dark;
        }
        input {
          display: none;
        }
        p {
          font-size: 14px;
          margin: 0;
          color: $text-dark;
        }
      }
    }
  }
  .widget.col-sm-4 {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .widget-container {
    border-radius: 0;
    box-shadow: none;
    .widget-header {
      display: none;
    }
  }
  .nav-buttons-region {
    .btn-group {
      margin: 0 3px 0 10px;
    }
  }
  .select-all-wrap {
    display: none;
  }
  .form-box {
    padding-bottom: 0;
    border: 0;
    h3 {
      text-transform: none;
    }
    .col-sm-6 {
      &:first-child {
        ul {
          float: left;
        }
      }
      &:last-child {
        border-left: 1px dashed $border-dark-gray;
        .caption {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
          color: $text-dark;
          i {
            font-size: 12px;
            color: $icon-gray;
          }
          img {
            max-width: 16px;
            margin-top: -3px;
            margin-right: 2px;
          }
        }
      }
    }
    .settings {
      .btn-sm {
        margin-top: 3px;
        margin-right: 3px;
        padding: 5px 10px;
        border: 0;
        &:hover {
          background-color: $white;
        }
        &:active {
          background-color: $white;
          box-shadow: none;
        }
      }
    }
    label {
      font-size: 12px;
      color: $icon-gray;
    }
    &.thiamis-box,
    &.chart-box {
      padding: 0;
    }
    .general-info-wrap {
      float: left;
      width: 380px;
      padding: 0;
      h3 {
        padding: 15px;
      }
      form {
        padding: 10px 15px;
      }
      .thiamis-img {
        position: relative;
        height: 80px;
        margin: 0 15px;
        text-align: center;
        &.thiamis-820g {
          background-position: center 5px;
        }
        &.thiamis-1000 {
          height: 100px;
        }
        &.airthinxpro {
          height: 140px;
        }
      }
    }
    .map-wrap {
      float: left;
      width: calc(100% - 380px);
      min-height: 240px;
    }
  }
  .form-box.view-admin_settings{
    #admin_settings-content {
      margin-top: 25px;
    }
    .form-group .control-label {
      font-size: 14px;
      color: #666;
      padding-top: 12px;
    }
    .endpoint-custom {
      display: none;
      &.active {
        display: block;
      }
    }
    .col-sm-6:last-child {
      border: none;
    }
    .form-group .col-sm-2 {
      position: relative;
      top: 3px;
      margin-left: -15px;
    }
    .select2-selection.select2-selection--multiple {
      height: 47px;

      .select2-selection__choice__remove {
        font-size: 16px;
        position: relative;
        top: 1px;
        margin-right: 4px;
      }
      &:after{
        display: none;
      }
    }
    .select2-search.select2-search--inline {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .chart-box {
    .chart-wrap {
      width: calc(100% - 224px);
      padding-right: 0;
    }
    &.measurements-collapsed {
      .chart-wrap {
        width: 100%;
      }
      .measurements-wrap {
        width: 0;
      }
    }
    .measurements-wrap {
      position: relative;
      float: right;
      width: 224px;
      padding-left: 0;
    }
    .debug-wrap,
    .debug-info-tags-region,
    .debug-updates {
      display: none;
    }
    .unchoose-all {
      display: none;
    }
    .recent_measurements_widget {
      min-width: 0;
      box-shadow: -3px 2px 4px rgba(0, 0, 0, .08);
      td h4.device-name small {
        visibility: hidden;
      }
      .table {
        tr {
          float: none;
          td {
            .name {
              color: $icon-gray;
            }
            h4 {
              font-size: 12px;
              padding: 0;
              .device-status i {
                font-size: 6px;
                position: relative;
                top: -3px;
              }
            }
          }
          &.offline {
            td {
              p .value-wrap * {
                color: $border-dark-gray;
              }
              .sparkline {
                path {
                  stroke: $border-dark-gray;
                }
              }
            }
          }
          &:hover {
            td {
              background: $select-gray;
              h4 small {
                visibility: visible;
              }
            }
          }
        }
      }
    }
    h3 {
      font-weight: normal;
      position: relative;
      padding: 10px 15px;
      padding-bottom: 0;
      text-transform: none;
      border-bottom: 1px solid $border-light-gray;
      &.rotate-title {
        font-size: 16px;
        display: none;
        width: 100%;
        padding: 60px 0;
        text-align: center;
        border: 0;
      }
      span {
        font-weight: 400;
        float: none;
        margin-right: 5px;
      }
      a, button {
        color: $icon-gray;
      }
      i {
        margin-right: 5px;
      }
      .col-sm-8 {
        padding-right: 15px;
      }
      .btn-group {
        position: relative;
        top: 0;
        left: 0;
      }
      .btn {
        margin: 0;
        padding: 5px;
        border: 0;
        &:hover {
          color: #666;
        }
        &.toggle-measurements {
          border: 0;
        }
        &.active {
          color: $text-dark;
          border-color: #adadad;
          background: $select-gray;
        }
      }
      > .row {
        > .col-sm-8 {
          width: calc(100% - 224px);
          text-align: right;
        }
        > .col-sm-4 {
          width: 224px;
          margin-top: -10px;
          height: 49px;
          line-height: 49px;
          border-left: 1px solid #dfdfdf;
          padding-left: 15px;
        }
        .unchoose-all {
          font-size: 12px;
          &:hover i {
            color: #666;
          }
        }
        .timezone {
          line-height: 26px;
          display: inline-block;
        }
        .timezone-short {
          float: right;
          margin-top: 8px;
        }
      }
      .form-group {
        margin-bottom: 0;
      }
      .datepicker-region {
        position: relative;
        float: right;
        margin-left: 15px;
        i {
          position: absolute;
          top: 8px;
          left: 8px;
          color: #999;
        }
        input {
          font-size: 12px;
          line-height: 18px;
          width: 110px;
          height: auto;
          padding: 5px 10px;
          padding-left: 30px;
          cursor: pointer;
          transition: width .2s linear;
          text-align: center;
          &.custom-range {
            width: 280px;
          }
        }
      }
      .settings {
        position: absolute;
        top: 0;
        right: 5px;
        text-align: right;
        opacity: 1;
        line-height: 47px;
        .btn-sm {
          margin: 0;
          padding: 1px 6px;
          color: #777;
          border-color: $border-light-gray;
        }
      }
      .select-all {
        display: none;
        margin-left: 110px;
        i {
          float: left;
          margin-top: 4px;
          color: #00ba63;
          &::before {
            content: $fa-var-circle-o;
          }
        }
        .btn {
          width: 100px;
        }
        &.selected {
          background: $select-gray;
          i::before {
            content: $fa-var-circle;
          }
          .btn {
            background: $select-gray;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
          }
        }
      }
    }
    &.view-debug {
      .chart-wrap {
        width: 100%;
      }
      .debug-updates {
        display: inline-block;
        margin-left: 6px;
        padding: 4.5px 9px;
        &.paused i::before {
          content: $fa-var-play;
        }
      }
    }
    &.view-chart {
      .unchoose-all {
        display: block;
        line-height: 50px;
      }
      .disabled .table {
        tr:not(.chosen) {
          &:hover td {
            background: $white;
          }
          td p span {
            color: $border-light-gray;
          }
          td {
            cursor: default;
          }
        }
        .chosen {
          opacity: 1;
          &:hover {
            td {
              background: $select-gray;
            }
          }
          td {
            cursor: pointer;
            .name .device-name b {
              font-weight: bold;
            }
          }
        }
      }
    }
    &.view-polar-chart {
      .recent_measurements_widget {
        tr {
          display: none;
        }
        tr[class*="wind-direction"], tr[class*="wind-speed-avg"] {
          display: block;
        }
        tr {
          td {
            background: $select-gray;
          }
        }
        td {
          cursor: default;
          .name .device-name .truncated {
            font-weight: bold;
            color: #666;
          }
        }
      }
    }
    &.view-files {
      .chart-wrap {
        width: 100%;
      }
      .measurements-wrap,
      h3 > .row .timezone,
      .datepicker-region,
      .toggle-measurements {
        display: none;
      }
      .files-wrap {
        display: block;
      }
    }
    &.view-table {
      .recent_measurements_widget tr.dp-location {
        display: block;
      }
      .chart_table_widget {
        padding: 0;
      }
      .select-all-wrap {
        display: inline;
        float: left;
        margin: 0;
        input {
          margin-right: 5px;
          position: relative;
          top: 2px;
        }
      }
      .sparkline {
        display: none;
      }
      .download-chart {
        display: inline;
      }
      .datapoints-region table tr {
        float: none;
      }
      .recent_measurements_widget {
        td {
          cursor: pointer;
          p {
            width: 100%;
          }
        }
        .table tr {
          opacity: 1;
          td .name {
            color: $icon-gray !important;
          }
          &:hover {
            td {
              cursor: pointer;
              background: $select-gray;
            }
          }
        }
      }
      .chosen {
        td {
          color: #555;
          .name .device-name .truncated {
            font-weight: bold;
            color: #666;
          }
        }
      }
    }
    .chart_table_widget {
      float: left;
      height: $chart-box-container-height;
    }
    .parameters-list-table nav,
    .tech-datapoints-region {
      height: $chart-box-fotter-height;
    }
    .parameters-list-table .table-wrap{
      height: $chart-box-container-height - $chart-box-fotter-height;
      overflow-y: hidden;
    }
    .thiamis-widget-content {
      height: $chart-box-container-height - $chart-box-fotter-height + 2;
      overflow-y: hidden;
    }
    .thiamis-widget-content {
      overflow: hidden;
      box-shadow: inset 0 -10px 10px -10px #d6d6d6;
      &:hover {
        overflow-y: auto;
      }
      .table tr.offline {
        opacity: .75;
      }
    }
  }
  .recent_measurements_widget {
    position: absolute;
    margin: 0;
    svg {
      fill: currentColor;
    }
    tr:hover {
      background: none;
      td {
        background: $select-gray;
      }
    }
    td {
      position: relative;
      cursor: pointer;
      color: $icon-gray;
      p {
        position: relative;
        display: inline-block;
        margin: 0;
        padding-left: 8px;
        float: left;
        span {
          font-size: 14px;
          padding-right: 3px;
        }
        .name {
          font-size: 12px;
          float: left;
          width: 100%;
          min-width: 35%;
          margin-right: 15px;
          padding: 0;
          color: $icon-gray;
        }
        .value-wrap {
          float: left;
          color: #777;
        }
      }
      .sparkline {
        display: block;
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 90px;
        padding-left: 5px;
      }
    }
    .last-updated-ts {
      text-align: right;
    }
    .chosen td {
      background-color: $select-gray;
      &:hover {
        background-color: $select-gray;
      }
    }
    .active {
      .display-name .fa-spin::before {
        content: $fa-var-refresh;
      }
    }
    .local-time {
      text-align: left;
      font-size: 12px;
      .fa-clock-o.disabled {
        display: none
      }
    }
    .node-widget-content {
      min-height: 150px;
      max-height: none;
    }
    .content-region {
      max-height: none;
    }
    .widget-header {
      border-bottom: 1px solid $border-light-gray;
    }
    .widget-container {
      overflow: visible;
      border: 0;
      border-left: 1px solid $border-light-gray;
      box-shadow: none;
    }
    .tech-datapoints-region .tech-datapoints {
      font-weight: bold;
      margin: 0;
      padding: 11px 0 10px;
      .col-sm-9 {
        padding-right: 0;
      }
      .col-sm-3 {
        padding-left: 0;
      }
    }
    .widget-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  .chart_table_widget {
    height: auto;
    margin-bottom: 0;
    .highcharts-navigator rect {
      fill: rgba(251, 251, 251, .5);
    }
    .widget-header {
      display: none;
    }
    .widget-container {
      overflow: visible;
      height: 100%;
      margin-bottom: 0;
      padding: 0;
      background: $white;
    }
    .content-region {
      height: 100%;
      max-height: 100%;
    }
    .chart-region {
      height: 70%;
    }
    .chart-parameters {
      padding: 0 9px;
      .sensor-list {
        display: flex;
        width: 100%;
        max-height: none;
        margin-right: 0;
        margin-bottom: 6px;
        margin-left: 0;
        padding: 0;
        justify-content: center;
        .sensor-box-container {
          min-width: 0;
          max-width: 270px;
          padding: 0 5px;
        }
        .sensor-box {
          position: relative;
          min-height: 120px;
          padding: 0 15px;
          background: $light-gray;
          border: 0;
          .settings {
            display: none;
          }
          &:hover .settings {
            display: block;
          }
          h1 {
            font-size: 22px;
            font-weight: 400;
            margin: 0;
          }
          h2 {
            font-size: 14px;
            font-weight: bold;
            position: relative;
            bottom: 0;
            width: 100%;
            margin: 0;
            margin-bottom: 5px;
            text-align: center;
            small {
              font-size: 80%;
            }
          }
          h3 {
            font-size: 13px;
            font-weight: 400;
            margin: 0 0 10px;
            padding: 0 0 8px;
            text-align: center;
            color: $text-dark;
            border-bottom: 1px dashed $border-light-gray;
          }
          .parameter-name {
            position: relative;
            height: auto;
            padding-top: 15px;
            //min-height: 45px;
          }
          .sensor-name {
            min-height: 22px;
            > :first-child  {
              text-align: center;
              display: block;
              max-width: 100%;
            }
          }
          .parameter-statistics {
            float: left;
            width: 33.33%;
            padding: 0 2.5px;
            overflow: hidden;
            div {
              padding: 2px;
              border-radius: 5px;
              background: $white;
            }
            p {
              margin: 0 0 5px;
              text-align: center;
              text-transform: uppercase;
            }
            h2 {
              font-size: 12px;
              font-weight: bold;
            }
          }
          &.empty {
            h3 {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .thiamis_info_widget {
    .widget-header {
      margin-bottom: 15px;
      border-bottom: 1px solid $border-light-gray;
    }
    .widget-body {
      h3 span {
        font-size: 14px;
      }
      label {
        font-size: 14px;
        display: block;
        float: left;
        width: 177.5px;
        margin: 0;
        padding-right: 13px;
        text-align: right;
        color: $text-dark;
      }
      .form-group span {
        display: block;
        float: left;
        width: calc(100% - 177.5px);
      }
    }
    .state-a {
      color: #49b96d;
    }
    .state-d {
      color: #cc4f4f;
    }
    .sensor-box {
      padding: 0;
      box-shadow: 0;
      p {
        margin: 0;
      }
    }
    .device-name {
      padding-bottom: 5px;
      .truncated {
        max-width: 325px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        height: 16px;
      }
    }
  }
  .sensor_widget,
  .thiamis_info_widget {
    .widget-container {
      overflow: visible;
    }
  }
  .parameters-list-table .list-table .table {
    table-layout: auto;
  }
  .parameters-list-table {
    .list-table {
      position: relative;
      z-index: 20;
      background: none;
      min-height: 508px;
      .table-wrap {
        z-index: 2;
      }
      .th small {
        font-size: 65%;
        position: absolute;
        bottom: -11px;
        display: block;
        width: 100%;
      }
    }
    .td span:nth-child(2):before {
      content: ';'
    }
    &::after {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 41px;
      content: '';
      border-bottom: 1px solid $border-light-gray;
    }
  }
}

.nav-minified .monitor-page {
  .content-top {
    width: calc(100% - 48px);
  }
}

.thiamis-info-params {
  .big-sensor-box {
    position: relative;
    overflow: visible;
    height: auto;
    min-height: 40px;
    padding: 0 10px;
    text-align: left;
    border-top: 1px dashed $border-light-gray;
    margin-top: 3px;
    p {
      margin-bottom: 5px;
    }
  }
}

.dev-status {
  font-size: 12px;
  position: absolute;
  bottom: 18px;
  left: 15px;
  color: $text-dark;
  span span {
    display: none;
  }
  .param-info-icon {
    display: inline-block;
    margin-right: 15px;
    position: relative;
    top: 9px;
    span {
      margin-left: 5px;
    }
  }
  .network {
    font-size: 11px;
    position: relative;
    top: 9px;
    left: -5px;
    display: inline;
  }
  .signal {
    display: inline;
    .network {
      margin-left: -3px;
    }
  }
}

.recent-collapse {
  display: none;
}

.debug-info-wrap {
  display: none;
}

.view-debug {
  .debug-info-wrap {
    display: block;
  }
  .toggle-measurements {
    display: none;
  }
}

.thiamis-debug-info-region {
  .col-sm-9 {
    float: left;
    width: calc(100% - 239px);
    height: 545px;
    padding-right: 0;
  }
  .list-table{
    height: 510px;
    overflow: scroll;
    table.table {
      font-size: 12px;
    }
    .timestamp-col {
      width: 25%;
    }
    .message-col {
      width: 75%;
      div {
        white-space: pre-wrap;
      }
    }
  }
  .col-sm-3 {
    float: left;
    width: 239px;
    height: 545px;
    padding-left: 0;
    border-left: 1px solid #ddd;
  }
  .table-responsive {
    height: 506px;
    table {
      margin: 0;
      th {
        border-bottom: 1px solid $border-light-gray;
        &:first-child {
          border-right: 1px solid $border-light-gray;
        }
      }
      tr td {
        font-size: 12px;
        padding: 3.5px 8px;
        color: $icon-gray;
        border: 0;
        &:first-child {
          width: 155px;
          border-right: 1px solid $border-light-gray;
        }
      }
    }
  }
  .filters-region {
    overflow: hidden;
    height: 100%;
    max-height: 338px;
    border-bottom: 1px solid $border-light-gray;
    .panel {
      .panel-collapse.collapse.in {
        max-height: 301px;
        box-shadow: inset 0 -10px 10px -10px #d6d6d6;
      }
      .panel-title {
        padding: 5px 0;
        color: $text-dark;
        a:hover {
          color: $text-dark;
        }
      }
      .panel-heading {
        padding: 5px 8px;
        border-bottom: 1px solid $border-light-gray;
        border-radius: 0;
        background: $select-gray;
        .badge {
          background: #777;
        }
      }
    }
  }
  nav {
    margin-top: 0;
    border-top: 1px solid $border-light-gray;
    background: $light-gray;
    .pagination {
      margin: 0;
      border-radius: 0;
      li {
        &.active a {
          border-color: $border-light-gray;
          background: #e6e6e6;
        }
        a {
          font-weight: bold;
          display: inline-block;
          float: none;
          padding: 8px 12px;
          color: $text-dark;
          border-top: 0;
          border-bottom: 0;
          border-radius: 0;
          background: none;
          &:hover {
            color: $text-dark;
            border-color: $border-light-gray;
            background: #e6e6e6;
          }
        }
      }
    }
  }
}

#thiamis-debug-tag {
  ul li {
    padding: 8px;
    a {
      display: block;
      width: 100%;
      .truncated {
        width: 80%;
      }
    }
    .badge {
      float: right;
      margin-top: 2px;
    }
    &.active a span:first-child {
      font-weight: bold;
    }
    &.active,
    &:hover {
      background: $select-gray;
    }
  }
}

.debug-form-region {
  padding: 8px;
  background: $select-gray;
  .form-group {
    margin-bottom: 5px;
  }
  .form-actions {
    padding-bottom: 10px;
    .btn-primary {
      float: right;
      margin-top: 12px;
    }
  }
  .select2-container {
    height: auto;
    .select2-selection.select2-selection--multiple {
      overflow: auto;
      max-height: 65px;
    }
  }
  select[name="debug_level"] + .select2 .select2-selection {
    max-width: 120px;
    float: right;
  }
  .form-horizontal {
    .col-sm-4 {
      padding-right: 7.5px;
      padding-left: 15px;
      .control-label {
        min-width: 67px;
      }
    }
    .col-sm-8 {
      padding-right: 15px;
      padding-left: 7.5px;
    }
  }
}

#line-btn {
  display: none;
}

.upload-file {
  display: none;
}

.files-wrap {
  display: none;
  #editor {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 3px);
    height: 545px;
  }
  .col-sm-3 {
    float: right;
    width: 224px;
    height: 545px;
    padding: 0;
    box-shadow: -3px 2px 4px rgba(0, 0, 0, .08);
  }
  .col-sm-9 {
    width: calc(100% - 227px);
  }
  .files-list {
    position: relative;
    height: 545px;
    color: $text-dark;
    h4 {
      font-size: 14px;
      margin: 0;
      padding: 10px 8px;
      border-bottom: 1px solid $border-light-gray;
      background: $select-gray;

      .badge {
        padding-bottom: 4px;
        + span {
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }
    ul li {
      font-size: 14px;
      padding: 8px;
      cursor: pointer;
      &:hover,
      &.active {
        background: $select-gray;
      }
      &:hover {
        color: $default-link-hover-color;
      }
    }
  }
  .files-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
    padding-right: 30px;
    text-align: right;
    border-top: 1px solid $border-light-gray;
    background: $select-gray;
  }
}

#table-content-region h5 {
  margin-top: 70px;
}

.parameters-list-table a b {
  font-weight: 400;
  position: relative;
}
