.configuration-page {
  overflow-y: auto;
  height: 100%;
  padding: 80px 0;
  background: $select-gray;
  .data-points-region .keywords {
    padding-left: 15px;
  }
  .add-stat-parameter {
    margin-left: 15px;
  }
  .container {
    width: 100%;
    max-width: 1080px;
    margin: auto;
    padding: 0 15px;
    .col-sm-7 {
      float: left;
    }
    .col-sm-5 {
      float: right;
    }
    &.airthinx{
      .thiamis-left, .thiamis-right {
        @include for-mobile-only {
          width: 100%;
          float: none;
        }

        float: left;
      }
      .thiamis-right {
        margin-top: 47px;
      }
      .configuration-form {
        width: 100%;
      }
      .form-box.active {
        .keywords a.remove {
          display: block;
        }
      }
      .healthway-automation-region .thiamis-box .form-horizontal,
      .public-link-region .thiamis-box .form-horizontal,
      .thiamis-region .thiamis-box .form-horizontal {
        border-bottom: 0;
        padding: 15px 0 5px;
      }
      .data-points-region, .calibrarion-data-points-region {
        .keywords {
          padding-left: 15px;
          margin-bottom: 6px;
          &:empty {
            margin-bottom: 2px;
          }
        }
      }
      .calibrarion-data-points-region {
        border-top: 1px dashed #ddd;
      }
      .add-stat-parameter-wrapper, .add-custom-calibration-wrapper {
        padding-bottom: 15px;

        .add-stat-parameter, .add-custom-calibration {
          margin-left: 15px;
        }
      }
      .data-points-region .keywords {
        margin-bottom: 6px;
        &:empty {
          margin-bottom: 2px;
        }
      }
      .add-stat-parameter-wrapper {
        padding-bottom: 15px;
      }
    }
  }
  .thiamis-region .device-name .truncated {
    max-width: 380px
  }
  .sensors-region .device-name .truncated {
    max-width: 500px;
  }
  .add-new-sensor {
    margin-right: 5px;
  }

  .calibration-values-region .form-box.active {
    box-shadow: 0 2px 4px #ddd;
  }

  .calibration-values-region {
    .form-title {
      font-size: 15px;
      color: #666;
    }
    .form-group {
      .input-group, i.value {
        display: none;
      }
    }
    .control-label .switch-button {
      right: 0;
    }
    h3 label.control-label {
      width: 120px;
      position: relative;
    }
    .control-label p {
      font-size: 14px;
      margin: 0;
      color: $text-dark;
      font-weight: normal;
    }
  }
  .calibration-values-region .form-box.active {
    h3 {
      padding-bottom: 0;
      padding-top: 10px;
    }
    .form-group {
      margin-bottom: 10px !important;
    }
    .display-text {
      display: none;
    }
    .input-group {
      display: table;
    }
    i.value {
      display: inline;
    }
    label.control-label {
      line-height: 37px;
    }
  }
  .thiamis-map-container {
    padding-left: 0;
    padding-right: 0;
    .location-caption {
      @include border-bottom-radius(5px);
      padding: 5px 10px;
      border-top: 1px solid $border-light-gray;

      .auto_location_switch {
        display: none;
      }
      .switch-location {
        line-height: 40px;
        padding-right: 10px;
        width: auto;
        margin: 0;
      }
    }
  }

  .sensor-icon {
    width: 33.33%;
    height: 100px;
    margin: 0;
  }
  .switch-button {
    position: absolute;
    top: 2px;
    right: 0;
  }
  .settings .switch-button {
    position: absolute;
    top: 2px;
    right: 0;
  }
  .switch-button {
    label {
      width: 50px;
      height: 18px;
      &::before {
        width: 15px;
        height: 15px;
      }
      &::after {
        line-height: 17px;
      }
      &:checked + label::after {
        line-height: 17px;
      }
      &:checked + label::before {
        left: 31px;
      }
    }
  }
  .form-box {
    padding: 0;
    border: 0;
    .form-actions,
    .btn-cancel,
    .loader-icon {
      display: none;
    }
    .settings {
      .btn-sm {
        margin-top: 12px;
        margin-right: 10px;
        padding: 1px 6px;
        color: $font-7;
        border-color: $border-light-gray;
        i {
          margin-right: 3px;
        }
      }
    }
    &.active {
      margin-bottom: 15px;
      box-shadow: 0 0 4px $default-link-hover-color;
      .settings {
        opacity: 1;
      }
      .btn-cancel,
      .form-actions,
      .btn-delete {
        display: block;
      }
      .btn-configure,
      .btn-save,
      .form-cancel {
        display: none;
      }
      .configuration-form {
        max-height: 1000px;
        overflow: visible;
        .popover {
          width: 100%;
        }
      }
      label.control-label {
        line-height: 32px;
      }
      .ysi-channels-region .hidden {
        display: block !important;
      }
    }
    .sensor-extend {
      position: absolute;
      top: 15px;
      right: 15px;
      display: none;
    }
    &.syncing {
      .loader-icon {
        display: block;
        position: absolute;
        top: 10px;
        left: -35px;
        line-height: 20px;
      }
    }
    &.has-configuration,
    &.new-sensor {
      .btn-save,
      .form-cancel {
        display: block;
      }
    }
    &.has-configuration.active {
      .keywords li a.remove {
        display: block;
      }
    }
    &.new-sensor {
      .configuration-form {
        overflow: visible;
        max-height: 100%;
        margin-top: 0;
      }
      .table-responsive {
        padding: 0 15px;
      }
    }
    .thiamis-left .form-horizontal .form-group {
      padding-right: 15px;
    }
    .text-info {
      label.control-label {
        line-height: 25px;
      }
    }
    label.control-label {
      font-size: 14px;
      line-height: 25px;
      float: left;
      width: 170px;
      white-space: nowrap;
      padding-right: 15px;
      color: $text-dark;
      small {
        font-style: italic;
      }
    }
    span.control-label {
      font-size: 14px;
      color: $text-dark;
      line-height: 25px;
      text-align: left;
      float: left;
      display: block;
      width: calc(100% - 170px);
      .thiamis-owner,
      .thiamis-shared,
      .thiamis-shared span {
        display: block;
      }
    }
    h3 {
      text-transform: none;
    }
    .col-sm-6 {
      &:first-child {
        ul {
          float: left;
        }
      }
      &:last-child {
        border-left: 1px dashed $border-dark-gray;
        .caption {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
          color: $text-dark;
          i {
            font-size: 12px;
            color: $icon-gray;
          }
          img {
            max-width: 16px;
            margin-top: -3px;
            margin-right: 2px;
          }
        }
      }
    }
    .widget-map-container {
      position: relative;
      height: 210px !important;
      border-top: 1px solid $border-light-gray;
    }
    .configuration-form {
      overflow: hidden;
      max-height: 0;
      transition: max-height .2s linear;
      .slider-label {
        font-size: 12px;
        margin-top: 5px;
      }
      .value {
        line-height: 14px;
        display: inline-block;
        margin: 0 0 32px;
      }
    }
    .form-actions {
      @include border-bottom-radius(5px);
      padding: 15px;
      border-top: 1px solid $border-light-gray;
      background: $select-gray;
      .btn-sm {
        float: right;
        margin-left: 5px;
        padding: 1px 6px;
        border-radius: 3px;
        i {
          margin-right: 3px;
        }
        &.btn-danger {
          float: left;
          margin-left: 0;
        }
        &.btn-cancel {
          .fa {
            color: #c55757;
          }
        }
        &.btn-save {
          color: $white;
          border-color: $logo-light-blue;
          background: $logo-light-blue;
          &:hover {
            background: #003d83;
          }
        }
        &.btn-reset{
          i {
            color: #fff
          }
          span {
            margin-top: -1px;
          }
        }
      }
    }
    label {
      font-size: 12px;
      color: $icon-gray;
    }
  }
  .configuration-form {
    padding: 0;
  }
  .sensor-configuration {
    margin-bottom: 15px;
    h3 {
      line-height: 25px;
      padding-top: 0;
    }
    .col-sm-6:first-child {
      .form-group {
        float: left;
        width: 100%;
        margin-bottom: 10px;
        label {
          font-weight: 400;
          line-height: 36px;
          float: left;
          width: 25%;
          margin: 0;
          padding-right: 10px;
          text-align: right;
        }
        .form-control-group {
          float: left;
          width: 75%;
        }
      }
    }
    .fancy {
      margin: 15px 0 10px;
    }
    .form-group {
      &::after, &::before {
        content: " ";
        display: table;
      }
    }
    [class$='-calibration-region'],
    [class*='-calibration-region'] {
      float: left;
      width: 100%;
    }
    .col-sm-6:nth-child(2) {
      label {
        width: 100%;
      }
    }
  }
  .slider-container {
    .slider {
      width: calc(100% - 5px);
      margin-left: 5px;
    }
    .slider-label {
      text-align: center;
      .form-control {
        display: inline;
        float: left;
        width: 50px;
        height: 29px;
        padding-right: 5px;
        padding-left: 5px;
        text-align: center;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        margin-bottom: 0;
      }
      .btn-default {
        padding: 4px;
      }
      span {
        font-size: 11px;
        opacity: .75;
        margin-bottom: 0;
      }
      .label-min {
        float: left;
      }
      .label-max {
        float: right;
      }
    }
  }
  .configuration-table {
    margin: 10px 0;
    table-layout: fixed;
    td {
      width: 100px;
      border: 0;
      .control-label {
        width: auto;
        margin: 0;
      }
      .form-group {
        float: left;
        width: 100%;
      }
      &.sensor-address {
        width: 100px;
        .form-control {
          height: auto;
          padding: 8px 12px;
          border-color: $border-light-gray;
        }
      }
      &.disabled {
        opacity: .4;
      }
      .select2 {
        float: left;
        width: 100% !important;
      }
      &:last-child {
        .action-button {
          font-size: 18px;
          display: block;
          float: left;
          width: 21px;
          text-align: center;
        }
        .btn {
          padding: 8px 10px;
          min-width: 30px;
          border: 0;
        }
        .input-group {
          border: 1px solid $border-light-gray;
          border-radius: 5px;
          .form-control {
            border: 0;
            border-left: 1px solid $border-light-gray;
            border-right: 1px solid $border-light-gray;
          }
          .input-group-btn:last-child {
            border-left: 1px solid $border-light-gray;
          }
        }
      }
    }
  }
  .configuration-info {
    .col-sm-6:nth-child(2) {
      padding-left: 0;
    }
    .col-sm-12 {
      padding-bottom: 15px;
    }
  }
  .keywords {
    li {
      font-size: 12px;
      padding-right: 10px;
      position: relative;
      border: 2px solid $select-gray;

      &.expand {
        &:before {
          content: '...';
        }
        cursor: pointer;
      }

      .badge {
        font-size: 10px;
        display: inline;
        position: relative;
        right: -5px;
        background-color: $icon-gray;
      }
    }
    a.remove {
      display: none;
      position: absolute;
      top: -10px;
      right: -10px;
      padding: 3px;
    }
    &.keywords-error {
      margin-bottom: 0;
      li {
        font-size: 12px;
        cursor: pointer;
        color: #c55757;
        border: 1px solid #c55757;
      }
    }
  }
  .thiamis-info {
    float: left;
    width: calc(100% - 85px);
    b {
      display: inline-block;
      width: 70px;
      padding-right: 15px;
      text-align: right;
      white-space: nowrap;
    }
  }
  .sensor-info-list {
    width: 66.667%;
    li:not(.hidden) {
      line-height: 30px;
      border-bottom: 1px dashed $border-light-gray;
      span {
        float: right;
      }
    }
    b {
      display: inline-block;
    }
  }
  .sensor-specific-list {
    padding-right: 15px;
    > li {
      line-height: 30px;
      position: relative;
      span.value {
        padding-left: 10px;
      }
      span,
      input {
        float: right;
      }
      > span {
        min-width: 50px;
        margin-left: 10px;
        text-align: right;
      }
      input {
        height: auto;
        padding: 1px 10px;
      }
      .select2-container {
        a, span {
          font-weight: normal;
          float: none;
          text-align: left;
        }
      }
      .btn {
        font-size: 12px;
        padding: 0 12px;
        text-transform: uppercase;
        border-radius: 10px;
      }
      .select2.select2-container {
        float: none;
        .select2-selection__choice__remove {
          margin-left: 0;
        }
      }
      ul {
        li {
          border: 0;
          b {
            font-weight: 400;
          }
          span {
            float: right;
            margin-left: 20px;
          }
        }
      }
      .select2-container {
        margin-bottom: 5px;
        .selection {
          width: 100%;
        }
        .select2-selection--multiple {
          padding: 0 5px;
          &::after {
            top: 10px;
            right: 5px;
          }
        }
      }
      &.input-inline {
        .select2-container {
          width: 65%;
          margin-left: 0;
          .selection {
            width: 100%;
          }
        }
        b {
          width: 35%;
          line-height: 37px;
        }
        b, .select2-container {
          float: left;
        }
      }
      &:hover {
        .configure-channel {
          opacity: 1;
        }
      }
      .configure-channel {
        opacity: 0;
        font-size: 85%;
        position: relative;
        top: -5px;
        left: 3px;
        color: #999;
        &:hover {
          color: $default-link-hover-color;
        }
      }
    }
  }
  .data-points-region > ul {
    border-top: 1px dashed $border-light-gray;
    margin-top: 15px;
  }
  .data-points-region, .calibrarion-data-points-region {
    > ul {
      padding-top: 12px;
      margin-bottom: 5px;
      .error {
        color: $red-color;
        background-color: transparent;
        border: 2px solid $red-color;
      }
    }
  }
  .thiamis-box {
    padding-bottom: 0;
    .form-control {
      display: none;
    }
    .select2-container {
      display: none;
    }
    .slider-container {
      display: none;
    }
    .form-group.empty, .manual-address {
      display: none;
    }
    input[name="automation"] {
      display: none;
    }
    .radio-inline {
      display: none;
    }
    &.active {
      .radio-inline,
      .form-control,
      .auto-switch,
      .address-input,
      .slider-container,
      .btn-save,
      .form-cancel {
        display: block;
      }
      .form-group.empty {
        display: block;
      }
      .form-group span > p, .auto_location_text {
        display: none;
      }
      .auto_location_text {
        font-weight: normal;
      }
      input[name="automation"] {
        display: inline-block;
      }
      .auto_location_switch {
        display: inline;
        span:first-child {
          padding-right: 10px;
        }
        label {
          font-size: 14px;
          padding-left: 3px;
          font-weight: normal;
        }
      }
      .manual-address {
        display: block;
      }
      .form-group.thiamis-wifi-password .input-group {
        display: table;
      }
      .thiamis-map-container {
        &.auto_location {
          .manual-address {
            display: none;
          }
        }
      }
      .form-horizontal .form-group {
        margin-bottom: 5px;
      }
      .col-sm-8.control-label {
        margin-bottom: 8px;
      }
      form .form-group {
        .control-label {
          margin-bottom: 0;
        }
        label {
          font-weight: bold;
        }
      }
      .wifi-input {
        display: table !important;
        width: 100%;

        .input-group-btn {
          position: absolute;
          top: 0px;
          right: 27px;
        }
      }
    }
    form .form-group {
      margin: 0;
      &:first-child {
        margin-bottom: 0;
        padding-top: 0;
      }
      .control-label {
        color: $text-dark;
        .thiamis-owner,
        .thiamis-shared,
        .thiamis-shared span {
          display: block;
        }
        p {
          font-size: 14px;
          margin: 0;
          color: $text-dark;
        }
      }
      .field-hint, .wifi-input, &.thiamis-wifi-password .input-group {
        display: none;
      }
    }
    .location-caption {
      position: relative;
      z-index: 300;
      min-height: 50px;
      span {
        line-height: 32px;
      }
    }
    .settings {
      z-index: 300;
    }
    .auto-switch {
      font-size: 12px;
      font-weight: bold;
      line-height: 29px;
      display: none;
      float: right;
      padding: 0 0 0 10px;
      color: $font-7;
      border-left: 1px dashed $border-dark-gray;
      input {
        margin-right: 2px;
      }
    }
    .configuration-form {
      margin-top: 0;
    }
  }
  &.is-inventory {
    .thiamis-box {
      .settings {
        position: relative;
        width: 100%;
        padding: 10px;
        opacity: 1;
        &.new-configuration{
          height: 47px;
          padding-top: 14px;
          .btn-configure{
            float: right;
            margin-top: -1px;
          }
        }
        .btn-cancel {
          display: none;
        }
      }
      h4 {
        display: none;
      }
      .info-params-region {
        display: none;
      }
      .form-horizontal {
        border-bottom: 0;
        .form-group.has-error {
          padding-top: 22px;
          .error-message {
            top: 0;
            right: 15px;
            bottom: auto;
          }
        }
      }
      .configuration-form .form-actions {
        .form-cancel {
          cursor: not-allowed;
          opacity: .65;
          box-shadow: none;
        }
      }
    }
  }
}

.configuration-page .sensors-region .sensor-configuration {
  padding: 0 15px;
}

.nav-buttons-region {
  margin-left: 10px;
}

.thiamis-region, .public-link-region, .healthway-automation-region {
  .thiamis-box {
    padding: 0;
    h3 {
      padding: 15px;
    }
    .thiamis-img {
      height: 80px;
      margin: 0 0 0 20px;
      &.airthinxpro {
        height: 140px;
      }
    }
    .thiamis-info {
      margin-top: 15px;
    }
    .thiamis-info-params {
      .flex-center-wrap {
        min-height: 110px;
      }
      border-top: 1px dashed #ddd;
      .big-sensor-box {
        position: relative;
        overflow: visible;
        width: 100%;
        min-height: 40px;
        padding: 0 10px;
        border-top: 1px dashed $border-light-gray;
        .dev-status {
          bottom: 18px;
        }
      }
    }
    .switch-button {
      right: inherit;
      .checkbox {
        min-height: 20px;
      }
    }
    .form-horizontal {
      padding: 10px 0;
      border-top: 1px dashed $border-light-gray;
      border-bottom: 1px dashed $border-light-gray;
      .control-label {
        padding-top: 0;
      }
    }
    .control-label.col-sm-8 {
      padding-left: 0;
    }
  }
}
.add-new-sensor-region .form-box.active label.control-label {
  float: none;
}

.public-link-region {
  .private_dashboard_toggle {
    border-left: 0 !important;
  }
}

.public-link-region,
.healthway-automation-region {
  .link-container {
    line-height: 32px;
    button {
      display: none;
      position: relative;
      top: -3px;
    }
    a {
      display: inline-block;
      margin-right: 5px;
    }
    &:hover button {
      display: inline-block;
    }
  }
}