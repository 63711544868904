@font-face {
    font-family: 'Roboto';
    src: url('#{$font-path}/Roboto-Bold.woff');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$font-path}/Roboto-Italic.woff');
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$font-path}/Roboto-Light.woff');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$font-path}/Roboto-Regular.woff');
    font-style: normal;
    font-weight: 400;
}

@font-face {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
    src: url('#{$font-path}/icomoon.woff');
}