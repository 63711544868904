@media (max-width: 1024px) {
  .users-container,
  .organizations-container {
    .list-table .td {
      &:first-child {
        width: 65px;
      }
      &:nth-child(2) {
        width: 200px;
      }
      &:nth-child(3) {
        width: 275px;
      }
      &:nth-child(4) {
        width: 100px;
      }
      &:nth-child(5) {
        width: 420px;
      }
    }
  }
  .alert-monitor-region .heatmap-wrap {
    max-width: 372px;
  }
  .monitor-page .thiamis_info_widget .device-name .truncated {
    max-width: 320px;
  }
}

@media(max-width: 995px) {
  .dashboard-page {
    .content-top {
      .col-sm-9 {
        width: 70%;
      }
      .col-sm-3 {
        width: 30%;
      }
    }
  }
  .configuration-page .sensors-region .device-name .truncated {
    max-width: 300px;
  }
}

@media(max-width:900px) {
  .list-table .actions-container {
    max-width: 40px;
    width: 100%;
  }
  .nodes-container .info-row a {
    float: left;
    margin: 5px 0;
  }
  .timezone {
    max-width: 100px;
    margin: 0 0 0 15px;
  }
  .firmwares-container .table .thead {
    right: 0px !important;
  }
  .organizations-container {
    .th,
    .td {
      &:nth-child(2) {
        padding-right: 0;
        text-align: center !important;
      }
    }
    #formation-requests-region.in {
      .created-at-col {
        display: none;
      }
      .td,.th {
        max-width: 19%;
        min-width: 19%;
        width: 19%;
        &:nth-child(2) {
          text-align: left !important;
        }
        &.requester-organization-name-col{
          max-width: 23%;
          min-width: 23%;
          width: 23%;
        }
        &.acceptance-state-col{
          max-width: 10%;
          min-width: 10%;
          width: 10%;
        }
        &.actions{
          max-width: 55px;
          min-width: 55px;
          width: 55px;
        }
      }
    }
  }
  .monitor-page .chart-box .recent_measurements_widget .table tr td {
    float: left;
    width: 100%;
  }
  .list-table .actions-container {
    padding-right: 5px;
  }
  .content-top .actions-container {
    //width: 130.5px;
    margin-top: -2px;
  }
  .nodes-container .status-block span:last-child {
    display: inline-block;
    width: auto;
  }
  .sensor-list .sensor-box-container.thiamis-box .sensor-box {
    padding: 9px 10px;
  }
  .nodes-container .sensor-list .sensor-box {
    padding: 15px;
  }
  .nodes-container .table .info-row .sensor-list .sensor-box-container.thiamis-box {
    min-width: 160px;
  }
  .nodes-container .thiamis-box .status-block {
    width: auto;
  }
  .nodes-container .sensor-box-container {
    float: right;
    width: 33.33%;
  }
  .left-panel {
    width: 48px;
  }
  .main-content-wrap {
    padding-left: 48px;
  }
  .header-nav {
    //display: none;
  }
  .select2-dropdown {
    margin-top: -3px;
  }
  .monitor-page .chart-box h3 > .row .timezone {
    float: left;
    margin-top: 30px;
  }
  .daterangepicker.opensright.dropdown-menu {
    right: 134px !important;
  }
  .filtered-content {
    padding-right: 0;
  }
  .profile-pop .popover.bottom > .arrow {
    right: 15px;
  }
  .content-top {
    width: calc(100% - 48px);
  }
  .nodes-container {
    .table {
      .td,
      .th {
        &:nth-child(2) {
          width: 100px;
        }
        &:nth-child(3) {
          width: 0;
          opacity: 0;
        }
        &:nth-child(5) {
          width: 30%;
        }
      }
    }
  }
  .thiamises-container {
    .filter-input {
      font-size: 12px;
      width: 100px;
    }
    .content-top .controls li a,
    .content-top .controls li button {
      font-size: 12px;
    }
  }
  .header-region .container-fluid {
    padding: 0 15px !important;
  }
  .nav-buttons-region .select-entity {
    width: 100px;
  }
  .configuration-page {
    .container {
      .col-sm-7.pr-75 {
        width: 100%;
        padding-right: 15px;
      }
      .col-sm-5.pl-75 {
        width: 100%;
        padding-left: 15px;
      }
    }
    .sensors-region .device-name .truncated {
      max-width: 515px;
    }
    .thiamis-region .device-name .truncated {
      max-width: 515px !important;
    }
  }
  .grid-table .grid-container .card-container {
    min-width: 0;
  }
  .users-list-table .table tr {
    td,
    th {
      &:first-child {
        width: 45px;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(5) {
        overflow: hidden;
        width: 20%;
      }
    }
  }
  .files-container .list-table .table {
    .td,
    .th {
      &:nth-child(2) {
        overflow: hidden;
        width: 23%;
      }
      &:nth-child(3) {
        overflow: hidden;
        width: 25%;
      }
      &:nth-child(4) {
        overflow: hidden;
        width: 25%;
      }
    }
  }
  .messages-container .list-table .table .tr {
    .td,
    .th {
      &:nth-child(2) {
        width: 50%;
      }
      &:nth-child(3) {
        width: 28%;
      }
      &:nth-child(4) {
        width: 15%;
      }
    }
  }
  .messages-container .list-table .table .th{
    &.body-col div{
      padding-left: 13px !important;
    }
    &.created-at-col div{
      margin-left: -35px !important;
    }
  }
  #profile {
    .col-sm-3 {
      width: 100%;
      .profile-picture {
        margin: auto;
      }
    }
    .col-sm-9 {
      width: 100%;
      .details-form {
        margin-top: 30px;
      }
    }
  }
  .fact {
    .number {
      font-size: 30px;
      padding-right: 0;
    }
    .icon {
      display: none;
    }
  }
  .dashboard-page {
    .widget,
    .widget-width-sizer {
      width: 50%;
    }
    .widget.chart_widget.height-extended {
      width: 100%;
    }
    .widget.map_widget {
      width: 100%;
    }
    .content-top {
      padding: 10px 0 5px;
      .col-sm-9 {
        width: 70%;
      }
      .col-sm-3 {
        width: 30%;
      }
      .dropdown {
        .number {
          font-size: 30px;
          //padding-right: 0;
        }
        span {
          //padding: 20px 10px;
        }
      }
      .dropdown-menu {
        .col-sm-2 i {
          font-size: 18px;
        }
      }
    }
  }
  .details-form-page .invite-organization-form {
    padding: 0 15px;
    .billing-same-as-shipping-group {
      margin: 0;
      > .form-group {
        min-height: 0;
      }
    }
  }
  .alerts-edit-page {
    .alert-sidebar,
    .alert-main {
      width: 100%;
      padding: 0 15px;
    }
    .alert-sidebar {
      .form-box:last-child {
        .form-group {
          &:nth-child(2) {
            float: left;
            width: 33.33%;
            padding-right: 15px;
          }
          &:nth-child(3) {
            float: left;
            width: 66.66%;
          }
        }
      }
    }
    .notification-level {
      max-width: 500px;
    }
  }
  .monitor-page .chart-box {
    .chart-wrap {
      width: calc(100% - 185px);
    }
    .thiamis-widget-content {
      height: 505px;
    }
    h3 > .row {
      > .col-sm-8 {
        width: calc(100% - 185px);
        .btn-group {
          float: left;
        }
      }
      > .col-sm-4 {
        width: 185px;
      }
    }
    .header-username {
      padding-right: 0;
    }
    h3 .datepicker-region input.custom-range {
      width: 25px;
      opacity: 0;
    }
    .recent_measurements_widget {
      td .sparkline {
        display: none;
        width: 60px;
      }
    }
    .measurements-wrap {
      width: 185px;
    }
  }
  // .monitor-page .content-top .select2-container {
  //   width: 182px;
  // }
  .organizations-container {
    .content-top {
      .filter-input-wrap {
        display: none;
      }
    }
    .td, .th {
      &:first-child {
        width: 20%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 20%;
      }
    }
  }
  .content-top .actions.opened {
    .actions-container {
      display: block;
      button {
        width: 100%;
        border: 0;
        border-radius: 0;
      }
    }
    .trigger {
      width: 45px;
      border-bottom: 0;
      border-top-left-radius: 5px;
    }
  }
  .thiamis-box.form-box {
    float: left;
    width: 100%;
    .thiamis-left {
      float: left;
      width: 100%;
    }
    .thiamis-right {
      float: right;
      width: 100%;
      .map-container {
        height: 270px;
      }
      .location-caption {
        border-left: 1px solid $border-light-gray;
        border-radius: 0;
      }
    }
    .configuration-form {
      float: left;
      width: 100%;
    }
    &.active {
      .thiamis-right .map-container {
        height: 320px;
      }
    }
  }
  body:not(.filters-collapsed) {
    .right-panel {
      right: -215px;
    }
  }
  .filters-collapsed {
    .right-panel {
      right: 0;
    }
  }
  .left-panel .fa.fa-device {
    content: '\e90b';
  }
  .thiamis-img.thiamis-1000 {
    background: url('#{$media-path}/images/icons/thiamis_1000-mobile.svg') no-repeat center left;
    background-size: 86%;
  }
  .monitor-page .chart-box h3 {
    > .row .timezone {
      float: left;
      max-width: 250px;
      margin: 0 0 10px 10px;
    }
    .datepicker-region {
      margin: 0 0 10px 10px;
      input {
        opacity: 0;
        width: 25px;
        padding-left: 0px;
      }
      i {
        top: 10px
      }
    }
  }
  .map-page .filters-collapse {
    right: 10px;
  }
  .filters-collapsed .map-page .filters-collapse {
    right: $right-panel-width + 10px;
  }
}

@media (max-width: 840px) {
  .monitor-page .chart_table_widget .chart-parameters .sensor-list .sensor-box-container {
    padding: 0 5px;
  }
}
@media (max-width: 710px) {
  .configuration-page .sensors-region .device-name .truncated {
    max-width: 400px;
  }
  .configuration-page .thiamis-region .device-name .truncated {
    max-width: 400px !important;
  }
}
@media (max-width: 600px) {
  .configuration-page {
    .sensors-region .device-name .truncated {
      max-width: 300px;
    }
    .thiamis-region .device-name .truncated {
      max-width: 300px !important;
    }
    .device-name {
      i,small {
        top: -12px;
      }
    }
  }
  .monitor-page .chart-box .chart-wrap {
    width: 100% !important;
  }
  .api-container .list-table .table {
    .org-id-col {
      width: 30% !important;
      min-width: 30%;
      max-width: 30%;
    }
    .created-at-col, .more-info td:nth-child(2) {
      display: none;
    }
    .token-col {
      width: 38% !important;
      min-width: 38%;
      max-width: 38%;
    }
    .more-info .subscriptions-list {
      margin-left: 38%;
    }
  }
  .firmwares-container .table {
    .current-units-col, .pending-units-col, .more-info .td:nth-child(4), .more-info .td:nth-child(5) {
      display: none;
    }
    .more-info .actions {
      padding-right: 2px;
    }
    .more-info .actions a {
      margin-right: 1px;
    }
    .version-col, .thiamis-col {
      width: 25%;
      min-width: 25%;
      max-width: 25%;
    }
    .channel-col {
      width: 20%;
      min-width: 20%;
      max-width: 20%;
    }
    .status-col {
      width: 22%;
      min-width: 22%;
      max-width: 22%;
    }
  }
}
@media (max-width: 525px) {
  .configuration-page .sensors-region .device-name .truncated {
    max-width: 250px;
  }
}
@media (max-width: 400px) {
  .firmwares-container .table {
    .td .actions {
      right: 25px !important;
      width: 40px;
      a {
        margin-right: 18px;
      }
    }
  }
}

@media (min-width: 768px) {
  .fa:not(:only-child) {
    margin-right: 3px;
    &.fa-building-plus,
    &.fa-link-plus {
      margin-right: 6px;
    }
  }
}