.thiamises-container .content-top .pull-right .dropdown {
  margin-right: 10px;
}
.nodes-container .table {
  .td,
  .th {
    &:first-child {
      width: 40px;
      &:not(.expanded) {
        text-align: center;
      }
    }
    &.serial-col {
      width: 100px;
      text-align: left;
      margin-right: 10px;
      margin-left: 3px;
    }
    &.organization-id-col {
      width: 29%;
      text-align: left;
      div > span {
        float: right;
        &:first-child {
          float: left;
        }
      }
    }
    &.name-col {
      width: 18%;
      text-align: left;
    }
    &.description-col {
      width: 26%;
      text-align: left;
    }
    &.status-col,
    &.shared-leased-col {
      width: 60px;
      text-align: center;
    }
    &:last-child {
      flex: 1 1 90px;
      width: 90px;
      text-align: center;
    }
  }
  .tr .td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.serial-col {
      a {
        color: $default-link-hover-color;
        .fa {
          color: $text-dark;
        }
      }
    }
    &.shared-leased-col {
      a {
        position: relative;
      }
    }
    &[colspan="8"] {
      padding: 0;
      width: 100%;
    }
  }
  .tr .th:last-child div {
    right: 0;
    padding-top: 5px;
    padding-right: 8px;
  }
  .info-row {
    position: relative;
    padding: 0;
    padding-top: 5px;
    &.is-inventory {
      min-height: 60px;
      .thiamis-box {
        display: none;
      }
    }
    a {
      z-index: 4;
    }
    .info-row-center {
      float: left;
      width: calc(100% - 40px);
      max-width: none;
      p {
        float: left;
        margin-top: -4px;
        padding-left: 47.5px;
      }
    }
    .sensor-list {
      float: left;
      width: 100%;
      height: auto;
      min-height: 82px;
      padding: 0 10px 5px 45px;
      padding-left: calc(25% + 60px);
      .sensor-box-container {
        float: left;
        width: 25%;
        &.thiamis-box {
          position: absolute;
          left: 45px;
          .status-block li {
            display: block;
          }
          .status-block .param-info-icon {
            margin-right: 0;
          }
        }
      }
      .sensor-box {
        text-align: right;
      }
    }
  }
  @media (min-width: 1300px) {
    .sensor-list {
      .sensor-box-container {
        float: right;
        width: 25%;
        &.thiamis-box {
          float: left;
        }
      }
    }
  }
  @media (min-width: 1230px) and (max-width: 1300px) {
    .sensor-list {
      padding-right: 5px;
      .sensor-box-container {
        width: 50%;
        &:last-child {
          padding-right: 1px;
        }
      }
    }
    .shared-caption {
      padding-right: 10px;
      .tag {
        padding: 3px 7px;
      }
    }
  }
  @media (max-width: 1230px) {
    .node-caption {
      font-size: 14px;
    }
    .sensor-list {
      padding-right: 5px;
      .sensor-box-container {
        width: 50%;
      }
    }
    .shared-caption {
      padding-right: 10px;
      .tag {
        padding: 3px 7px;
      }
    }
  }
}

.node-caption {
  font-size: 14px;
  width: 100%;
  padding: 9px 15px;
  padding-right: 54px;
  .info {
    margin: 0;
    margin-top: -5px;
  }
}

.status-block {
  font-size: 12px;
  float: right;
  width: 100px;
  padding-top: 0;
  list-style-type: none;
  li {
    display: inline-block;
    width: 100%;
    .param-info-icon {
      margin-right: 5px;
      font-size: 12px;
    }
    i {
      font-size: 10px;
      line-height: 16px;
      float: right;
      width: 26px;
      height: 16px;
      margin-left: 5px;
      margin-right: 0px !important;
      text-align: center;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.keywords.shared-list {
  position: relative;
  top: -5px;
  width: 100%;
  padding-right: 50px;
  padding-left: 47.5px;
  li {
    font-size: 12px;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 2px 10px 2px 5px;
    padding-right: 10px;
    border: 0;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    i {
      margin-right: 5px;
    }
  }
}

.node-caption-bckg {
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 0;
  width: 70%;
  height: 37px;
  background: $grey;
}

.bottom-caption-bckg {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 38px;
  background: $grey;
}

.thiamis-info {
  float: left;
  width: 255px;
  padding-bottom: 15px;
  .params-container {
    padding-bottom: 10px;
  }
  .tech-params-container {
    > span {
      padding-right: 20px;
    }
    i {
      padding-right: 5px;
    }
  }
}

.device-name {
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 5px;
  white-space: nowrap;
  line-height: 16px;
  .truncated {
    display: inline-block;
    max-width: 128px;
    padding-left: 5px;
  }
  small {
    padding-left: 3px;
    position: relative;
    top: -3px;
  }
  i {
    font-size: 12px;
    position: relative;
    top: -3px;
  }
  .device-info {
    padding-left: 10px;
    &.warning {
      color: $red-color;
    }
  }
}

.sensor-list {
  width: 270px;
  padding: 0 10px 10px 5px;
  color: #777;
  .sensor-box-container {
    float: left;
    width: 33.33%;
    min-width: 200px;
    max-width: 240px;
    padding: 0 2.5px 5px;
    &.thiamis-box {
      position: absolute;
      left: 45px;
      .sensor-box {
        float: left;
        max-width: 190px;
        padding-right: 10px;
        border: 1px solid #66afe9;
      }
    }
  }
  .sensor-box {
    float: right;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid $white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    .nodes-container & {
      background: $white;
    }
    .sensor-caption {
      padding: 0 0 0 10px;
      text-align: left;
      h4.device-name {
        margin-bottom: 0;
        small {
          display: block;
          padding-top: 3px;
          padding-left: 15px;
        }
      }
      .serial {
        float: left;
        padding-left: 15px;
        font-size: 12px;
        position: relative;
        bottom: -10px;
        b {
          display: inline-block;
          float: left;
          margin-right: 5px;
        }
        span {
          display: inline-block;
          overflow: hidden;
          max-width: 90px;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .sensor-icon {
    position: relative;
    right: -10px;
    float: left;
  }
  .device-name i {
    font-size: 12px;
    position: relative;
    top: -2px;
  }
  span {
    display: inline-block;
    max-width: 75px;
    white-space: nowrap;
  }
}

.info-row.is-inventory {
  .sensor-list {
    display: none;
  }
}

.info-row-map {
  position: relative;
  float: left;
  overflow: hidden;
  width: 412px;
  height: 160px;
  padding: 0 0 0 37px;
  .timestamp {
    font-size: 14px;
    position: absolute;
    padding: 1px 24px 3px 18px;
    color: $white;
    border-bottom-right-radius: 19px;
    background: #888;
  }
}

.info-row-status {
  width: 100%;
  padding: 0;
  padding-left: 412px;
}

.fa-remove_share,
.fa-remove_lease {
  display: none;
}

.manage-node-remove-lease p.help-block {
  display: none;
}


.certificate-lists-column {
  flex: 1;
  margin-left: 15px;
}

.certificate-lists-column-actions {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 5px;
}


.certificate-picture {
  overflow: hidden;
  width: 100%;
  height: 220px;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px dashed rgba(0,0,0,0.3);
  background: $light-gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    opacity: 0.1;
  }
  .img-responsive {
    opacity: 1 !important;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.certificate-lists-column .caption-box {
  font-weight: bold;
  padding: 0 0 5px 0;
}
.certificate-lists-column ul {
  padding: 0 10px;
  padding-bottom: 10px;
}
.certificates-table .more-info {
  align-items: flex-start !important;
}

.is-invalid {
  border-color: #dc3545 !important;
}
.certificate-picture-alt {
  margin: 6px 0;
  opacity: 0.6;
  img {
    margin-right: 10px;
    margin-top: -2px;
  }
}
.certificate-group-label {
  margin: 15px 0px;
}
.certificate-example {
  display: flex;
  opacity: 0.6;
  margin: 5px 0;
}

@media (max-width: 900px) {
  .certificate-picture-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    label, small {
      width: 80%;
      text-align: left;
    }
  }
}

.no-devices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 50vw;
  max-width: 400px;
  height: 300px;
  margin: 120px auto;
  text-align: center;
  padding: 10px 40px 20px 40px;
  border: 1px dashed rgba(119, 119, 119, 0.3);
  border-radius: 8px;
}

.claim-device-form {
  overflow-y: unset !important;

  .form-control[disabled] {
    background: hsl(0,0%,95%);
  }

  .center-form {
    .save-form {
      width: 130px;
      margin: 0 calc(50% - 65px);
      span {
        display: inline-block;
      }
    }
  }

  .results-table {
    border: 1px solid rgba(119, 119, 119, 0.4);
    border-radius: 5px;
    min-height: 50vh;
    max-height: 80vh;
    background: #F5F5F5;
    tbody, thead {
      background: white;
    }
    thead {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    }
    th {
      border-radius: 5px 5px 0 0;
      padding: 12px 16px;
    }
    tr {
      padding: 8px 16px;
      .status-column {
        color: rgba(41, 189, 103, 1);
        svg {
          fill: rgba(41, 189, 103, 1);
          margin-right: 12px;
        }
      }
    }
    td {
      border-color: transparent;
    }
    tr.failed {
      color: rgba(203, 73, 0, 1);
      .status-column {
        color: rgba(203, 73, 0, 1);
        svg {
          fill: rgba(203, 73, 0, 1);
          margin-right: 12px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .no-devices-container {
    width: 90vw;
    .btn span {
      display: inline-block;
    }
  }
}