.conditions-box,
.references-box {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  .show-references {
    float: right;
    margin: 12px 15px 0 0;
  }
  .loading {
    text-transform: capitalize;
    padding: 30px 0 46px;
    display: block;
  }
  h3 {
    padding: 15px;
  }
}

.conditions-box {
  padding-bottom: 15px;
}

.alert-table {
  .flex-form-container {
    &:only-child .flex-form {
      &:first-child .relation {
        display: none;
      }
      &:last-child,
      &:only-child {
        border: 0;
        .relation,
        .row::after {
          display: none;
        }
      }
    }
    &:last-child:not(:only-child) .flex-form {
      border: 0;
      .relation,
      .row::after {
        display: none;
      }
    }
    &:first-child:not(:only-child) .flex-form .relation {
      display: none;
    }
  }
}

.flex-form {
  border-bottom: 1px dashed $border-light-gray;
  padding: 15px;
  .form-group {
    width: 100%;
    margin-bottom: 0;
    > .row {
      width: calc(100% - 30px);
      float: right;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 15px;
        height: 15px;
        left: -33px;
        top: 36px;
        background: $border-light-gray;
        border-radius: 50%;
      }
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -26.5px;
        border-left: 1px dashed $border-light-gray;
        width: 1px;
        height: 110px;
        top: 42px;
        z-index: 1;
      }
    }
  }
  [class^="col-sm-"],
  [class*="col-sm-"] {
    @include box-align(center);
    padding: 0 5px;
    display: flex;
    min-height: 85px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    .select2 {
      margin: 2.5px 0;
    }
  }
  &.active,
  &:hover {
    .actions > button {
      opacity: 1;
      i {
        color: $font-gray;
      }
      &:disabled {
        opacity: .65;
        background-color: $select-gray;
        border-color: $border-light-gray;
      }
    }
  }
  .actions {
    > button {
      opacity: 0;
      &:not([disabled]):hover {
        background-color: $select-gray;
        border-color: $text-gray;
      }
    }
  }
  .params2-region {
    width: 100%;
    margin: 0;
  }
  .params-region {
    width: 100%;
    > div {
      width: 100%;
    }
    .double {
      padding-left: 15px;
    }
    .row,
    .params-region,
    .params2-region {
      width: 100%;
      margin: 0;
    }
  }
}

.cond-table .flex-form .value {
  padding-left: 15px;
}

.ref-table {
  .flex-form {
    &:only-child,
    &:last-child {
      border: 0;
    }
  }
  .form-group .row {
    &::after,
    &::before {
      display: none;
    }
  }
}

.removed.flex-form .row {
  .display-node,
  .func,
  .value,
  .name {
    text-decoration: line-through;
  }
}

.func {
  white-space: nowrap;
}

.relation {
  display: block;
  padding: 1px 5px;
  font-size: 12px;
  border: 1px solid $border-light-gray;
  background: $white;
  text-align: center;
  border-radius: 5px;
  min-width: 38px;
  position: absolute;
  bottom: -25px;
  z-index: 9;
}

.letter-dot {
  font-size: 12px;
  font-weight: bold;
  background: $border-light-gray;
  border-radius: 50%;
  padding: 2px 7px;
  position: absolute;
  top: 32px;
}

.params-region {
  .has-reference {
    .parameter {
      display: none;
    }
    .display-node {
      font-weight: normal;
      font-size: 14px;
    }
  }
  .display-node {
    font-weight: bold;
    display: block;
    width: 100%;
    font-size: 12px;
    color: $font-7;
  }
  .parameter {
    font-style: normal;
    display: block;
    padding-left: 10px;
    .name {
      margin-left: 5px;
    }
  }
}

.add-reference,
.add-condition {
  font-size: 12px;
  color: $default-link-hover-color;
  display: block;
  padding: 15px 0 20px;
}

.add-condition {
  padding-bottom: 0;
}

.actions .btn .fa {
  margin: 0 1px;
}

.relation-select-wrap {
  position: absolute;
  top: 148px;
  left: 0;
  padding: 0 5px;
  z-index: 10;
  .fa {
    color: $icon-gray;
    position: relative;
    top: 2px;
    background: $white;
  }
  .select2 {
    width: 55px;
    margin-left: 5px;
    font-size: 12px;
    text-align: center;
  }
  .select2-container.select2-container--default .select2-selection,
  .select2-container.select2-container--focus .select2-selection {
    padding: 1.5px 10px 1.5px 0 !important;
  }
  p {
    display: none;
  }
  &:not(.selected) {
    .select2-container.select2-container--default .select2-selection,
    .select2-container.select2-container--focus .select2-selection {
      border-color: $red-color;
    }
    p {
      display: inline-block;
      margin: 0 0 0 5px;
    }
  }
  .select2-results__option,
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 1.4em;
    text-align: center;
  }
  .select2-dropdown {
    font-size: 12px;
  }
  .select2-container.select2-container--default.select2-container .select2-selection .select2-selection__arrow {
    padding-top: 8px;
    height: auto;
    margin: 0 5px 0 0;
  }
}
