.select2-container.select2-container--default {
  width: 100%;
  line-height: 24px;
  &.select2-container--above .select2-selection {
    border-bottom-color: $select2-border-color;
  }
  &.select2-container-disabled .select2-selection .select2-selection__arrow {
    background-color: transparent;
  }
  &.select2-container .select2-selection .select2-selection__arrow {
    float: right;
    margin: 0 10px 0 0;
    padding-top: 9px;
    transition: all 2s ease 0s;
    background: none;
    border: 0;
    b {
      font: normal normal normal 16px/1 FontAwesome;
      padding-left: 5px;
      &::before {
        content: $fa-var-angle-down;
      }
    }
  }
  .select2-selection > .select2-chosen {
    display: inline;
    margin-right: 0;
  }
  &.select2-container--open {
    .select2-selection .select2-selection__arrow {
      b::before {
        content: $fa-var-angle-up;
      }
    }
  }
}

select.select-entity.select2 {
  display: none !important;
}

.select2-selection.centered .select2-selection__rendered,
.select2-dropdown.centered .select2-results ul > li {
  text-align: center;
}

.select2-container--default .select2-selection,
.select2-container--default.select2-container--open .select2-selection {
  abbr {
    background-image: url('#{$media-path}/images/select2.png');
  }
  .select2-selection__arrow {
    padding-top: 6px;
    border-left: 0;
    border-radius: 0;
    background: none;
    background-clip: padding-box;
    b {
      background-image: none;
      border-width: 0;
    }
  }
  > .select2-chosen {
    margin-right: 0;
  }
}

.btn-group .select2-container {
  width: auto;
  float: left;
  min-width: 85px;
}
.alerts-monitor-page .nav-buttons-region .select2-container {
  min-width: 195px;
}

.nav-buttons-region .select2-selection.select2-selection--single,
.select2-container.select2-container--default .select2-selection,
.select2-container.select2-container--focus .select2-selection {
 padding-right: 25px !important;
}

.select2-container--disabled .select2-selection {
  background: $select-gray !important;
  cursor: not-allowed !important;
}

.select2-label-only .select2-container {
  .select2-selection {
    border-width: 0;
  }

  &.select2-container--open .select2-selection {
    border-width: 1px;
  }
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $text-color;
}

.select2-container .select2-selection .select2-selection__rendered {
  padding-left: 0;
}

.select2-container {
  &.select2-container--default, &.select2-container--focus {
    .select2-selection {
      position: initial;
      width: 100%;
      height: auto;
      padding: 3.5px 15px;
      color: $text-dark;
      border-color: $select2-border-color;
      border-radius: 5px;
      outline: none;
      background: $white;
      box-shadow: none;
    }
  }
}

.select2-dropdown {
  z-index: 1200;
  padding-top: 5px;
  border-color: $select2-border-color;
  border-radius: 0;
  background: $white;
  box-shadow: none;
}

.select2-dropdown.select2-container--above,
.select2-dropdown.select2-container--above.select2-drop-active {
  border-top-color: $select2-border-color;
  box-shadow: none;
}

.select2-drop-active {
  border-top: 0;
}

.select2-drop-auto-width {
  border-top-color: $select2-border-color;
}

.select2-search input {
  border-color: $select2-border-color;
  background: $white;
  &.select2-active {
    @include gradient-center($white,
    $gray-e);
    background-image: url('#{$media-path}/images/select2-spinner.gif');
    background-repeat: no-repeat;
    background-size: 100%;
  }
}
.select2-container--default {
  &.select2-container--open {
    .select2-selection, .select2-selections {
      @include border-bottom-radius(0);
      border-color: $select2-border-color;
      box-shadow: none;
    }
  }
}

.select2-dropdown {
  border-color: $select2-border-color;
}

.select2-container--open.select2-container--above .select2-selection,
.select2-container--open.select2-container--above .select2-selections {
  @include border-top-radius(0);
  @include border-bottom-radius($select2-border-radius);
  border-color: $select2-border-color;
  background: $select2-bg-color;
  box-shadow: none;
}

.select2-results .select2-disabled.select2-highlighted {
  color: $font-gray;
}

.select2-results .all_option {
  border-bottom: 1px #dfdfdf dashed;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  color: #999;
  display: list-item;
  border-color: #dfdfdf;
  cursor: not-allowed;
  > div {
    cursor: not-allowed;
  }
}

.select2-more-results.select2-active {
  background: $select-gray url('#{$media-path}/images/select2-spinner.gif') no-repeat 100%;
}

.select2-container--default {
  .select2-selection--multiple {
    .select2-selection__choice {
      border-color: $select2-border-color;
    }
    .select2-selection__choice, .select2-selection__rendered li {
      line-height: 24px;
    }
    .select2-search {
      background: $white;
      background-image: none;
      input {
        font-size: 1em;
        width: 100% !important;
        min-width: 200px;
        padding: 3px 20px 3px 5px;
        color: $font-gray;
      }
      .select2-active {
        background-image: url('#{$media-path}/images/select2-spinner.gif');
      }
    }
  }
}
.select2-container--default.select2-container--disabled .select2-selection--multiple .select2-search {
  background: transparent;
}
.select2-search-choice-close {
  background: url('#{$media-path}/images/select2.png') right top no-repeat;
}

.modal-open .select2-dropdown {
  z-index: 1200;
}

.select2-spinner {
  width: 35px;
  height: 35px;
  background: url('#{$media-path}/images/select2-spinner.gif') no-repeat center;
}

.select2-drop-mask {
  z-index: 1100;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 0;
}

.header-choose-account .select2-container--disabled .select2-selection {
  background: $white !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx)  {
    .select2-selection.select2-selection--multiple::after {
        content: '';
        position: absolute;
        top: 18px;
        right: 12px;
        width: 15px;
        height: 15px;
        background-image: url('#{$media-path}/images/select2x2.png') !important;
        background-repeat: no-repeat !important;
        background-size: 60px 40px !important;
        background-position: 95% -26px !important;
    }
}

_::-webkit-full-page-media, _:future, :root .header-choose-account .select2-container.select2-container--default .select2-selection .select2-selection__arrow {
  top: 4px;
}
