$media-path: '../../assets/media/';
$font-path: '../../assets/fonts/';

$text-color: #666;
$default-link-color: #666;
$default-link-hover-color: #2a74bb;
$link-color-blue: #2a74bb;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "~font-awesome/fonts/";
$flagsImagePath: "~intl-tel-input/build/img/" !default;
// ** Not bootstrap variables ** //
// Global //`
$header-height: 65px;
$content-top-height: 65px;
$header-icons-color: #003c7f;
$left-panel-width: 210px;
$left-panel-minified-width: 48px;
$right-panel-width: $left-panel-width + 5px;
$logo-light-blue: #0e75c9;
//Panels //
$left-panel-nav-active: #053f81;
$left-panel-bg-active: #0a67b6;
$left-panel-text-color: #e3e0e1;
$left-panel-text-color-active: #fff;
$right-panel-bg: #414042;
$right-panel-open-bg: #2d2a2b;
$right-panel-heading-text-color: #f1f2f2;
$right-panel-text-color-active: #fff;
$right-panel-bg-color-active: #615a5c;
$white: #fff;
$gray-e: #eee;
$black: #000;
$yellow: #ff0;
$grey: #e6e6e6;
$select-gray: #f5f5f5;
$light-gray: #fbfbfb;
$dark-blue: #053f80;
$btn-hover: $dark-blue;
$light-blue: #0e75c9;
$icon-gray: #999;
$text-gray: #6d6e71;
$red-color: #d87f77;
$headline-blue: #003c7f;
$node-green: #2fd273;
$node-status-empty: #ccc;
$node-gray: #a9a9a9;
$text-dark: #666;
$font-gray: #666;
$font-7: #777;
$border-dark-gray: #ccc;
$border-light-gray: #ddd;
$toastr-green: rgba(73, 185, 109, .9);
$toastr-red: rgba(169, 90, 60, .9);
$toastr-orange: #d0a456;
$dropdown-width: 148px;
$add-widget-disabled: #8691a1;
$add-widgets: #40587b;
$button-border: #adadad;
$button-primary: #006092;
$button-primary-hover: #0e75c9;
$table-highlight: #f0f4f7;
$tooltip: #8691a1;
$spin-button: #bbb;
$spin-button-hover: #0cf;
$keywords-hover: #013c7f;
$footer-background: rgba(255, 255, 255, .4);
$flash-back: #fbf8b2;
$orange: #ef680e;
$left-sidebar: #006092;
$left-sidebar-active: #0c5177;
$dot-gray: #eaeaea;
// ICOMOON
$fa-certificate: "\e900";
$fa-share: "\e606";
$fa-lease: "\1f304";
$fa-thiamis: "\e620";
$fa-thiamis-1000: "\e90c";
$fa-thiamis-1000-2: "\e90b";
$fa-satelite: "\e91e";
$fa-pin: "\e91d";
$fa-airthinx: "\e901";

// DASHBOARD
$font-blue: #05479e;
//Thiamis Monitor
$chart-box-container-height: 545px;
$chart-box-fotter-height: 39px;
$parameters-table-timestamp-width: 150px;
$debug-parameters-table-timestamp-width: 180px;
// Select2
$select2-border-radius: 5px;
$select2-border-color: #ddd;
$select2-bg-color: #fff;
// ReactGrid
$react-grid-toolbar-height: 52px;
$react-grid-gap: 15px;
