.form-content {
  .checkbox {
    &,
    input {
      display: inline;
      width: auto;
    }
    span {
      font-weight: normal;
    }
  }
}

.form-margins {
  margin: 0px 15px;
}

.form {
  input,
  textarea,
  select {
    display: block;
    width: 100%;
    padding: 8px 15px;
    border: 1px solid $border-light-gray;
    border-radius: 5px;
    outline: none;
    background-color: $white;
  }
  .PhoneInput {
    border: 1px solid $border-light-gray;
    border-radius: 5px;
    outline: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .PhoneInputInput {
    border: none !important;
  }
  label {
    line-height: 25px;
    display: block;
  }
  textarea {
    max-width: 100%;
    border-radius: 5px;
  }
  .popover {
    min-width: 200px;
  }
  #profile{
    textarea[name="address_street"] {
      resize: none;
    }
  }
}

.form-control {
  height: 37px;
  box-shadow: none;
  &.select2 {
    padding: 0;
    border: 0;
    box-shadow: none;
    height: auto;
  }
  &.support-full-name,
  &.support-email {
    font-size: 14px;
    line-height: 24px;
    float: left;
  }
}

.form-control-group {
  position: relative;
  .form-control-unit {
    position: absolute;
    top: 10px;
    right: 5px;
    color: $icon-gray;
  }
  .form-control {
    padding-right: 35px;
  }
}

.popover-fa-link {
  position: relative;
  &.top-right {
    top: -5px;
    i.fa {
      font-size: 12px;
    }
  }
}

.form-group {
  position: relative;
  .control-label {
    font-size: 12px;
    line-height: 21px;
    color: $icon-gray;
  }
  &.has-error {
    .control-label > span {
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      right: 0;
      bottom: -25px;
      display: block;
      color: $red-color;
    }
  }
}

.configuration-page .form-group .control-label {

  &.show-hint p.field-hint{
    font-size: 10px;
    margin-left: 5px;
    margin-top: -5px;
    height: 22px;
    color: #263238;
    display: block;
    i {
      margin-right: 3px;
      font-size: 9px;
      color: #2a74bb;
    }
  }

  .input-group-btn .refreshWifiList {
    margin-left: -4px;
    font-size: 9px;
    margin-right: 0px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    padding: 11.5px;
    background: #f5f5f5;

    &:hover {
      border-color: #ccc;
    }

    & + .tooltip.top {
      margin-left: -53px;
      .tooltip-inner {
        min-width: 140px
      }
      .tooltip-arrow {
        right: 12px;
        left: auto !important;
      }
    }
    span {
      display: inline-block;
      top: 2px;
    }
    &.loadingWifiList{
      cursor: not-allowed !important;

      span {
        @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
        @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
        @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
      }
    }
  }
}

.configuration-page .form-group [name='wifi_ssid'] + .select2 .select2-selection__arrow b {
  padding-left: 0px;
}

.form-control[disabled],
.form-control[readonly],
.form [disabled] .form-control {
  color: $text-dark;
  background: $light-gray;
}

.details-form-page {
  .tab-content {
    width: 100%;
    max-width: 990px;
    margin: auto;
    padding: 0 40px;
    margin-top: $content-top-height;
  }
}

.details-form-page {
  padding-top: 50px;
  padding-bottom: 30px;
  &.organization-page {
    padding-top: 85px;
  }
  .form-actions {
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 0;
    padding: 20px 7.5px;
    text-align: right;
  }
  .content-top {
    ul {
      max-width: 1000px;
      margin: auto;
    }
  }
  .form label {
    line-height: 20px;
  }
  .invite-organization-form {
    padding: 0;
    h3 {
      max-width: 770px;
      margin-bottom: 10px;
    }
    .form-actions {
      padding: 0 0 20px;
    }
    .brand-name {
      .col-xs-2{
        padding-top: 8px;
        text-align: right;
        padding-right: 0px;
      }
      .col-xs-10 {
        padding-left: 10px;
      }
      [name='app_name'] ~ .select2 {
        width: 100% !important;
      }
    }
  }
}

.ch-label {
  min-width: 200px;
}

.password-tab .form-horizontal label {
  width: 33.33%;
}

.form-box {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  padding: 0 15px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  background: $white;
  box-shadow: 0 2px 4px $border-light-gray;
  h4 {
    padding: 15px 0 10px;
  }
  h3 {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 15px 0;
    text-transform: uppercase;
    background: none;
    span {
      font-size: 12px;
      font-weight: 400;
      float: right;
      text-transform: none;
    }
  }
  p,
  p span,
  p small,
  .char-counter {
    font-size: 12px;
    color: $icon-gray;
  }
  p span {
    float: right;
  }
  > .col-sm-8 {
    padding-right: 7.5px;
  }
  &.disabled {
    opacity: .5;
  }
  .settings {
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    top: 0;
    opacity: 1;
    right: 0;
    width: auto;
    transition: opacity .2s linear;
    color: $icon-gray;
    a,
    button {
      margin-top: 5px;
    }
    a {
      cursor: pointer;
      color: $text-dark;
      &:hover {
        color: $button-primary;
      }
    }
  }
  &.active {
    .form-group .control-label {
      .select2-container {
        display: inline-block;
      }
      span.add-on.input-group-addon {
        display: table-cell;
        padding: 6px 10px;
        outline: 0;
        i {
          font-family: 'Glyphicons Halflings';
          font-size: 12px;
          color: #808080;
          &.fa-eye, &.fa-eye-slash {
            font-family: 'FontAwesome';
          }
        }
      }
    }
  }
}

.modal .form-group .control-label, .react-modal-dialog .form-group .control-label {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: $text-dark;
}

.nav-buttons-region {
  .select-entity, div[class$="container"] {
    float: left;
    width: 253px;
    .select2-selection.select2-selection--single {
      line-height: 27px;
      border: 1px solid $border-dark-gray;
      background: $white;
    }
  }
  div[class$="control"] {
    border-radius: 0;
    border-left-width: 0;
    border-right-width: 0;
  }

  .select2-dropdown {
    margin-top: -5px;
    margin-left: 0;
    color: $text-gray;
    border: 1px solid $border-dark-gray;
    border-top: 0;
    background: $white;
  }
  .btn-group {
    .prev {
      @include border-right-radius(0);
      border-right: 0;
    }
    .next {
      @include border-left-radius(0);
      border-left: 0;
    }
  }
  .select2-selection.select2-selection--single {
    margin: 0;
    text-align: center;
    border-radius: 0;
  }
}

.info-text {
  font-size: 12px;
  margin-top: 5px;
  color: $icon-gray;
}

.switch-button {
  input {
    display: none;
  }
  label {
    font-size: 12px;
    width: 55px;
    height: 27px;
    margin: 0;
    padding: 9px;
    cursor: pointer;
    &::before {
      width: 23px;
      height: 23px;
    }
    &::after {
      line-height: 25px;
      background: $text-dark;
    }
  }
  [id^='checkbox-']:checked + label {
    &::after {
      background: $default-link-hover-color;
    }
    &::before {
      left: 31px;
    }
  }
  &.always-on label {
    &::after {
      background: $default-link-hover-color;
    }
  }
}

.has-error {
  .input-error-wrapper{
    position: relative;
  }
  .form-control:focus{
    border-color: $red-color;
  }
  .select2-container .select2-selection.select2-selection--single,
  .select2-container .select2-selection.select2-selection--multiple,
  .form-control,
  .PhoneInput input,
  textarea {
    border-color: $red-color;
  }
  .help-block {
    margin-bottom: 0;
    &.error-message {
      position: absolute;
      top: 1px;
      right: 4px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 10px;
      margin: 0px;
      width: calc(100% - 10px);
      color: #a94442 !important;
      font-size: 12px !important;
    }
    &.certificate-form-error.error-message {
      position: relative;
      right: unset;
      top: unset;
      padding-right: 0px;
      width: 100%;
    }
  }
}

.align-right {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  text-align: right;
}

.certificate-form h3 {
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
  padding: 10px;
  border-radius: 5px;
  background: #f5f5f5;
}
.certificate-form .change-picture {
  display: block;
  color: #337ab7;
  float: left;
}

.col-sm-3 input ~ .help-block.error-message{
  padding-right: 0px;
  font-size: 10px;
}

.healthway-automation-region .form-group {
  margin-bottom: 0;
}


.billing-same-as-shipping-group {
  margin-bottom: 0;
  .form-group {
    min-height: 20px;
  }
}

label.md-radio {
  padding: 0;
  border-bottom: 2px solid #d5d5d5;
  cursor: pointer;
  text-align: center;
  &.chosen {
    border-color: $default-link-hover-color;
    span {
      color: $default-link-hover-color;
      font-weight: bold;
    }
  }
  input[type="radio"] {
    position: inherit;
    margin: 0;
    height: 0;
    padding: 0;
    opacity: 0;
    width: 0;
  }
  span {
    font-weight: normal;
  }
}

input[type="number"] {
  position: relative;
  margin: 0 0 1rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
    width: 1em;
    border-left: 1px solid #ddd;
    opacity: .5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
  &::-webkit-inner-spin-button:hover,
  &::-webkit-inner-spin-button:active {
      box-shadow: 0 0 1px #ddd;
      opacity: .8;
  }
}

/* Override browser form filling */
input:-webkit-autofill {
    background: black;
    color: red;
}

.claim-organizations {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  > .form-group {
    flex: 2;
  }
  > button {
    margin-left: 10px;
  }
}

.calendar-input {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}