* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  position: relative;
  height: 100%;
  background-color: $white;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  > i > .badge {
    font-size: 10px;
    position: absolute;
    top: -12px;
    right: 4px;
    left: auto;
    font-family: 'Roboto'
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  &:disabled, &.disabled {
    color: #999;
    cursor: not-allowed;
    &:hover {
      color: #999;
    }
  }
}

* {
  box-sizing: border-box
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

button,
a {
  &:focus {
    text-decoration: none;
    outline: 0;
  }
}

[role='button'] > span > i,
[role='button'] > i {
  margin-right: 0;
  cursor: pointer;
  text-decoration: none;
}

[disabled] {
  color: $icon-gray !important;
  cursor: not-allowed !important;
}

.table {
  margin: 0;
  .td,.th{
    padding: 8px;
  }
  .th {
    font-weight: bold;
    .sort {
      display: block;
      color: $text-color;
      &.asc,
      &.desc {
        i {
          padding-left: 5px;
        }
      }
      &.asc i::before {
        content: $fa-var-caret-down;
      }
      &.desc i::before {
        content: $fa-var-caret-up;
      }
    }
  }
}

h5 {
  &.text-center {
    margin: 20px 0;
  }
}

.noselect {
  user-select: none;
  -webkit-touch-callout: none;
}

.nowrap {
  white-space: nowrap;
}

.main-wrap {
  position: absolute;
  /*overflow: hidden;*/
  width: 100%;
  height: 100%;
  min-height: 550px;
  padding-top: $header-height;
}

.fill {
  width: 100%;
  height: 100%;
}

.fill-height {
  height: 100%;
}

.refresh-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
  opacity: 0;
  cursor: progress;
  transition: opacity 0.6s ease;
  visibility: hidden;
  &.visible {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
    transition: opacity 0.6s ease;
  }
}

.minified .main-content-wrap {
  padding-left: $left-panel-minified-width;
}

.main-content-wrap {
  z-index: 100;
  /*overflow: hidden;*/
  height: 100%;
  min-height: 100%;
  padding-left: $left-panel-width;
  transition: padding .2s linear;
}

.main-padding-right {
  padding-right: $left-panel-width;
}

.collapsed-padding-left {
  padding-left: 48px;
}

.collapsed-padding-right {
  padding-right: 0;
}

.main-content, .main-content-react {
  position: relative;
  height: 100%;
  min-height: 100%;
  padding: 0;
  > .spinner {
    margin-left: $left-panel-width / 2;
  }
  > .row {
    height: 100%;
  }
}

.img-responsive {
  width: 100%;
}

.padding-left0 {
  padding-left: 0;
}

.padding0 {
  padding: 0;
}

.pl-75 {
  padding-left: 7.5px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-75 {
  padding-right: 7.5px;
}

.truncated {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  > * {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// FILTER COLLAPSED
.filters-collapsed {
  .filtered-content {
    padding-right: 0;
  }
  .right-panel {
    right: -$right-panel-width;
  }
  .tagedit-list {
    width: auto;
    min-width: 180px;
  }
  .toggle-filters::before {
    font-size: 16px;
    content: $fa-var-tags;
  }
}

// NAV MINIFIED
.nav-minified {
  .critical-message {
    padding-left: 65px;
  }
  .content-top {
    width: calc(100% - 48px);
  }
  .main-nav-overlay {
    width: 48px;
    transition: all .1s linear;
  }
  .left-panel {
    width: 48px;
    .fa-angle-double-right {
      display: block;
    }
    .fa-angle-double-left {
      display: none;
    }
  }
  .main-content-wrap {
    padding-left: 48px;
  }
  .main-content > {
    .spinner {
      margin-left: 24px;
    }
  }
  .filtered-content .content-top {
    width: calc(100% - 48px);
  }
  .dashboard-page .content-top {
    width: 100%;
  }
  &.filters-collapsed {
    .content-top {
      width: calc(100% - 48px);
    }
    .main-content-wrap {
      min-width: 0;
    }
  }
}

.dashboard-page {
  .content-top {
    .fact-container {
      &.widgets-container {
        max-width: 300px;
      }
    }
    .dropdown-menu {
      .col-sm-2 {
        width: 14%;
      }
    }
  }
}

// KEYWORDS
.keywords {
  > li {
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 4px 10px;
    padding-right: 0;
    color: $text-dark;
    border-radius: 5px;
    background: $select-gray;
  }
  .fa-remove {
    transition: opacity .2s linear;
    color: $icon-gray;
    &:hover {
      color: $keywords-hover;
    }
  }
}

.has-error {
  .help-block {
    font-size: 12px;
  }
  &.error-bottom .help-block.error-message{
    bottom: -25px !important;
    top: auto !important;
    font-size: 11px !important;
  }
  &.error-top .help-block.error-message{
    top: -25px !important;
    font-size: 11px !important;
  }
}

.error-message {
  text-align: center;
  h3 {
    font-size: 26px;
    font-weight: 400;
    padding-top: 20%;
    text-align: center;
  }
  .btn {
    margin-top: 30px;
  }
}

// DATERANGE PICKER
.daterangepicker.dropdown-menu {
  z-index: 500;
}

.daterangepicker.opensright.dropdown-menu {
  right: 314px !important;
  left: auto !important;
  &::before {
    right: 7px;
    left: auto;
  }
  &::after {
    right: 8px;
    left: auto;
  }
}

.legend {
  span {
    color: $icon-gray;
  }
}

.fancy {
  line-height: .5;
  overflow: hidden;
  width: 100%;
  margin: 20px 0 15px;
  text-align: center;
  span {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    display: inline-block;
    float: none;
    color: $text-dark;
    &::before,
    &::after {
      position: absolute;
      top: 9px;
      width: 600px;
      height: 1px;
      content: '';
      border-top: 1px solid $border-light-gray;
    }
    &::before {
      right: 100%;
      margin-right: 15px;
    }
    &::after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.empty label {
  font-size: 14px;
  font-weight: 400;
}

.flex-center-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.btn:focus,
.btn:active,
.btn.active:focus,
.btn:active:focus {
  outline: none;
  box-shadow: none;
}

.context-designed {
  .design-access-context {
    background: $orange;
  }
  //.header-account-info .user-picture-wrap::before {
  //  content: "\f111";
  //  font-family: 'FontAwesome';
  //  font-size: 16px;
  //  position: absolute;
  //  left: 10px;
  //  bottom: -16px;
  //  color: $orange;
  // opacity: .7;
  // }
  .user-picture-wrap .fa-shield {
    color: $orange;
  }
}

a.blue {
  color: $link-color-blue;
}

footer {
  position: fixed;
  z-index: 1200;
  right: 0;
  bottom: 0;
  padding: 2px 5px;
  background-color: $footer-background;
  p {
    font-size: $font-size-small;
    margin: 0;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.next-environet-promo {
  a {
    color: #fff;
    text-decoration: underline;
    padding-left: 5px;
    &:hover {
      color: #fff;
    }
  }
  .flashing-icon {
    border: none;
    display: inline-block;
    color: #ffb600;
    font-size: 20px;
    padding: 0 8px;
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    &::before {
      content: $fa-var-bolt;
    }
  }
}
.Toastify__toast-container--top-center {
  top: 0 !important;
}

@keyframes blinker {
  from, to { opacity: 1 }
  50% { opacity: 0 }
}