.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  .header-logo {
    height: 60px;
    margin-top: 2px;
    margin-left: 22px;
  }
}

.ball-clip-rotate {
  @include vertical-align;
  position: relative;
  top: 42%;
  width: 40px;
  height: 40px;
  margin: auto;
  div {
    position: relative;
    display: inline-block;
    float: left;
    box-sizing: content-box;
    width: 40px;
    height: 40px;
    margin: 0;
    animation: ball-clip-rotate 1s linear infinite;
    border-radius: 100%;
    background: transparent;
  }
  &::after {
    line-height: 0;
    display: table;
    clear: both;
    content: '';
  }
}

.loading-overlay {
  position: absolute;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .65;
  background-color: $white;
  cursor: not-allowed;
  > div {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 30px;
    margin-top: -15px;
    text-align: center;
  }
}

.loading {
  opacity: .5;
}

.loading.rt-tr {
  opacity: .5;
  cursor: not-allowed;
}

.loading-overlay-container {
  position: relative;
}

.main-nav-overlay {
  position: fixed;
  z-index: 2000;
  top: $header-height;
  left: 0;
  width: $left-panel-width;
  height: calc(100% - 105px);
  transition: all .2s linear;
  opacity: .4;
  background-color: $black;
}

.header-overlay {
  position: fixed;
  z-index: 900;
  top: 0;
  width: 100%;
  height: $header-height;
  opacity: .4;
  background: $black;
}

.react-loading-container {
  position: relative;
  height: calc(100vh - 70px);
}

.react-modal-spinner {
  height: 200px;
}