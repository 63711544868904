.toast-title {
  font-weight: 700;
}

.toast-message {
  a,
  label {
    font-size: 13px;
    font-weight: bold;
    color: $white;
  }
  a {
    text-decoration: underline;
    color: $white;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
  padding: 5px 10px;
  p {
    margin: 0;
  }
}

.toast-close-button {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  top: -5px;
  right: 0;
  float: right;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  color: $font-gray;
  border: 0;
  background: 0 0;
  text-shadow: none;
  -webkit-appearance: none;
  &:focus,
  &:hover {
    cursor: pointer;
    text-decoration: none;
    opacity: .4;
    color: $black;
  }
}

.toast-top-center {
  top: 54px;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  right: 0;
  bottom: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  right: 0;
  bottom: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 1100;
  top: 38px;
  left: 0;
  margin: auto;
  text-align: center;
  * {
    box-sizing: border-box;
  }
  > div {
    font-size: 14px;
    font-weight: 400;
    position: relative;
    overflow: hidden;
    width: auto;
    min-width: 300px;
    margin: 0 auto 6px;
    padding: 6px 7px;
    text-align: center;
    opacity: 1;
    color: $white;
    border-radius: 19px;
    background-position: 15px center;
    box-shadow: none;
    display: inline-block;
    padding-left: 40px;
  }
  >:hover {
    box-shadow: none;
  }
  &.toast-bottom-center > div,
  &.toast-top-center > div {
    width: 100%;
    max-width: 440px;
    margin: auto;
    margin-bottom: 10px;
    display: block;
  }
  &.toast-bottom-full-width > div,
  &.toast-top-full-width > div {
    width: auto;
    margin: auto;
  }
  > .toast-info {
    padding-left: 20px;
  }
}
.new-release .toast-info{
  padding-right: 10px !important;
  max-width: 450px !important;
  .toast-close-button {
    color: #fff;
  }
}

.toast {
  font-size: 14px;
  font-weight: 400;
  top: 10px;
  margin: 0 auto 1px;
  margin-bottom: 1px;
  padding: 7px;
  border: 0;
  border-radius: 32px;
  background-color: $toastr-green;
  background-size: 16px;
}

.toast-success {
  color: $white;
  background: $toastr-green;
}

.toast-error {
  color: $white;
  background: $toastr-red;
}

.toast-info {
  color: $white;
  background-color: $default-link-hover-color;
}

.toast-warning {
  color: $white;
  background-color: $toastr-orange;
}

.toast-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  opacity: .4;
  background-color: $black;
}

@media all and (max-width:240px) {
  #toast-container > div {
    width: 11em;
    padding: 8px 8px 8px 50px;
  }
  #toast-container .toast-close-button {
    top: -.2em;
    right: -.2em;
  }
}

@media all and (min-width:241px) and (max-width:480px) {
  #toast-container > div {
    width: 18em;
    padding: 8px 8px 8px 50px;
  }
  #toast-container .toast-close-button {
    top: -.2em;
    right: -.2em;
  }
}

@media all and (min-width:481px) and (max-width:768px) {
  #toast-container > div {
    width: 25em;
    padding: 15px 15px 15px 50px;
  }
}
