.alerts-container {
  .add-new-button {
    margin-right: 205px;
  }
  .list-table {
    display: block;
    .table {
      .more-info .td {
        .info-row {
          .btn-sm {
            margin-top: 5px;
          }
          .row {
            margin: 0;
          }
        }
      }
      .tr {
        .td {
          [id^='checkbox-'] + label {
            margin: -7px 0;
            cursor: pointer;
          }
          &[colspan='4'] {
            max-width: none;
            padding: 0;
          }
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .th {
          background: #eff4f7;
        }
      }
    }
    .actions-container {
      padding-right: 8px;
      .info:first-child {
        margin-top: 5px;
      }
    }
    .more-info {
      position: relative;
      padding: 5px 0 10px;
      .keywords li {
        padding: 0;
        padding-bottom: 4px;
      }
      .caption-box {
        font-weight: bold;
        padding: 0 0 5px 0;
      }
      .cb-left {
        padding-left: 20px;
      }
      .subscriptions {
        overflow-y: auto;
        max-height: 260px;
        margin-top: 10px;
        padding-right: 12px;
        span {
          float: right;
          color: #9c9c9c;
        }
      }
      .conditions {
        p small {
          display: block;
        }
        p span {
          padding-left: 15px;        }
      }
    }
    [id^='checkbox-'] {
      + label {
        width: 53px;
        height: 22px;
      }
      &:checked {
        + label::before {
          left: 32px;
          width: 18px;
          height: 18px;
        }
        + label::after {
          line-height: 21px;
          background: #006ecc;
        }
      }
      + label::before {
        width: 18px;
        height: 18px;
      }
      + label::after {
        line-height: 21px;
        background: #a9a9a9;
      }
    }
    .relation {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      float: right;
      display: inline-block;
      background: none;
      font-size: 14px;
      line-height: 18px;
      border: 0;
    }
  }
  .more-info {
    position: relative;
    .table {
      width: 100%;
      .th {
        padding: 8px 0;
      }
      .td:first-child,
      .th:first-child {
        width: 20%;
        padding-left: 20px;
      }
      .td {
        &:nth-child(3) {
          width: 25%;
        }
        &:nth-child(4) {
          width: 15%;
          padding-right: 0;
        }
      }
    }
    ul {
      padding: 0 10px;
    }
    .conditions-list {
      flex: 0 0 53%;
      .conditions {
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        max-height: 120px;
        padding: 0;
        color: #777;
        ul {
          padding-top: 0;
          li {
            float: left;
            span {
              display: inline-block !important;
            }
            &:last-child {
              padding-bottom: 10px;
              .relation {
                display: none;
              }
            }
          }
        }
        .condition-box {
          position: relative;
          min-height: 95px;
          padding: 0 15px;
          text-align: center;
          border-radius: 5px;
          background: $white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
          h1 {
            font-size: 20px;
            font-weight: 400;
            margin: 0;
          }
          p {
            font-size: 12px;
            margin: 0;
            margin-bottom: 5px;
            text-transform: lowercase;
            color: $text-dark;
          }
          h2 {
            font-size: 14px;
            font-weight: bold;
            position: absolute;
            bottom: 0;
            width: 100%;
            margin: 0;
            margin-bottom: 5px;
          }
          .parameter-name {
            position: relative;
            height: 34px;
          }
          .settings {
            font-size: 14px;
            font-weight: bold;
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            transition: opacity .2s linear;
            opacity: 0;
            color: $icon-gray;
          }
        }
        .condition-box-container {
          float: left;
          width: 50%;
          min-width: 180px;
          padding: 0 6px 2px;
          .condition-box .relation {
            font-size: 12px;
            font-weight: bold;
            line-height: 29px;
            position: absolute;
            z-index: 300;
            top: 37px;
            left: -20.5px;
            width: 30px;
            text-align: center;
            text-transform: uppercase;
            color: $icon-gray;
            border-radius: 50%;
            background: $select-gray;
          }
          &:first-child .condition-box .relation {
            display: none;
          }
          &.active {
            .condition-box {
              box-shadow: 0 0 4px $logo-light-blue;
            }
            &:hover .settings {
              display: none;
            }
          }
          &:hover .settings {
            opacity: 1;
          }
        }
      }
    }
    .nodes-list {
      .keywords {
        overflow-y: auto;
        max-height: 95px;
        padding: 0;
        li {
          width: 100%;
          padding: 0;
        }
      }
    }
    .subscriptions-list {
      padding: 8px;
      .keywords {
        overflow-y: auto;
        max-height: 120px;
        li {
          float: left;
          clear: both;
          padding: 0;
          border-radius: 5px;
        }
      }
    }
    .info {
      margin-bottom: 5px;
    }
    .subscriptions {
      overflow-y: auto;
      max-height: 260px;
      margin-top: 10px;
      padding-right: 12px;
      span {
        float: right;
        color: #9c9c9c;
      }
    }
  }
  .table {
    > .tbody .name-col {
      a {
        display: block;
        color: $default-link-hover-color;
      }
    }
    .td,
    .th {
      &.name-col {
        min-width: 15.5%;
        max-width: 15.5%;
        text-align: left;
      }
      &.react-name-col {
        min-width: 20%;
        max-width: 20%;
      }
      &.react-choosable-col {
        padding-left: 20px;
      }
      &.react-conditions-col {
        min-width: 45%;
        max-width: 45%;
        flex: 0 0 calc(35% - 15px);
        .react-nodes-col .keywords li {
          float: none;
          margin-bottom: 0;
          &:first-child {
            padding-top: 0;
            padding-left: 0;
          }
        }
      }
      &.react-subscribers-col {
        flex: 0 0 calc(20% - 15px);
      }
      &.description-col {
        min-width: 29%;
      }
      &.organization-id-col {
        min-width: 23%;
        text-align: left;
      }
      &.status-col {
        flex: 65px;
        min-width: 65px;
        max-width: 65px;
        text-align: center;
        > div{
          margin-left: 2px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      &.last-triggered-ts-col {
        min-width: 17%;
        text-align: center;
        span {
          float: left;
          margin-left: 30px;
          min-width: 40px;
        }
      }
      .actions > div {
        right: 0;
        padding-top: 5px;
        padding-right: 8px;
      }
      &.actions-col {
        width: 100%;
        min-width: 40px;
        align-self: flex-end;
        align-items: flex-end;
        flex-direction: column;
        display: flex;
      }
    }
    .td {
      &.status-col {
        text-align: center
      }
    }
  }
}
#tooltip_conditions {
  white-space: normal;
  li:first-child {
    margin-top: 5px;
  }
}
.mc-no-filter {
  .content-top {
    input {
      float: left;
    }
    .select {
      float: right;
      margin: 0;
    }
  }
}

.big-sensor-box {
  .subscriptions {
    li {
      padding: 5px 0;
      span {
        float: right;
        opacity: .8;
      }
    }
  }
}

.list-table .state-r {
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: .2;
}

.alerts-list-table-container {
  .text-center {
    min-height: 50px;
  }
}

.content-top .pull-right .dropdown {
  margin-right: 5px;
}
