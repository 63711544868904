.highcharts-container {
  overflow: visible;
  cursor: crosshair;
}

.highcharts-tooltip {
  z-index: 9998;
  .tooltip-content,
  .tooltip-content p {
    text-align: center;
  }
  span {
    z-index: 1000;
    opacity: 1;
  }
}

.highcharts-input-group {
  display: none;
}
