.files-container {
  .info-row {
    position: relative;
    padding: 10px 47px;
  }
  .table .th,
  .table .td {
    padding: 15px 8px;
    &:first-child {
      width: 39%;
      padding-left: 15px;
    }
    &:nth-child(4) {
      width: 25%;
    }
    &:nth-child(2),
    &:nth-child(3) {
      width: 17%;
    }
  }
  .td:first-child a {
    color: $default-link-hover-color;
  }
}

.file-actions {
  height: 37px;
  margin-right: 3px;
  &.opened {
    margin-right: 5px;
  }
}

.file-info {
  width: 100%;
  margin-bottom: 20px;
  list-style-type: none;
  li {
    display: inline-block;
    margin-right: 25px;
    &:last-child {
      margin: 0;
    }
  }
}

.tag {
  display: inline-block;
  margin-right: 2px;
  padding: 3px 5px;
  border: 1px solid $border-dark-gray;
  border-radius: 5px;
  &.tag-sm {
    font-size: 12px;
    font-weight: normal;
  }
}
