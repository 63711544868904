.list-table {
  background: $white;
  .list-table {
    background: $select-gray;
  }
  .table {
    width: 100%;
    margin-bottom: 0;
    table-layout: fixed;
    border-collapse: collapse;
    background-color: $white;
    .thead {
      display: flex;
    }
    .tbody.disabled {

      .tr{
        cursor: default
      }
      .td {
        font-style: italic;
        cursor: default;
      }
    }
    .checkbox {
      display: none;
      margin: 0;
    }
    &.isOpened .checkbox,
    &.is_opened .checkbox {
      display: inline;
    }
    .tr {
      display: flex;
      align-items: center;
      cursor: pointer;
      .th {
        white-space: nowrap;
        font-weight: bold;
      }
      .td {
        vertical-align: middle;
      }
      &.chosen .td {
        background-color: $table-highlight;
      }
      &.gray-back {
        .td .info {
          visibility: visible;
        }
        .fa-info::before {
          content: "\f00d";
        }
      }
      &.active,
      &.gray-back {
        background: $select-gray;
      }
      &:hover .td a {
        visibility: visible;
      }
      &.empty-table {
        &:hover .td {
          background-color: $white;
        }
      }
    }
  }
  .collapsed-row {
    height: 0;
    transition: height .2s linear;
  }
  .expanded, .active {
    // height: 100px;
    padding: 0 !important;
    .gray-back .td {
      padding-bottom: 0;
    }
    .info-row {
      display: block;
    }
    .table {
      background-color: $select-gray;
      .td {
        border-top: 0;
      }
    }
    .more-info,
    .visible-row {
      // height: 100px;
      background-color: $select-gray;
      .td {
        padding-top: 0;
        vertical-align: top;
        border: 0;
      }
      .info, .actions > a {
        visibility: visible;
      }
    }
  }
  .tr.expandable, .tr.gray-back {
    > .td {
      cursor: pointer;
      outline: none;
    }
  }
  .actions-col {
    width: 90px;
    text-align: center;
  }
  .choosable-col {
    width: 40px;
    text-align: center;
  }
  .info {
    float: right;
    margin-bottom: 2.5px;
    margin-top: 2.5px;
    &.empty {
      visibility: hidden !important;
      &::before {
        content: ".";
      }
    }
  }
  .actions-container {
    float: right;
    width: 30px;
    padding-right: 10px;
    padding-bottom: 8px;
    .info {
      position: relative;
      margin-bottom: 7px;
    }
  }
  .btn-sm {
    padding: 4px 5px;
    text-align: center;
    border: 0;
    outline: 0;
    box-shadow: 0;
  }
  .refresh-button {
    position: relative;
    top: 5px;
    visibility: visible;
    i {
      margin-right: 0;
    }
  }
  .more-info {
    > .td {
      //padding: 0;
      text-align: left;
      vertical-align: top;
      border: 0;
      .actions {
        position: absolute;
        z-index: 200;
        top: 0;
        right: 10px;
        width: 35px;
      }
      &:last-child {
        position: relative;
      }
    }
  }
  .info-row {
    display: none;
    padding: 20px 0 30px 15px;
    background: $select-gray;
    #map-canvas {
      height: 130px;
    }
    h3 {
      font-size: 16px;
      margin-top: 0;
      color: $light-blue;
    }
    a {
      font-weight: normal;
      margin: 0;
      visibility: visible;
      color: inherit;
    }
    .bold {
      font-weight: bold;
    }
    .table .tr .td:first-child {
      width: auto;
      padding-right: 15px;
    }
    .info-row-center {
      position: relative;
      z-index: 3;
      float: left;
      width: calc(100% - 40px);
      max-width: 1200px;
      margin: auto;
    }
  }
  .block {
    min-height: 130px;
    border-right: 1px solid $border-light-gray;
    &:last-child {
      border: 0;
    }
  }
  .actions {
    text-align: right;
  }
  a {
    &:hover i.small-icon .badge {
      background-color: $default-link-hover-color;
    }
    i.small-icon {
      position: relative;
      .badge {
        position: absolute;
        top: -9px;
        right: -9px;
        background: $icon-gray;
        padding: 2px 4px;
        font-size: 9px;
        border: 1px solid #fff;
      }
    }
  }
}

.filtered-content {
  padding-right: $right-panel-width;
  transition: padding-right .1s linear;
  .content-top {
    @include for-desktop-only {
      width: calc(100% - #{$left-panel-width});
    }
  }
}

.img-rounded {
  display: block;
  overflow: hidden;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: #F39C12;
}

.user-picture {
  h2 {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }
}

.grid-table {
  display: block;
  height: 100%;
  padding: 0 7.5px;
  .grid-container {
    padding-top: 5px;
    .card-container {
      padding: 0 7.5px;
      h2 {
        font-size: 18px;
        margin-bottom: 0;
        padding-right: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .user-card {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    background: $white;
    box-shadow: 0 3px 10px $border-dark-gray;
    border: 1px solid $select-gray;
    &:hover {
      .content .btn-sm {
        visibility: visible;
      }
    }
  }
  .picture {
    position: absolute;
    z-index: 1;
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
    padding: 0;
    &.qrcode-visible {
      .qr-code {
        display: block;
      }
      .user-picture {
        display: none;
      }
    }
    .user-picture {
      margin: 10% auto;
      h2 {
        font-size: 35px;
        margin-top: 38px;
        padding: 0;
      }
    }
    .qr-code {
      display: none;
      padding: 20px;
    }
    .company-qr-code {
      display: block;
    }
    span {
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 3px 10px;
      cursor: pointer;
      text-transform: uppercase;
      visibility: hidden;
      background: rgba(255, 255, 255, .85);
      z-index: 900;
    }
    &:hover span {
      visibility: visible;
    }
  }
  .content {
    position: relative;
    overflow: hidden;
    padding: 20px;
    padding-left: 150px;
    h2 {
      font-size: 22px;
      font-weight: bold;
      margin: 0;
    }
    .btn-sm {
      display: none;
      margin-top: 5px;
      padding: 2px 10px;
      transition: opacity .2s linear;
      visibility: hidden;
      i {
        margin-right: 3px;
      }
    }
  }
}

.list-content {
  height: 100%;
}

// .table-hover > .tbody > .tr:hover {
//   > .td,
//   > .th {
//     background: $select-gray;
//   }
// }
.table-hover > .tbody > .tr:hover {
  background: $select-gray;
}

.expand-table {
  .table-striped .tr:nth-child(odd) {
    .td,
    .th {
      background: #e7ebee;
    }
  }
  .table {
    background: $select-gray;
    .td {
      padding: 5px 15px;
    }
  }
}

.nodes-container, .alerts-container, .files-container, .api-container, .firmwares-container {
  .react-list-container {
    margin-top: 64px;
  }
}

// List table fixed header styles
.list-table-container {
  position: relative;
  .list-table {
    overflow-y: auto;
    margin-top: 50px;
    border-top: 1px solid $border-light-gray;
    .list-table {
      margin-top: 0;
    }
    &.empty {
      margin-top: 0;
      border-top: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .table {
      .th {
        line-height: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        div {
          line-height: normal;
          position: fixed;
          top: 132px;
          margin-left: -25px;
          padding: 15px 25px;
          background: transparent;
        }
        &:first-child div {
          margin-left: -25px;
          border: 0;
        }
      }
      &.thiamis-list tbody{
        display: none;
        &:nth-child(-n+300){
          display: table-row-group;
        }
      }
    }
  }
}

.tel {
  font-weight: normal;
  margin-bottom: 0;
  color: $text-color;
  &:hover {
    color: $default-link-hover-color;
  }
}
.user-mobile .tel {
  i{
    font-size: 21px;
    width: 21px;
    vertical-align: middle;
    & + span {
      vertical-align: middle;
      display: inline-block;
    }
  }
}
.user-phone .tel {
  i{
    font-size: 16px;
  }
}

.table > .tbody + .tbody {
  border-top: 1px solid $border-light-gray;
}

.table > .tfoot .td * {
  white-space: normal;
}

.table {
  .info,
  .actions > a,
  .actions > button,
  .info-row .info > a {
    visibility: hidden;
  }
}

.info,
.actions > a,
.actions > button,
.info-row .info > a {
  display: inline-block;
  margin: 0 3px 2px 5px;
  padding: 5px;
  cursor: pointer;
  color: $text-gray;
  border: 1px solid $text-gray;
  border-radius: 50%;
  &:hover {
    color: $select-gray;
    background: $text-gray;
    i {
      color: $white;
    }
  }
}
.organizations-list-table .actions > a {
  margin: 0 3px 5px 5px;
}

.manage-node-table tr {
  background: white;
}