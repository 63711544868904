.alert-notification-level {
  width: 100%;
  .btn-group {
    width: 100%;
    .btn {
      width: 33.33%;
      margin: 0;
      &.active {
        font-weight: bold;
        color: $text-dark;
        border-color: $border-dark-gray;
        background: $select-gray;
        box-shadow: 0;
      }
      &:hover {
        background: $select-gray;
      }
      &:nth-child(2) {
        border-right: 0;
        border-left: 0;
      }
    }
    &.disabled {
      .btn {
        cursor: default;
        &:hover {
          background: $white;
        }
        &.active:hover {
          background: $select-gray;
        }
      }
    }
  }
}

.alert-form-region {
  height: 100%;
  padding-top: 65px;
  .device-name span {
    float: none;
    color: inherit;
  }
  .alerts-edit-page {
    overflow-y: auto;
    height: 100%;
    padding-top: 30px;
    form {
      max-width: 1050px;
      margin: auto;
      .form-control {
        height: auto;
        min-height: 36px;
      }
    }
    .form-actions {
      padding: 20px 15px;
      text-align: right;
    }
    .select2-container--default .select2-selection--multiple .select2-search input{
      padding: 3px 20px 1px 5px;
    }
    .select2-selection.select2-selection--multiple::after {
      top: 19px;
    }
    .message-remaining-text {
      margin-top: 2px;
      i {
        margin-left: 2px;
      }
    }
  }
  .datepicker-region {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.alerts-edit-page {
  padding: 15px 0;
  background: $select-gray;
  .alert-main {
    float: left;
    padding-right: 7.5px;
    .form-box {
      .select2 {
        margin: 0;
      }
      .input-error-wrapper {
        margin: 3px 0;
        width: 100%;
      }
      &::before {
        position: absolute;
        top: 15px;
        left: -31px;
        width: 2px;
        height: 111%;
        content: "";
        border-left: 2px dotted $border-dark-gray;
      }
      &:last-child::before {
        border: 0;
      }
    }
  }
  .alert-sidebar {
    float: right;
    padding-left: 7.5px;
  }
  .sensor-list {
    width: 100%;
    max-height: none;
    margin: 0 0 15px;
    padding: 0;
    .sensor-box {
      position: relative;
      min-height: 120px;
      cursor: pointer;
      text-align: center;
      border: 0;
      background: $select-gray;
      h1 {
        font-size: 22px;
        font-weight: 400;
        margin: 0;
      }
      h2 {
        font-size: 14px;
        font-weight: bold;
        margin: 0 0 5px;
      }
      p {
        margin: 0;
        margin-bottom: 5px;
        text-transform: lowercase;
        color: $text-dark;
      }
      .settings {
        font-size: 14px;
        font-weight: bold;
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        transition: opacity .2s linear;
        opacity: 0;
        color: $icon-gray;
      }
      .relation {
        font-size: 12px;
        font-weight: bold;
        line-height: 29px;
        position: absolute;
        z-index: 300;
        top: 50px;
        left: -20.5px;
        width: 30px;
        text-align: center;
        text-transform: uppercase;
        color: $icon-gray;
        border-radius: 50%;
        background: $white;
      }
    }
    .sensor-box-container {
      padding: 9px 6px 2px;
      &:first-child {
        padding-left: 0;
        .sensor-box .relation {
          display: none;
        }
      }
      &:last-child {
        padding-right: 0;
      }
      &.active {
        .sensor-box {
          box-shadow: 0 0 4px $logo-light-blue;
          .settings {
            opacity: 1;
          }
        }
      }
      &:hover .settings {
        opacity: 1;
      }
    }
  }
  .add-button {
    position: absolute;
    top: 12px;
    right: 15px;
    padding: 2px 10px;
    color: $text-dark;
  }
  .form-group {
    margin-bottom: 10px;
    .control-label {
      font-size: 12px;
      line-height: 21px;
      color: $icon-gray;
    }
    .params-region {
      .has-interval {
        flex-direction: column;
        .col-sm-12 {
          min-height: auto;
        }
      }
    }
  }
}

.conditions-table {
  table-layout: fixed;
  td {
    padding: 8px 10px;
    padding-right: 0;
    border: 0;
    input {
      width: 100%;
    }
    &:first-child {
      width: 33%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:last-child {
      padding-right: 8px;
      .btn-action {
        font-size: 14px;
        float: right;
        margin: 0;
        color: $icon-gray;
        &:hover {
          color: $icon-gray;
        }
      }
    }
  }
}

.alerts-ct {
  height: 100%;
  &.is-new {
    .alert-form-region {
      padding-top: 0;
    }
    .content-top {
      display: none;
    }
  }
  .controls {
    max-width: 990px;
    margin: auto;
  }
}

.sync-error {
  box-shadow: 0 0 4px #a94442;
}

.disabled-box {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: $select-gray;
}

.disabled-removal {
  .select2-search-choice-close {
    display: none;
  }
}

@media (max-width: 1350px) {
  .alert-form-region .alerts-edit-page {
    form {
      max-width: 990px;
    }
  }
}
