$infowindow-bg: #f5f5f5;
.node-infowindow {
  background-color: $infowindow-bg;
  box-shadow: rgba(0, 0, 0, .6) 0 1px 6px;
  border-radius: 2px;
}
.infoBox:before{
  content : " ";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $infowindow-bg;
  position:absolute;
  top: -10px;
  left: 200px;
}

.custom-iw .gm-style-iw {
  top: 0 !important;
  left: 0 !important;
  height: 384px;
  border-radius: 2px;
}

.custom-iw > div:first-child > div:nth-child(2) {
  display: none;
}

// Set background for the infowindow arrow
.custom-iw > div:first-child > div:nth-child(3) > div > div {
  background-color: $infowindow-bg !important;
}

// Set background for the infowindow close button
.custom-iw > div:last-child {
  background-color: $infowindow-bg !important;
}

.custom-iw > div:first-child > div:last-child {
  z-index: -1 !important;
  top: 0;
  left: 0 !important;
  background-color: $infowindow-bg !important;
  box-shadow: rgba(0, 0, 0, .6) 0 1px 6px;
}

.custom-iw .gm-style-iw,
.custom-iw .gm-style-iw > div,
.custom-iw .gm-style-iw > div > div {
  overflow: visible !important;
  width: 100% !important;
  max-width: 100% !important;
}

// set here the width
.custom-iw,
.custom-iw > div:first-child > div:last-child {
  width: 400px !important;
}

// Map | Satelite labels
.gm-style-mtc > div {
  font-size: 13px !important;
}
