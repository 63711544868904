@mixin clear-ul() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin clearfix() {
  &::before,
  &::after {
    content: " "; // 1
    display: table; // 2
  }
  &::after {
    clear: both;
  }
}

// WebKit-style focus
@mixin tab-focus() {
  outline: thin dotted;
  -webkit-outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin box-align($align) {
  -webkit-box-align: $align;
  -webkit-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}

// Text overflow
// Requires inline-block or block for proper styling
@mixin text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hide-text() {
  font: #{"0/0"} a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

// New mixin to use as of v3.0.1
@mixin text-hide() {
  @include hide-text();
}

// CSS3 PROPERTIES
// --------------------------------------------------
// Single side border-radius
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin gradient($gradient-start, $gradient-end) {
  background: linear-gradient(to bottom, $gradient-start 0%, $gradient-end 100%);
}

@mixin gradient-left($gradient-start, $gradient-end) {
  background: linear-gradient(to right, $gradient-start 0%, $gradient-end 100%);
}

@mixin gradient-center($gradient-start, $gradient-end) {
  background-color: linear-gradient(to bottom, $gradient-start 0%, $gradient-end 100%);
}

@mixin rotate-anim($start, $middle, $end) {
  0% {
    transform: rotate($start);
  }
  50% {
    transform: rotate($middle);
  }
  100% {
    transform: rotate($end);
  }
}
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}
// Resize anything
@mixin resizable($direction) {
  resize: $direction; // Options: horizontal, vertical, both
  overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  column-count: $column-count;
  column-gap: $column-gap;
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: #{"alpha(opacity=#{$opacity-ie})"};
}

@mixin custom($main-color, $main-hover, $link, $link-hover, $login-btn) {
  $login-button: $login-btn !global;
  body {

    .left-panel {
      background: $main-color;
      ul li.active a,
      ul li.active a:hover,
      .support-nav li:last-child {
        background: $main-hover;
      }
    }

    footer p,
    .header-popover .profile-info h1,
    .grid-table .picture span,
    .grid-table .content h2,
    #organization-info h3,
    #organization-address h3,
    .nav-tabs > li.active > .btn-tab > i,
    .nav-tabs > li > .btn-tab:hover > i,
    .nav-tabs > li.active > a .btn-tab.active {
      color: $main-color;
    }

    .nav-tabs > li > a > .badge {
      background: $main-color;
    }

    a {
      color: $link;
      &:hover {
        color: $link-hover;
      }
    }
    .btn.btn-primary {
      border-color: $main-color;
      background: $main-color;
      &:enabled:hover {
        border-color: $main-hover;
        background: $main-hover;
      }
    }
    .ball-clip-rotate div {
      color: $main-color;
      border: 4px solid $main-color;
      border-bottom-color: transparent;
    }
  }
}

@mixin animation-delay($time) {
  -webkit-animation-delay: $time;
  -moz-animation-delay: $time;
  -o-animation-delay: $time;
  -ms-animation-delay: $time;
  animation-delay: $time;
}

@mixin animation($name, $duration, $behaviour, $loop, $delay) {
  -webkit-animation: $name $duration $behaviour $loop $delay;
  -moz-animation: $name $duration $behaviour $loop $delay;
  -o-animation: $name $duration $behaviour $loop $delay;
  -ms-animation: $name $duration $behaviour $loop $delay;
  animation: $name $duration $behaviour $loop $delay;
}

@mixin animation-timing-function($anim) {
  -webkit-animation-timing-function: $anim;
  -moz-animation-timing-function: $anim;
  -o-animation-timing-function: $anim;
  -ms-animation-timing-function: $anim;
  animation-timing-function: $anim;
}

@mixin scale($value) {
  -webkit-transform: scale($value);
  -moz-transform: scale($value);
  -o-transform: scale($value);
  -ms-transform: scale($value);
  transform: scale($value);
}

@mixin custom-slideshow($images, $trans-time: 10s) {
  .cb-slideshow,
  .cb-slideshow::after {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      background: #fff;
      list-style-type: none;
  }

  #a0-lock.a0-theme-default .a0-panel .a0-icon-container .a0-image img {
    height: 45px;
  }

  .cb-slideshow li {
    $max-imgs: 6;
    $total-time: $trans-time * $max-imgs;
    span {
      -webkit-animation: slideshowAnimation $total-time linear infinite 0s;
      -moz-animation: slideshowAnimation $total-time linear infinite 0s;
      -o-animation: slideshowAnimation $total-time linear infinite 0s;
      -ms-animation: slideshowAnimation $total-time linear infinite 0s;
      animation: slideshowAnimation $total-time linear infinite 0s;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: transparent;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: none;
      opacity: 0;
      z-index: 0;
      -webkit-backface-visibility: hidden;
    }

    $imgs-len: length($images);
    @for $i from 1 through $max-imgs {
      &:nth-child(#{$i}) span {
        @include animation-delay( ($i - 1) * $trans-time );
        background-image: url("#{$media-path}/images/login-slideshow/#{nth($images, (($i - 1) % $imgs-len) + 1)}");
      }
    }
  }
  // Hide video, show slideshow
  .login,
  .signup {
    .cb-slideshow {
      display: block;
    }
    video {
      display: none;
    }
  }
}

@mixin custom-header-logo($logo-img, $bg-size:100%) {
  .sample.header-logo,
  header .header-logo,
  .page-loader .header-logo {
    background: url("#{$media-path}/images/#{$logo-img}") no-repeat top left; // CHANGE LOGO URL
    background-position: 0 50%;
    background-size: $bg-size; // ADJUST THE BACKGROUND SIZE INSTEAD OF WIDTH TO KEEP THE LAYOUT
  }

  .sample.header-logo { display: none; }
}

@mixin custom-mobile-logo($logo-img, $width) {
  @media (max-width: 767px) {
  	header .header-logo,
  	.page-loader .header-logo {
  		background: url("#{$media-path}/images/#{$logo-img}") no-repeat center;
  		background-size: 100%;
  		width: $width;
  		margin: 0;
  	}
  }
}

@mixin for-mobile-only { @media (max-width: 768px) {@content;} }

@mixin for-desktop-only { @media (min-width: 1080px) {@content;} }
