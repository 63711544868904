.Toastify__toast-container {
  max-width: 500px !important;
  width: 100% !important;
  text-align: center !important;
  padding-left: 40px !important;
}

.Toastify__toast {
  font-size: 14px;
  font-weight: 400;
  top: 10px;
  margin: 0 auto 1px;
  margin-bottom: 1px;
  padding: 7px;
  border: 0;
  border-radius: 32px !important;
  background-color: $toastr-green;
  background-size: 16px;
  min-height: unset !important;
}

.Toastify__toast--success {
  color: $white !important;
  background: $toastr-green !important;
}

.Toastify__toast--error {
  color: $white !important;
  background: $toastr-red !important;
}

.Toastify__toast--info {
  color: $white !important;
  background-color: $default-link-hover-color !important;
}

.Toastify__toast--warning {
  color: $white !important;
  background-color: $toastr-orange !important;
}

// .toast-progress {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   height: 4px;
//   opacity: .4;
//   background-color: $black;
// }