// DASHBOARD PAGE SPECIFIC STYLES
.dashboard-page {
  overflow-y: scroll;
  height: 100%;
  min-height: 100%;
  padding: 0 7.5px;
  background-color: $select-gray;
  .widget-container {
    font-size: 14px;
    box-shadow: 0 2px 4px $border-light-gray;
  }
  .widgets-region {
    padding-top: 5px;
  }
  .content-top {
    position: relative;
    top: 0;
    width: 100%;
    height: auto;
    padding: 15px 0;
    border: 0;
    background: transparent;
    .fact-container {
      max-width: 260px;
      padding: 0 7.5px;
    }
  }
  .dropdown {
    .fact {
      cursor: pointer;
      background: $add-widgets;
    }
    span {
      font-size: 18px;
      padding: 20px 10px 20px 20px;
    }
    .number {
      font-size: 50px;
    }
  }
  .dropdown-menu {
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0 10px;
    border: 0;
    border-radius: 2px;
    background: $add-widgets;
    box-shadow: none;
    .col-sm-2 {
      padding: 0;
      text-align: center;
    }
    i {
      font-size: 20px;
      line-height: 59px;
    }
    .new_airthinx_widget {
      i {
        visibility: hidden;
      }
      background: url('#{$media-path}/images/icons/heatmap-icon.svg') no-repeat center left;
      background-size: 70%;
      float: right;
    }
    a {
      opacity: .5;
      cursor: pointer;
      transition: opacity .1s linear;
      color: $white;
      &:hover {
        color: $white;
        opacity: 1;
      }
      &.disabled {
        color: $add-widget-disabled;
        &:hover {
          cursor: default;
          color: $add-widget-disabled;
          opacity: .5;
        }
      }
    }
  }
  .dropdown:hover > .dropdown-menu {
    display: block;
  }
}

// TOP 4 WIDGETS (FACTS)
.fact-container.syncing {
  opacity: 0.6;
  cursor: progress;
}

.fact {
  float: left;
  width: 100%;
  color: $white;
  border-radius: 3px;
  background: $add-widget-disabled;

  &.clickable:hover {
    cursor: pointer;
    span {
      opacity: 1;
    }
  }

  span {
    font-size: 14px;
    line-height: 19px;
    float: left;
    opacity: .8;
    padding: 9px 0 9px 15px;
  }
  .number {
    font-size: 36px;
    padding: 18px 10px 18px 15px;
  }
  .icon {
    font-size: 31px;
    position: absolute;
    right: 5px;
    padding: 13px 8px 13px 0;
    opacity: .5;
  }
  .add-number {
    font-size: 60px;
  }
}
.is-airthinx .fact .icon i {
    width: 1.28571em;
    background-size: 120%;
    height: 33px;
    margin: 0 0 0 10px;
}

// DASHBOARD - NO WIDGETS
.dashboard-empty {
  border: 4px dashed $border-light-gray;
  .message {
    margin: 200px 0;
    text-align: center;
    p {
      font-size: 16px;
    }
    h2 {
      font-size: 36px;
    }
  }
}

.general-parameter {
  .param-info-icon span {
    display: none;
  }
}

.custom-radio__checked {
  position: absolute;
  top: calc(100% + 4px);
  left: 1px;
  right: 1px;
  height: 1px;
  background: #666;
}

.custom-radio_input {
  display: none;
  width: 1px;
  height: 1px;
  pointer-events: none;

  &:checked ~ .custom-radio__checked {
    top: -4px;
    bottom: -4px;
    background: #3177BA;
    height: auto;
    border-radius: 5px;
  }

  &:checked ~ .custom-radio__title {
    color: #fff;
    position: relative;
    z-index: 1;
  }
}

.custom-radio_label {
  position: relative;
  padding: 4px 8px;
  cursor: pointer;
  color: #666 !important;
  line-height: 1;
  margin-bottom: 8px;
  display: none;
}

.configuration-page .thiamis-box.active {
  .custom-radio_label {
    display: inline-block;
  }
}

.custom-radio_label_previewed {
  .custom-radio_label {
    display: inline-block !important;
    pointer-events: none;
    user-select: none;
  }

  .custom-radio__title {
    opacity: 0.65;
    font-weight: normal;
  }

  .custom-radio_input:checked ~ .custom-radio__title {
    opacity: 1;
    font-size: 14px;
    font-weight: normal;
    color: #666;
    background: transparent;
  }

  .custom-radio_input:checked ~ .custom-radio__checked {
    background: transparent;
  }

  .custom-radio__checked {
    background: transparent;
  }
}

.thiamis-box.active {
  .custom-radio_label_previewed {
    .custom-radio_label {
      pointer-events: all;
      padding: 4px 8px;
    }

    .custom-radio__title {
      opacity: 1;
    }

    .custom-radio_input:checked ~ .custom-radio__checked {
      top: -4px;
      bottom: -4px;
      background: #3177BA;
      height: auto;
      border-radius: 5px;
    }

    .custom-radio__checked {
      position: absolute;
      top: calc(100% + 4px);
      left: 1px;
      right: 1px;
      height: 1px;
      background: #666;
    }

    .custom-radio_input:checked ~ .custom-radio__title {
      opacity: 1;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      background: #3177ba;
    }
  }
}

.thiamis-box.active .custom-radio_row {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.custom-radio_row__expanded {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}