.analytics-page {
  background-color: $select-gray;
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover {
    background-color: $select-gray;
  }
  .nav-tabs > li > a,
  .nav-tabs > li > a:hover {
    margin-right: 6px;
  }
  .nav-tabs > li.active > a:hover {
    border-bottom-color: $select-gray;
  }

  .btn-add-new{
    color: $left-panel-bg-active;
  }

  .tabs-content-region {
    width: 100%;
  }
  .tab-content {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
  .widget-container {
    font-size: 14px;
    box-shadow: 0 2px 4px $border-light-gray, 0 0 0 1px $border-dark-gray;
  }
  .widgets-region {
    padding: 0 15px;
    padding-top: 20px;
  }

  .report-controls {
    padding: 20px 15px 15px;
    border-bottom: 1px solid $border-light-gray;
  }

  .add-report-widget {
    position: relative;
  }

  .flex {
    display: flex;
    align-items: center;
    width: 100%;

    &--space-between {
      justify-content: space-between;
    }

    &--end {
      justify-content: flex-end;
    }
  }
  &.details-form-page.tabed-details-page {
    height: auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .content-top {
    position: static;
    width: 100%;
    background-color: $white;
  }

  .widget {
    padding: 0;
    padding-right: 15px;
  }

  .tab-title {
    font-size: 24px;
    width: 100%;
    margin: 0;
    color: $font-gray;
  }

  .divider{
    width: 1px;
    height: 32px;
    background-color: $border-light-gray;
    margin: 0 10px;
  }

  .btn-expand{
    position: absolute;
    bottom: -15px;
    z-index: 999;
    left: calc(50% - 30px);
    $size: 30px;
    width: $size;
    height: $size;
    border-radius: $size;
    display: flex;
    border: 1px solid $border-light-gray;
    background-color: $select-gray;
    align-items: center;
    justify-content: center;
    background-image: url("#{$media-path}/images/icons/icn-expand-block.svg");
    background-repeat: no-repeat;
    background-position: center;
    &:hover{
      border: 1px solid $button-border;
    }
  }

  .no-margin{
    margin: 0;
  }

  .btn{
    display: flex;
    align-items: center;
    i{
      margin-right: 5px;
    }
    .caret{
      margin-left: 5px;
    }
  }

  .btn--mr-8{
    margin-right: 10px;
  }
}
