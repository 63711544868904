.comments-section {
  h3 {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    padding: 15px 0;
    text-transform: none;
    color: $border-dark-gray;
    span {
      font-size: 14px;
    }
  }
  .form-group,
  .select2-container-multi {
    margin-bottom: 5px;
  }
  .form-control {
    line-height: 25px;
  }
  textarea {
    margin-bottom: 5px;
  }
}

.alert-monitor-region {
  max-width: 1160px;
  min-height: 100%;
  margin: auto;
  padding: 80px 0 15px;
  .panel-footer {
    background-color: #fff;
    border-bottom: 0;
  }
  .heatmap-cell-today {
    stroke: #999;
    stroke-width: 1px;
  }
  .heatmap-cell-color-1 {
    fill: #FFCAB2;
  }
  .heatmap-cell-color-2 {
    fill: #FCC4AC;
  }
  .heatmap-cell-color-3 {
    fill: #F9BEA7;
  }
  .heatmap-cell-color-4 {
    fill: #ECA591;
  }
  .heatmap-cell-color-5 {
    fill: #D87F77;
  }
  .device-name span {
    float: none;
    fill: inherit;
  }
  .pagination {
    margin-bottom: 0;
    .active {
      a,
      a:hover,
      a:focus,
      span,
      span:hover,
      span:focus {
        color: $icon-gray;
        border-color: $border-light-gray;
        background-color: $select-gray;
      }
    }
  }
  .sensor-list {
    .sensor-box {
      cursor: default;
    }
  }
  .alert-notification-level .btn-group.disabled {
    .btn {
      display: none;
      &.active {
        font-weight: 400;
        display: block;
        width: 100%;
        border: 0;
        border-radius: 5px;
      }
    }
  }
  .heatmap-wrap {
    max-width: 564px;
    margin: 0 auto;
  }
  .form-box.panel {
    position: relative;
    &.empty {
      .heatmap-container,
      .btn-group {
        display: none;
      }
    }
    .thiamis-status .device-name {
      padding-left: 0;
      color: #666;
    }
    .btn.download {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .heatmap-container {
      margin: 15px 0;
      margin-top: 0;
      width: 100%;
      position: relative;
      .thiamis-time {
        position: absolute;
        bottom: -5px;
        right: 0px;
      }
      .daily-info {
        position: absolute;
        bottom: -12px;
        right: 80px;
        a {
          padding: 5px;
        }
        > span {
          padding-right: 5px;
        }
      }
      .graph-label {
        font-size: 12px;
      }
    }
    .prev-btn, .next-btn {
      position: absolute;
      top: 50%;
      margin-top: -15px;
      z-index: 2;
      a {
        padding: 5px;
      }
    }

    .prev-btn {
      left: -5px;
    }

    .next-btn {
      right: -5px;
    }

    .toggle-btn-wrap {
      margin-top: 15px;
    }
    .list-table {
      .panel {
        margin-bottom: 0;
      }
      .expanded .visible-row td {
        padding-left: 15px;
      }
      .expanded .visible-row .pagination {
        padding-top: 0px;
        padding-bottom: 10px;
      }
      padding-top: 0;
      .info-row {
        padding: 15px;
        border-top: 1px dashed $border-dark-gray;
        background: $white;
        .keywords {
          margin-top: 10px;
          cursor: pointer;
        }
      }
      .table {
        .btn-sm {
          padding: 1px 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    .table {
      margin: 0;
      thead tr {
        font-weight: bold;
        td {
          text-align: center;
          &:last-child {
            padding-right: 0;
            text-align: right;
          }
        }
      }
      tbody tr {
        td {
          border-bottom-color: $light-gray;
          &:nth-child(5) {
            padding-right: 0;
            text-align: right;
            .keywords li {
              float: right;
              &:first-child {
                margin-right: 0;
              }
            }
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
    .btn-group .btn.active {
      background: #f5f5f5;
    }
  }
  .daily-info {
    display: none;
  }
  .daily-view .daily-info {
    display: block;
    > p {
      margin-top: 15px;
    }
    span {
      float: none;
    }
  }
  .node-navigation {
    .keywords {
      li {
        span {
          color: $border-dark-gray;
        }
      }
    }
  }
  .keywords {
    .popover {
      min-width: 0;
      max-width: 150px;
    }
  }
  .arrow-right {
    position: relative;
  }
  .arrow-right::after,
  .arrow-right::before {
    position: absolute;
    top: 38%;
    right: 100%;
    width: 0;
    height: 0;
    margin-right: -1px;
    content: '';
    pointer-events: none;
    border: solid transparent;
  }
  .arrow-right::after {
    margin-top: -10px;
    border-width: 10px;
    border-color: rgba(255, 255, 255, 0);
    border-right-color: $white;
  }
  .arrow-right::before {
    margin-top: -11px;
    border-width: 11px;
    border-color: rgba(204, 204, 204, 0);
    border-right-color: $border-dark-gray;
  }
  .edit-comment {
    p {
      display: none;
    }
    .editable {
      display: block;
    }
  }
  .conditions-rules {
    .display-node {
      font-size: 85%;
    }
    .sensor-box p {
      font-weight: normal;
    }
  }
}

.alerts-monitor-page {
  @extend .alerts-edit-page;
  overflow-y: auto;
  height: 100%;
  padding-top: 0;
  .nav-buttons-region .select-entity {
    width: auto;
    min-width: 200px;
  }
  .device-name .truncated {
    max-width: initial;
  }
  .sensor-list {
    margin: 0;
    padding-bottom: 10px;
    .sensor-box {
      border: 0;
      padding: 25px 0 20px;
      .relation {
        display: block;
        position: absolute;
        top: 120px;
        left: calc(50% - 15px);
        line-height: 29px;
        height: 30px;
        width: 30px;
      }
      .display-node {
        text-transform: none;
      }
    }
    .sensor-box-container {
      width: 100%;
      max-width: none;
      padding: 9px 0 0;
      &:first-child {
        padding-top: 0;
        .sensor-box .relation {
          display: block;
        }
      }
      &:only-child .sensor-box .relation {
        display: none;
      }
      &:last-child .sensor-box {
        .relation {
          display: none;
        }
      }
      .parameter-name .name {
        max-width: initial;
      }
    }
  }
  .keywords {
    li {
      padding-right: 10px;
    }
  }
  .list-table {
    .visible-row {
      background: $white;
      &:hover > td {
        background: none;
      }
      td {
        padding: 5px 8px;
        &[colspan="3"] {
          padding: 0;
          .timestamp-col {
            width: 33.33%;
          }
        }
      }
    }
    .expanded .table {
      background: $white;
    }
  }
}

.alert-general-info li {
  margin-bottom: 15px;
  b {
    display: block;
    margin-bottom: 5px;
  }
  .notification_level-conf {
    margin: 5px 0 10px;
    padding: 2px 5px;
    border-radius: 5px;
    background: $select-gray;
  }
  p {
    font-size: 14px;
    color: $text-color;
  }
}