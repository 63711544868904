.table thead .text-primary {
  color: $default-link-color;
}

.react-grid_panel__empty {
  position: absolute;
  height: $react-grid-toolbar-height;
  width: 100%;
}

.react-grid_btn-export {
  position: absolute;
  right: $react-grid-gap * 2;
  top: $react-grid-toolbar-height / 2;
  line-height: 1;
  z-index: 600;
  transform: translateY(-50%);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1) !important;
}

@media all and (max-width:1355px) {
  .react-grid_btn-export_text {
    display: none;
  } 
}