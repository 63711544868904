.panel {
  background-color: transparent;
}

.panel-default {
  border: 0;
  > .panel-heading {
    color: inherit;
    border: 0;
    background-color: transparent;
  }
}

.table > tbody > tr:first-child > td {
  border-top: 0;
}

.pagination li a i {
  padding: 0 3px;
}

// Top Left
.popover.topLeft {
  margin-top: -10px;
  .arrow {
    bottom: -11px;
    left: 20% !important;
    margin-left: -11px;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
    &::after {
      bottom: 1px;
      margin-left: -10px;
      content: '';
      border-top-color: $white;
      border-bottom-width: 0;
    }
  }
}

// Top Right
.popover.topRight {
  margin-top: -10px;
  .arrow {
    bottom: -11px;
    left: 80% !important;
    margin-left: -11px;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
    &::after {
      bottom: 1px;
      margin-left: -10px;
      content: '';
      border-top-color: $white;
    }
  }
}

// Right Top
.popover.rightTop {
  margin-left: 10px;
  .arrow {
    top: 20% !important;
    left: -11px;
    margin-top: -11px;
    border-right-color: rgba(0, 0, 0, .25);
    border-left-width: 0;
    &::after {
      bottom: -10px;
      left: 1px;
      content: '';
      border-right-color: $white;
      border-left-width: 0;
    }
  }
}

// Right Bottom
.popover.rightBottom {
  margin-left: 10px;
  .arrow {
    top: 80% !important;
    left: -11px;
    margin-top: -11px;
    border-right-color: rgba(0, 0, 0, .25);
    border-left-width: 0;
    &::after {
      bottom: -10px;
      left: 1px;
      content: '';
      border-right-color: $white;
      border-left-width: 0;
    }
  }
}

// Bottom Left
.popover.bottomLeft {
  margin-top: 10px;
  .arrow {
    top: -11px;
    left: 20% !important;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: rgba(0, 0, 0, .25);
    &::after {
      top: 1px;
      margin-left: -10px;
      content: '';
      border-top-width: 0;
      border-bottom-color: $white;
    }
  }
}

// Bottom Right
.popover.bottomRight {
  margin-top: 10px;
  .arrow {
    top: -11px;
    left: 80% !important;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: rgba(0, 0, 0, .25);
    &::after {
      top: 1px;
      margin-left: -10px;
      content: ' ';
      border-top-width: 0;
      border-bottom-color: $white;
    }
  }
}

// Left Top
.popover.leftTop {
  margin-left: -10px;
  .arrow {
    top: 20% !important;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: rgba(0, 0, 0, .25);
    &::after {
      right: 1px;
      bottom: -10px;
      content: ' ';
      border-right-width: 0;
      border-left-color: $white;
    }
  }
}

// Left Bottom
.popover.leftBottom {
  margin-left: -10px;
  .arrow {
    top: 80% !important;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: rgba(0, 0, 0, .25);
    &::after {
      right: 1px;
      bottom: -10px;
      content: ' ';
      border-right-width: 0;
      border-left-color: $white;
    }
  }
}

// Modal
.dialog-region {
  position: fixed;
  z-index: 1100;
}

.dialog-content-region {
  position: relative;
  min-height: 260px;
}

.bootstrap-dialog {
  z-index: 10000000;
}

.btn-default:active:focus {
  background-color: #fff;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #999;
}
