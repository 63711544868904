$widget-height: 225px;
$widget-height2: 250px;
$widget-padding: 15px;
$widget-title-height: 44px;
$widget-header-color: $font-blue;
$widget-height-extra: $widget-padding + $widget-title-height;
.loader-icon {
  font-size: 111%;
  line-height: $widget-title-height;
  display: none;
  float: left;
  width: 37px;
  height: $widget-title-height;
  margin: 0;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center center;
}

.report {
  .config-report-title {
    font-size: $font-size-h2;
    width: 100%;
    margin: 0;
    margin-bottom: 15px;
    padding-top: 5px;
    padding-left: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    box-shadow: 0;
  }
  &.outofsync {
    .loader-icon {
      display: inline;
    }
  }
  &.error-state {
    @extend .error;
  }
}

.widget-height-sizer {
  display: none;
  width: 0;
  height: $widget-height;
}

.widget {
  margin-bottom: 15px;
  padding: 0 7.5px;
  &:hover {
    .handle {
      opacity: 1;
    }
    .ui-resizable-handle{
      display: block;
    }
  }
  .ui-resizable-handle{
    display: none;
  }

  .nwgrip, .negrip, .swgrip, .segrip {
    width: 10px;
    height: 10px;
    z-index: 1000;
    background-color: rgba(255,255,255,0.5);
    position: absolute;
  }
  .nwgrip {
    left: 1px;
    top: 1px;
  }
  .negrip {
    top: 1px;
    right: 1px;
  }
  .swgrip {
    bottom: 1px;
    left: 1px;
  }
  .segrip {
    bottom: 1px;
    right: 1px;
  }
  &.error .widget-container {
    transition: .2s;
    box-shadow: 0 0 6px rgb(198, 26, 26);
  }
  &.height-extended {
    width: calc(8 / 12 * 100%);
    height: 2 * $widget-height2 + $widget-padding;
    .content-region {
      height: $widget-height + $widget-height2;
    }
    .action-resize > i::before {
      content: $fa-var-compress;
    }
  }
  &.outofsync {
    .loader-icon {
      display: inline;
    }
  }
  &.error-state {
    @extend .error;
  }
  .content-region {
    min-height: $widget-height - $widget-height-extra;
    max-height: 2 * $widget-height2 + $widget-padding;
  }
  .popover {
    .popover-title {
      text-align: center;
      white-space: nowrap;
      color: $text-color;
      background: $white;
    }
    .popover-content {
      white-space: nowrap;
    }
  }
  &.ui-resizable {
    .content-region {
      max-height: 100%;
    }
  }
}

.widget-container {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.widget-header {
  font-size: 14px;
  font-weight: bold;
  line-height: $widget-title-height;
  height: $widget-title-height;
  padding: 0 10px;
  text-align: center;
  background: transparent;
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 44px;
    text-align: left;
    > span {
      font-weight: normal;
      display: inline;
    }
    a {
      padding: 5px 0;
      cursor: pointer;
      color: $font-gray;
      &:hover {
        color: $logo-light-blue;
      }
    }
  }
  .handle {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 5px;
    transition: all .2s;
    opacity: 0;
    i {
      margin-left: 2px;
      cursor: pointer;
    }
    .dropdown {
      position: relative;
      top: 5px;
    }
    > a {
      color: $icon-gray;
      &:hover {
        color: $logo-light-blue;
      }
    }
  }
  .action-config > i::before {
    content: $fa-var-gear;
  }
  .action-move {
    cursor: move;
    > i::before {
      content: $fa-var-arrows;
    }
  }
  .action-remove > i::before {
    content: $fa-var-times;
  }
  .action-resize > i::before {
    content: $fa-var-expand;
  }
  .color-select {
    right: -10px;
    left: auto;
    margin-top: 8px;
  }
  .color-box {
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-right: 10px;
  }
}

.widget-body {
  @include border-bottom-radius(5px);
  position: relative;
  .table {
    margin-bottom: 0;
  }
}

// TEXT WIDGET
.text_widget {
  height: 2 * $widget-height2 + $widget-padding;
  .widget-header {
    background: transparent;
    .title {
      display: none;
    }
  }
  .widget-footer {
    font-style: italic;
  }
  .widget-body {
    line-height: 30px;
    overflow-y: auto;
    margin: 0 15px 15px 5px;
    color: $text-dark;
    background: none;
    .content-text p {
      font-size: 14px;
      line-height: 30px;
      margin-top: 5px;
    }
    p {
      margin: 0;
    }
    ul {
      padding-left: 20px;
      list-style-type: disc;
    }
  }
  .widget-container {
    height: 2 * $widget-height2 + $widget-padding;
    padding-left: 10px;
    background: url('#{$media-path}/images/paper.jpg') -35px 0;
  }
}

// TWITTER WIDGET
.twitter_widget .widget-container {
  height: 2 * $widget-height2 + $widget-padding;
  overflow: scroll;
  iframe {
    width: 100% !important;
  }
  .widget-header {
    width: 33%;
    background: $white;
  }
}

.text_widget,
.map_widget,
.twitter_widget {
  .widget-header {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    width: 100%;
  }
}

.widget-map-container {
  border-radius: 5px;
  .location-description {
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    height: 30px;
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    width: auto;
    padding: 0 15px;
    padding-left: 10px;
    border-bottom-left-radius: 5px;
    background-color: rgba(255, 255, 255, .9);
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    opacity: 0.8;
    .fa-map-marker {
      font-size: 14px;
      margin-right: 5px;
    }
    .address {
      display: none;
    }
    &.has-address {
      height: auto;
      line-height: 18px;
      padding-bottom: 3px;
      .address {
        display: inline-block;
      }
      .gps {
        font-size: 90%;
        display: block;
        margin-left: 15px;
        font-weight: normal;
        text-align: right;
      }
    }
  }
  .staticMap {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.static_map_widget {
  .map-canvas {
    @include border-right-radius(5px);
  }
}

.widget-map-container {
  position: relative;
  height: 100%;
  .no-location {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    h3 {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }
  }
  .no-location-icon{
    position: absolute;
    top: 5px;
    left: 15px;
    i {
      color: #d87f77;
    }
  }
}

// MAP WIDGET
.map_widget {
  .widget-container {
    border-radius: 5px;
  }
  .map-canvas {
    border-radius: 5px;
    > div {
      border-radius: 5px;
    }
    .gm-style {
      border-radius: 5px;
    }
  }
  .widget-footer {
    display: none;
  }
  .content-region {
    border-radius: 5px;
    height: $widget-height + 25;
  }
  &.height-extended .content-region {
    height: 2 * $widget-height2 + $widget-padding;
  }
  &.height-extended .staticMap {
    background-position: center bottom
  }
}

// CHART WIDGET
.chart_widget {
  .control-icons {
    padding: 5px 15px 5px 10px;
  }
}
.chart_table_widget {
  .control-icons {
    padding: 10px 15px 15px 15px;
  }
}
.chart-container {
  position: relative;
  .control-icons {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  .group-btn, .toggle-ordinal-btn {
    color: #999;
    font-size: 12px;
    &:hover {
      color: #666;
    }
  }
  .group-btn {
    i::before {
      content: $fa-var-object-group;
    }
    &.grouped {
      i::before {
        content: $fa-var-object-ungroup;
      }
    }
  }
  a{
    display: flex;
  }
  .toggle-ordinal-btn {
    margin-right: 10px;
    .gap-toggle-icon {
      display: inline-block;
      width: 20px;
      height: 14px;
      background-image: url('#{$media-path}/images/icn-chart-gap-toggle.png');
    }
    &.condensed {
      .gap-toggle-icon {
        background-position-x: -20px;
      }
    }
  }
}

.thiamis-time {
  font-size: 12px;
  .fa-clock-o.disabled {
    display: none;
  }
}

.compare_devices_widget {
  .title .pull-left:last-child i {
    padding-left: 7px;
    padding-right: 2px;
    &:before {
      content: $fa-var-exchange;
    }
  }
}

.chart_widget, .compare_devices_widget {
  .widget-container {
    color: $font-gray;
    background: $white;
  }
  .widget-body {
    padding: 0 14px;
    background: $white;
  }
  &.ui-resizable {
    .content-region {
      height: auto;
    }
  }
  .content-region {
    height: $widget-height2 - $widget-padding - 31;
  }
  &.height-extended .widget-container {
    height: 2 * $widget-height2 + $widget-padding;
  }
  .thiamis-time {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

// NODE LITS WIDGET
.node_widget,
.heatmap_widget,
.blocks_widget {
  .widget-body {
    background: $white;
  }
  .widget-container {
    overflow: hidden;
    &:hover {
      overflow-y: visible;
    }
  }
  .widget-header {
    @include border-top-radius(5px);
    border-bottom: 1px solid $border-light-gray;
  }
  .node-widget-content {
    position: relative;
    z-index: 300;
    overflow: hidden;
    height: $widget-height - $widget-height-extra;
    &:hover {
      overflow-y: auto;
    }
    a {
      color: $font-gray;
    }
    table {
      position: relative;
    }
  }
  &.height-extended {
    .heatmap-container {
      height: 475px;
    }
    .node-widget-content {
      height: 434px;
    }
    .content-region {
      height: auto;
    }
  }
  .tech-datapoints {
    @include border-bottom-radius(5px);
  }
}

.node_widget,
.blocks_widget {
  &.height-extended {
    width: 33.3333%;
  }
}

.node_widget,
.heatmap_widget,
.recent_measurements_widget {
  min-width: 300px;
  td:first-child {
    .name {
      padding-left: 8px;
    }
    input {
      position: relative;
      top: 0;
      float: left;
    }
  }
  td {
    padding: 7px 8px;
    span {
      float: none;
    }
  }
  tr.dp-location {
    display: none;
  }
}

.tech-datapoints {
  font-size: 12px;
  padding: 10px 0;
  border-top: 1px solid $border-light-gray;
  i {
    margin-right: 3px;
  }
  span {
    white-space: nowrap;
    .gsm_signal i {
      color: $black;
    }
  }
  .last-updated-ts {
    text-align: right;
  }
  .general-parameter {
    float: left;
    padding-left: 10px;
    &:first-child {
      padding-left: 15px;
    }
    span > span {
      display: none;
    }
  }
}

.no_data_points {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5em;
  text-align: center;
  text-transform: uppercase;
  color: $icon-gray;
}

.node_widget,
.table_widget,
.heatmap_widget,
.recent_measurements_widget {
  color: $text-dark;
  .sparkline path {
    stroke: #9b9b9b;
  }
  .fa-warning {
    font-size: 14px;
    float: right;
    margin-top: 3px;
    color: $font-gray;
  }
  .widget-header,
  .tech-datapoints {
    color: $text-dark;
    background: $white;
  }
  .tech-datapoints {
    font-size: 12px;
  }
  .table tr td {
    border: 0;
  }
  .table .alert {
    border-left: 3px solid $red-color;
    border-radius: 0;
  }
  .table-striped > tbody > tr:nth-child(odd) > td,
  .table-striped > tbody > tr:nth-child(odd) > th {
    background: #f9f9f9;
  }
}

.node_widget {
  .table tr {
    &:hover {
      cursor: pointer;
      .name-wrap .name .device-name,
      .value-wrap {
        color: $default-link-hover-color;
      }
      .sparkline path {
        stroke: $default-link-hover-color;
      }
    }
    td:nth-child(2) {
      min-width: 60px;
    }
    td:first-child:hover {
      color: $default-link-hover-color;
    }
  }
  .data_points_table .name-wrap {
    padding: 10px 8px;
    a {
      padding: 0;
    }
    h4 {
      color: $text-dark;
      margin: 0;
      .truncated {
        font-weight: 400;
        display: inline-block;
        max-width: 120px;
        margin-top: -2px;
        padding-bottom: 2px;
      }
      b i {
        font-size: 6px;
        position: relative;
        top: -7px;
      }
    }
  }
}

// NODE BLOCKS WIDGET STYLES
.blocks_widget {
  .widget-container {
    overflow: hidden;
    background: $white;
    &:hover {
      overflow-y: auto;
    }
  }
  .datapoints-region {
    padding: 0 7.5px;
  }
  .node-widget-content {
    padding-top: 7.5px;
  }
  .widget-header {
    background: transparent;
  }
  .node-parameter {
    padding: 0 2.5px;
    .parameter-body {
      margin-bottom: 5px;
      padding: 15px;
      cursor: pointer;
      text-align: center;
      border-radius: 5px;
      background: #f9f9f9;
      min-height: 80px;
      &:hover {
        .name,
        .parameter {
          color: $default-link-hover-color;
        }
      }
    }
    .name {
      font-size: 14px;
      font-weight: bold;
      display: block;
      margin-bottom: 5px;
      white-space: nowrap;
      color: $text-dark;
      &:hover {
        color: $default-link-hover-color;
      }
    }
    .parameter {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      .value,
      .unit {
        font-family: 'Helvetica';
      }
    }
  }
  h4 b i {
    font-size: 8px;
    position: relative;
    top: -3px;
  }
}

//4 COLUMN TRANSFORMATION - BIG SCREENS
@media (min-width: 1600px) {
  .widget,
  .widget-width-sizer {
    width: 25%;
  }
  .widget {
    &.height-extended {
      width: 50%;
    }
    &.node_widget,
    &.blocks_widget {
      &.height-extended {
        width: 25%;
      }
    }
  }
}

.data_points_table {
  font-size: 14px;
  z-index: 300;
  table-layout: fixed;
  tr td {
    overflow: hidden;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.name-wrap {
      width: 65px;
      padding: 11px 8px;
    }
    &.value-wrap {
      font-family: 'Roboto', sans-serif;
      width: 55px;
      text-align: right;
    }
    &.sparkline {
      width: 15%;
    }
  }
}

.recent-measurements-widget-region .data_points_table .unit {
  color: inherit;
}

.sparkline {
  .axis {
    display: none;
  }
}


.empty-widget-container {
  font-size: 16px;
  padding: 40px 0;
  text-align: center;
}

.datapoints-region {
  position: relative;

  table tr {
    position: relative;
    float: left;
    width: 100%;
  }

  &.shadow-top:before {
    @include gradient($grey, $white);
    content: '';
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    z-index: 90;
  }
  &.shadow-bottom:after {
    @include gradient($white, $grey);
    content: '';
    background-color: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    z-index: 900;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .dashboard-page {
    .height-extended .data_points_table tbody tr:nth-child(12)::after {
      @include gradient($white,
      $grey);
      position: absolute;
      z-index: 200;
      top: -2px;
      left: 0;
      width: 100%;
      height: 5px;
      content: '';
      background-color: $white;
    }
    .height-extended .datapoints-region.shadow-top {
      .data_points_table tbody tr:nth-child(12)::after {
        display: none;
      }
    }
    .widget:not(.height-extended) {
      .data_points_table tbody tr:nth-child(5)::after {
        @include gradient($white,
        $grey);
        position: absolute;
        z-index: 200;
        top: -2px;
        left: 0;
        width: 100%;
        height: 5px;
        content: '';
        background-color: $white;
      }
      .datapoints-region.shadow-top {
        .data_points_table tbody tr:nth-child(5)::after {
          display: none;
        }
      }
    }
  }
}

@supports (-ms-accelerator:true) {
  .dashboard-page {
    .height-extended .data_points_table tbody tr:nth-child(12)::after {
      @include gradient($white,
      $grey);
      position: absolute;
      z-index: 200;
      top: -2px;
      left: 0;
      width: 100%;
      height: 5px;
      content: '';
      background-color: $white;
    }
    .height-extended .datapoints-region.shadow-top {
      .data_points_table tbody tr:nth-child(12)::after {
        display: none;
      }
    }
    .widget:not(.height-extended) {
      .data_points_table tbody tr:nth-child(5)::after {
        @include gradient($white,
        $grey);
        position: absolute;
        z-index: 200;
        top: -2px;
        left: 0;
        width: 100%;
        height: 5px;
        content: '';
        background-color: $white;
      }
      .datapoints-region.shadow-top {
        .data_points_table tbody tr:nth-child(5)::after {
          display: none;
        }
      }
    }
  }
}

.node_widget_modal, .blocks_widget_modal {
  div.data_points_filter_wrap div.clearfix {
    margin-left: -15px;
  }
}

.param-info-icon {
  display: inline-block;
}

.rc-calendar-time-picker-panel .rc-time-picker-panel-inner {
  width: 100%;
}

.rc-calendar-time-picker-panel .rc-time-picker-panel-select {
  width: 50% !important;
}

.heatmap_widget .widget-container {
  overflow: unset; // fix for tooltip display when out of bounds
}