@media (max-width: 786px) {
  .profile-content-top .controls li {
    min-width: 140px;
  }
  .monitor-page {
    .thiamis_info_widget .device-name .truncated {
      max-width: 320px;
    }
    .sensor_widget .sensors-region .device-name .truncated {
      max-width: 520px;
    }
  }
  .firmwares-container .list-table .more-info > .td .actions {
    right: 7px;
  }
  .users-container {
    .card-container {
      width: 100% !important;
    }
    #grid-region .ReactTable .rt-tr-group {
      width: 50%;
    }
  }
  .grid-table .grid-container .card-container .content h2 {
    height: 19px;
  }
  .organizations-container .card-container {
    width: 50% !important;
    float: left;
    .content h2 {
      height: 19px;
    }
  }
  .messages-container {
    .more-info .td .info-row > .col-sm-10 {
      padding-left: 20px;
    }
    .more-info .td .info-row .actions {
      right: 7px;
    }
    .thead > .th:first-child, .tbody > .tr:first-child > .td:first-child {
      display: none;
    }
    .list-table .table .th{
      &.body-col div{
        padding-left: 20px !important;
      }
      &.created-at-col div{
        margin-left: -15px !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .messages-container .list-table .table {
    .th{
      &.created-at-col div{
        margin-left: -5px !important;
      }
    }
    .td, .th{
      &:nth-child(3){
        flex: 50%;
        max-width: 50%;
        min-width: 50%;
      }
      &.actions{
        margin-left: auto;
      }
    }
  }
}
@media (max-height: 590px) {
  .left-panel .support-nav {
    position: static;
    padding-bottom: 65px;

    ul {
      padding-top: 0px;
    }
  }
}
@media (max-width: 767px) {
  .list-table .expanded .user-info {
    height: auto;
    width: 100vw;
    display: inline-block;
  }
  #formation-requests-region.in {
    .list-table .expanded .user-info{
      display: flex;
    }
    .th,.td {
      width: 40% !important;
      min-width: 40% !important;
      max-width: 40% !important;
    }
    .th,.td{
      &:nth-child(5),&:nth-child(4),&:nth-child(3){
        display: none
      }
      &.actions{
        width: 55px !important;
        min-width: 55px !important;
        max-width: 55px !important;
      }
    }
  }
  .configuration-page .configuration-info {
    .sensor-info-list, .sensor-specific-list {
      li {
        font-size: 14px;
      }
    }
    .col-sm-6:nth-child(2) {
      padding-left: 15px;
    }
  }

  .organizations-list-table .list-table .more-info > .td .actions {
    right: 8px;
  }

  .invitations-list-table .table {
    table-layout: fixed;
    .td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.role-col, &.acceptance-state-col, &.created-at-col {
        width: 0;
        opacity: 0;
        overflow: hidden;
        display: none;
      }
      &.organization-id-col {
        min-width: 30% !important;
        max-width: 30% !important;
        width: 30% !important;
        opacity: 1;
      }
    }
    .th {
      &.role-col, &.acceptance-state-col, &.created-at-col {
        width: 0;
        opacity: 0;
        overflow: hidden;
        display: none;
      }
      &.organization_id-col {
        width: 30% !important;
        width: 30% !important;
        width: 30% !important;
        opacity: 1;
      }
    }
  }
  .firmwares-container, .api-container{
    .list-table-container .list-table .table .thead {
      left: 0px;
    }
  }
  .timezone {
    display: none;
  }
  .timezone-short {
    display: block !important;
  }
  .profile-tab {
    .col-sm-4:nth-child(2) {
      padding: 0 15px;
    }
  }
  .password-tab .has-error {
    margin-top: 40px;
  }
  .role-list {
    > div {
      width: 92%;
    }
    .col-sm-6 {
      width: 50%;
    }
  }
  .content-top .controls > li a, .content-top .controls > li button {
    font-size: 12px;
    text-align: center;
  }
  .monitor-page {
    margin-top: 0;
  }
  .modal .modal-dialog, .react-modal-dialog {
    margin: 0;
    padding: 0;
    .modal-content {
      border: 0;
      border-radius: 0;
    }
  }
  .chart_table_widget {
    height: 575px;
    .chart-parameters {
      .sensor-box-container {
        min-width: 0;
        .sensor-box {
          padding-bottom: 5px;
          .parameter-name {
            padding-top: 10px;
          }
          .parameter-statistics {
            width: 100%;
            margin-bottom: 5px;
            div {
              padding: 2px 5px;
            }
            p {
              display: inline-block;
            }
            h2 {
              line-height: 20px;
              display: inline-block;
              float: right;
              width: auto;
            }
          }
        }
      }
    }
  }
  #files-btn {
    display: none;
  }
  .alert-monitor-region .heatmap-wrap .daily-info > span {
    display: none;
  }
  .alert-monitor-region .heatmap-wrap {
    width: 190px;
  }
  .alert-monitor-region .node-block .btn-group span {
    display: none;
  }
  .alert-monitor-region .node-block .btn-group {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .table-responsive {
    border: 0;
  }
  .datapoints-region table tr {
    float: none;
  }
  .device-name {
    margin-top: 4px;
  }
  .shadow-top::before,
  .shadow-bottom::after,
  .dashboard-page .height-extended .data_points_table tbody tr:nth-child(12)::after,
  .data_points_table tbody tr:nth-child(5)::after {
    display: none;
  }
  .node_widget .node-widget-content,
  .blocks_widget .node-widget-content {
    height: auto;
  }
  .content-top .controls {
    height: 37px;
  }
  .main-content-wrap {
    overflow-y: auto;
  }
  .chart-box.form-box.measurements-collapsed {
    .measurements-wrap {
      width: 200px;
    }
  }
  .main-wrap {
    min-height: 0;
  }
  .error-message h3 {
    font-size: 16px;
  }
  .map-page {
    .data_points_table tr td {
      &.name-wrap, &.value-wrap, &.sparkline {
        width: 33.33%;
        line-height: 30px;
      }
    }
  }
  .list-table-container .list-table {
    margin-top: 40px;
  }
  .list-table-container .list-table .table tr th div {
    top: 130px;
    padding: 13px 15px;
    padding-left: 23px;
  }
  .conditions-table {
    position: relative;
    top: 10px;
  }
  .alerts-ct .content-top .select-entity {
    width: 140px;
  }
  .heatmap {
    overflow: auto;
  }
  .messages-container .content-top .actions.opened {
    .btn.trigger {
      position: relative;
      z-index: 200;
    }
    .actions-container {
      z-index: 100;
      left: -55px;
      display: block;
      width: 100px;
    }
  }
  .invitations-list-table .table  {
    .td,
    .th {
      &:first-child {
        width: 50% !important;
        min-width: 50% !important;
        max-width: 50% !important;
      }
      &:last-child {
        width: 20%;
      }
    }
  }
  .messages-container .more-info td .info-row p {
    padding-left: 0;
    white-space: normal;
  }
  .map-page .filter-input-wrap {
    display: none !important;
  }
  #profile .col-sm-9 .details-form {
    margin-top: 15px;
  }
  .alert-form-region {
    padding-top: 58px;
  }
  .modal-dialog .form {
    .form-group {
      .col-sm-3 {
        width: 100%;
      }
    }
  }
  .dialog-content-region {
    .save-form {
      //margin-bottom: 10px;
    }
  }
  .select2-search {
    display: none;
  }
  .node_widget.height-extended .content-region,
  .blocks_widget.height-extended .content-region {
    max-height: none;
  }
  .dashboard-page .widget,
  .dashboard-page .widget-width-sizer {
    position: relative !important;
    top: 0 !important;
    height: auto;
  }
  .widgets-region .container-region > div {
    height: auto !important;
  }
  .node_widget.height-extended .node-widget-content,
  .blocks_widget.height-extended .node-widget-content {
    height: auto;
  }
  #chart-content-region {
    padding: 10px 0 30px;
  }
  .user-details-form {
    padding-top: 0;
    .form-horizontal > .form-group {
      margin: 0 -7.5px 10px;
      .form-group {
        margin-bottom: 10px;
      }
      .col-sm-4 {
        float: left;
        width: 45%;
        padding: 0 7.5px;
      }
      .col-sm-6 {
        float: left;
        width: 49%;
        padding: 0 7.5px;
        margin-bottom: 10px;
        &.col-sm-offset-4 {
          width: 100%;
          padding: 0;
        }
      }
      .remove-role {
        display: block;
        float: right;
        margin-top: 7px;
      }
    }
    .user-fields {
      .form-group {
        padding: 0;
      }
    }
    .form-content, #user-roles .profile {
      padding: 0;
    }
  }
  .user-details-form .form-actions {
    padding: 20px 0;
  }
  #line-btn {
    display: block;
  }
  .modal-dialog {
    padding: 0 5px;
  }
  .modal-dialog .form .form-actions {
    text-align: center;
  }
  .form-control.select2 {
    height: auto;
    min-height: 36px;
  }
  .configuration-page {
    padding-top: 75px;

    .form-box span.control-label {
      width: calc(100% - 130px);
    }
    .configuration-form .table-responsive {
      padding: 10px 15px;
    }
    .configuration-table {
      margin: 0;
      tr td {
        display: block;
        width: 100% !important;
        .form-group {
          margin-bottom: 0;
          label {
            width: 33.33%;
          }
          .select2-container {
            width: 66.66%;
          }
        }
      }
    }
  }

  .alerts-container .list-table-container {
    .list-table .table {
      .td.actions-container {
        display: none;
      }
      .th,
      .td {
        &.choosable-col,
        &:nth-child(3),
        &:nth-child(5) {
          overflow: hidden;
          width: 0;
          opacity: 0;
          padding: 0;
          height: 0;
          display: none;
        }
        &:nth-child(2) {
          flex: 1 0 25%;
          max-width: 25%;
        }
        &:nth-child(4) {
          flex: 37%;
        }
      }
      .tr .td span {
        display: block;
      }
      .actions-col {
        width: 30px;
        min-width: 30px;
      }
      .more-info .td {
        &:nth-child(4){
          display: inline-flex;
          flex-direction: column;
          align-items: flex-start;
          flex: none;
        }
        &.choosable-col,
        &:nth-child(3),
        &:nth-child(2),
        &:nth-child(5) {
          display: none;
        }
        .info-row {
          margin-left: 8px;
        }
      }
    }
  }
  .partnerships-list-table .list-table .table {
    .th,
    .td {
      &:first-child, &:nth-child(3) {
        width: 37%;
      }
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        width: 0;
        opacity: 0;
        overflow: hidden;
        height: 0;
      }
    }
  }
  .files-container .list-table-container .list-table .table{
    .th,
    .td {
      &:nth-child(2),
      &:nth-child(3) {
        overflow: hidden;
        width: 0;
        opacity: 0;
      }
      &:first-child{
        width: 60%;
      }
      &:last-child{
        width: 40%;
      }
    }
  }
  .content-top .help-text {
    display: none;
  }
  .popover-content .user-picture-wrap {
    width: 65px;
    height: 65px;
  }
  .profile-pop .popover .profile-info {
    width: 230px;
    h1 {
      margin-top: 15px;
    }
  }
  .monitor-page {
    overflow-x: hidden;
    padding-top: 70px;
  }
  .one-page-form .header-logo {
    margin-top: 40px;
  }
  .main-nav-overlay {
    display: none;
  }
  .header-region .container-fluid {
    padding: 0 10px !important;
  }
  .filters-collapsed.nav-minified .content-top,
  .nav-minified .filtered-content .content-top {
    width: 100%;
  }
  .nav-minified .main-content-wrap {
    opacity: .5;
  }
  .header-logo {
    width: 20px;
    margin: 0;
    background: url('#{$media-path}/images/drop.svg') no-repeat center;
    background-size: 100%;
  }
  .header-nav {
    display: block;
    margin-right: 0;
    padding-right: 10px;
    padding-left: 10px;
  }
  .header-choose-account {
    position: absolute;
    left: 90px;
    .select2-container {
      width: auto;
      &.select2-container-active {
        width: 140px;
      }
    }
  }
  .header-account-info {
    min-width: 0;
    .header-username {
      display: none;
    }
  }
  .left-panel {
    z-index: 700;
    width: 0;
  }
  .main-content-wrap {
    padding-left: 0;
  }
  .content-top {
    width: 100%;
    height: 60px;
    padding: 10px;
  }
  .dashboard-page {
    padding: 0 2.5px;
    .content-top {
      .col-sm-9 {
        width: 100%;
        .fact-container {
          float: left;
          width: 33.33%;
          padding: 0 2.5px;
          .fact {
            padding: 8px 5px;
            text-align: center;
            span {
              width: 100%;
              max-width: none;
              padding: 3px 10px;
            }
            .number {
              font-size: 22px;
            }
            .span {
              font-size: 12px;
              line-height: 16px;
              max-width: none;
            }
          }
        }
      }
      .col-sm-3 {
        display: none;
      }
    }
    .widget-header .handle {
      opacity: 1;
      .action-resize,
      .action-move {
        display: none;
      }
    }
    .widget,
    .widget-width-sizer {
      width: 100%;
    }
    .tech-datapoints {
      .col-sm-8 {
        float: left;
        width: 66.66%;
      }
      .col-sm-4 {
        float: left;
        width: 33.33%;
      }
    }
    .blocks_widget .node-parameter {
      float: left;
      width: 50%;
    }
    .map_widget {
      &.height-extended,
      .content-region {
        height: 250px !important;
      }
    }
  }
  .table-responsive {
    margin-bottom: 0;
  }
  .filter-input {
    width: 130px;
  }
  .btn-default span,
  .btn-primary span {
    display: none;
  }
  .nav-minified {
    .left-panel {
      width: 170px;
    }
    .main-content-wrap {
      padding-left: 0;
    }
    .content-top {
      width: 100% !important;
    }
  }
  .map-page .filters-collapse {
    top: 15px;
    right: 15px;
  }
  .filters-collapsed .map-page {
    .filter-input-wrap {
      display: none;
    }
    .filters-collapse {
      right: 220px;
    }
  }
  .thiamises-container {
    overflow: hidden;
    .filter-input {
      font-size: 14px;
      width: 165px;
    }
    .content-top .controls li {
      .trigger {
        > span {
          display: none;
        }
      }
      button {
        font-size: 14px;
      }
    }
    .dropdown-toggle{
      padding-left: 3px !important;

      .dropdown-actions {
        display: none;
      }
      .caret-arrow {
        display: inline-block;
      }
    }
  }

  .alerts-container, .files-container, .messages-container {
    .content-top {
      padding-bottom: 10px;
    }
    .list-table-container .list-table {
      margin-top: 103px;
    }
    .tab-content.list-content {
      padding-top: 0;
    }
  }

  .nodes-container {
    .list-table-container .list-table .table tr th div {
      top: 130px;
      padding: 5px;
      padding-left: 0px;
    }
    .content-top {
      padding-bottom: 0px;
    }
    .list-table-container .list-table {
      margin-top: 58px;
    }
    .react-list-container {
      margin-top: 58px;
    }
    .tab-content.list-content {
      padding-top: 0;
    }
  }

  .nodes-container .table {
    .td,
    .th {
      &:first-child {
        width: 0px;
      }
      &:nth-child(4),
      &:nth-child(6) {
        overflow: hidden;
        width: 0;
        opacity: 0;
      }
      &.expanded {
        width: auto;
        opacity: 1;
      }
      &:nth-child(2) {
        flex: 70px;
        max-width: 20%;
      }
      &:nth-child(5) {
        flex: 37%;
      }
      &:nth-child(6) {
        width: 0;
        opacity: 0;
      }
      &:last-child {
        width: 40px;
        opacity: 1;
        .info {
          display: block;
          margin-right: 5px;
          float: right;
        }
      }
    }
    .th:nth-child(2){
      > div {
        padding-left: 15px;
      }
    }
    .th:nth-child(5){
      div {
        margin-left: -15px !important;
        padding-left: 0px;
      }
    }
    .td:nth-child(5){
      padding-left: 10px !important;
    }
    .tr .td {
      padding: 15px 5px;
    }
    .more-info {
      .td {
        width: 100%;
        opacity: 1;
      }
      .info-row {
        .info-row-center {
          p {
            margin-top: 5px;
            padding-left: 10px;
            span {
              display: block;
            }
          }
          .keywords {
            padding-left: 10px;
          }
        }
        .sensor-list {
          padding: 0 7.5px 5px 35px;
          .sensor-box-container {
            float: left;
            width: 100%;
            max-width: 250px;
            &.thiamis-box {
              position: relative;
              left: 0;
            }
            .sensor-box {
              width: 100%;
              max-width: none;
              .col-sm-7 {
                float: left;
                width: 60%;
              }
              .col-sm-5 {
                float: left;
                width: 40%;
              }
              .status-block li {
                display: block;
              }
            }
          }
        }
      }
    }
    .expanded .tr .td {
      padding-bottom: 0px;
    }
  }
  .list-table .info,
  .list-table .actions > a {
    display: block;
    float: right;
    width: 30px;
    margin-right: 0;
    margin-left: 2px;
    opacity: 1;
  }
  .profile-pop .popover {
    .profile-pic {
      max-width: 70px;
    }
  }
  .pp-bottom {
    .col-sm-6 {
      float: left;
      width: 50%;
    }
  }
  .profile-content-top {
    .controls {
      li {
        border-radius: 0;
        @include border-top-radius(5px);
        min-width: 0;
        border-bottom: 0;
      }
    }
  }
  #profile .col-sm-3 .profile-picture {
    max-width: 100px;
    max-height: 100px;
    margin: 10px auto;
    img {
      width: 100%;
    }
  }
  #profile > .row {
    max-width: none;
    padding: 0;
    .col-sm-5 {
      float: left;
      width: 40%;
    }
    .col-sm-7 {
      float: left;
      width: 60%;
    }
  }
  #notifications.tab-pane {
    padding-top: 15px;
    .select2 {
      margin-bottom: 10px;
    }
  }
  .roles-tab.tab-pane {
    padding-top: 15px;
  }
  .details-form-page .tab-content {
    padding: 0 15px;
  }
  #change-password.tab-pane {
    padding-top: 15px;
    .form.form-horizontal {
      margin-top: 0;
      label {
        width: 100%;
      }
    }
  }
  .details-form-page {
    padding-top: 35px !important;
    padding-bottom: 15px;
  }
  .users-container .content-top .filter-input-wrap,
  .organizations-container .content-top .filter-input-wrap {
    display: none;
  }
  .grid-table {
    .user-card {
      height: 135px;
    }
    .picture {
      width: 100px;
      height: 120px;
      padding-top: 20px;
    }
    .content {
      padding-left: 110px;
    }
    .grid-container .card-container {
      h2 {
        font-size: 16px;
      }
      .picture h2 {
        font-size: 24px;
        margin-top: 22px;
        padding-top: 7px;
      }
    }
  }
  .users-container,
  .organizations-container {
    .table {
      .td,
      .th {
        &:nth-child(2) {
          width: 40%;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 0;
          opacity: 0;
        }
      }
      .invitations-list-table .table {
        .td,
        .th {
          &:first-child {
            width: 80%;
          }
        }
      }
      .user-info {
        td {
          &:first-child,
          &:nth-child(2),
          &:nth-child(4) {
            width: 0;
            opacity: 0;
            display: none;
          }
          &:nth-child(5),
          &:nth-child(3) {
            width: 70%;
            padding-top: 10px;
            opacity: 1;
            display: block;
            float: left;
          }
          &:nth-child(6) {
            width: 30px;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
  .organizations-container {
    .content-top {
      height: auto;
      padding: 10px;
      padding-bottom: 0;
      .btn {
        float: left;
        &.btn-primary {
          float: right;
        }
      }
    }
    .list-table-container {
      &::before {
        display: none;
      }
      .organizations-list-table .table {
        .td,
        .th {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            overflow: hidden;
            width: 0;
            opacity: 0;
            display: none;
          }
          &:first-child {
            width: 75%;
          }
          &:nth-child(6) {
            width: 10%;
          }
          &:last-child {
            width: 50px;
            padding-left: 3px;
            display: block;
            opacity: 1;
          }
        }
        .expanded .more-info {
          .node-count-col {
            opacity: 100 !important;
            width: 30px !important;
            height: 30px !important;
            ul {
              position: static !important;
              left: auto !important;
            }
          }
          .gray-text {
            width: 95% !important;
            max-width: 95% !important;
            padding-right: 0px !important;
          }
        }
      }
      .partnerships-list-table .table {
        .td,
        .th {
          &:nth-child(3),
          &:nth-child(4) {
            overflow: hidden;
            width: 0;
            opacity: 0;
          }
          &:first-child,
          &:nth-child(2) {
            width: 40%;
          }
        }
      }
      .invitations-list-table .table {
        .td,
        .th {
          &:nth-child(3) {
            overflow: hidden;
            width: 0;
            opacity: 0;
          }
          &:first-child,
          &:nth-child(2) {
            width: 50%;
          }
        }
      }
      .formation-requests-list-table .table tr {
        td,
        th {
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            overflow: hidden;
            width: 0;
            opacity: 0;
          }
          &:first-child {
            width: 40%;
          }
        }
      }
    }
  }
  .dialog-content-region {
    .form.form-horizontal .col-sm-4 {
      width: 100%;
    }
  }
  .alerts-edit-page {
    .form-box {
      .col-sm-4 {
        padding-left: 15px;
      }
      .col-sm-8 {
        padding-right: 15px;
      }
    }
    .alert-sidebar .form-box:last-child .form-group:nth-child(2) {
      width: 100%;
      padding-right: 0;
      .pl-75 {
        padding-left: 15px;
      }
      .pr-75 {
        margin-bottom: 10px;
        padding-right: 15px;
      }
    }
  }
  .modify-thiamis span {
    display: none;
  }
  .monitor-page {
    .content-top .select-entity {
      width: 195px;
    }
    .chart-box {
      .thiamis-widget-content {
        height: 485px;
      }
      .chart_table_widget {
        height: auto;
        .chart-parameters {
          padding: 0;
          .sensor-list .sensor-box-container {
            max-width: none;
            .sensor-box {
              width: 100%;
              min-height: 0;
              .parameter-statistics {
                width: 100%;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      .chart-wrap {
        z-index: 1;
        width: calc(100% - 200px);
      }
      .measurements-wrap {
        position: absolute;
        z-index: 2;
        right: 0;
        width: 200px;
        .datapoints-region {
          background: $white;
        }
        .tech-datapoints {
          .col-sm-9 {
            float: left;
            width: 75%;
          }
          .col-sm-3 {
            float: left;
            width: 25%;
          }
        }
      }
      h3 {
        > .row {
          > .col-sm-8,
          > .col-sm-4 {
            float: left;
          }
          > .col-sm-4 {
            float: right;
            width: 200px;
          }
          .timezone {
            margin-bottom: 10px;
          }
          .col-sm-8 {
            width: calc(100% - 200px);
            .btn-default {
              span {
                display: none;
              }
            }
          }
          .col-sm-4 {
            padding-left: 15px;
            .btn-default {
              float: left;
              &.toggle-measurements {
                float: right;
                margin: 0;
                padding: 15px;
              }
            }
          }
        }
      }
    }
    .sensor_widget .sensors-region .col-sm-4 {
      width: 100%;
    }
    .form-box .map-wrap {
      width: 100%;
      .content-region > div {
        height: 300px !important;
      }
    }
    .chart_table_widget .chart-parameters {
      padding: 0;
    }
  }
  .thiamis-box.form-box {
    .thiamis-left {
      width: 100%;
      .form-group {
        label:not(.checkbox) {
          float: left;
          width: 125px;
          padding-left: 15px;
          text-align: right;
        }
        > .col-sm-8 {
          float: left;
          width: calc(100% - 125px);
          .col-sm-8 {
            padding-right: 0;
          }
        }
      }
    }
    .thiamis-right {
      width: 100%;
      .map-container {
        height: 270px;
      }
      .location-caption {
        border-left: 1px solid $border-light-gray;
        border-radius: 0;
      }
    }
    .configuration-form {
      float: left;
      width: 100%;
    }
    &.active {
      .thiamis-right .map-container {
        height: 320px;
      }
    }
  }
  .messages-container .list-table .table tr {
    .td,
    .th {
      &:first-child {
        width: 38px;
      }
      &:nth-child(3) {
        overflow: hidden;
        width: 0;
        opacity: 0;
      }
      &:nth-child(2) {
        overflow: hidden;
        width: 66%;
        opacity: 1;
      }
    }
  }
  .organizations-container {
    .grid-table {
      padding-top: 10px;
      .user-picture::before {
        font: normal normal normal 38px/1 FontAwesome;
        top: 50px;
      }
    }
  }

  .nav-tabs > li > a > .title {
    display: none;
  }
}