@media (max-width: 600px) {
  .configuration-page .content-top .btn span {
    display: none;
  }
  .monitor-page .general-info-wrap {
    width: 100%;
    .thiamis-img {
      width: 25%;
    }
    .thiamis-info {
      width: 60%;
      b {
        width: 100%;
      }
    }
  }
  .monitor-page .sensor_widget .sensors-region .device-name .truncated {
    max-width: 410px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .timezone-short {
    display: none !important;
  }
  .profile-tab {
    .col-sm-6 {
      width: 50%;
      float: left;
    }
    .col-sm-4 {
      width: 33.33%;
      float: left;
    }
  }
  .profile-content-top .controls li {
    @include border-top-radius(5px);
    margin-left: 5px;
  }
  .dashboard-page {
    .widget,
    .widget-width-sizer {
      float: left;
      width: 50%;
    }
  }
  .chart-box.form-box.measurements-collapsed .measurements-wrap {
    overflow: hidden;
    width: 0;
  }
  .monitor-page .chart-box .tech-datapoints-region {
    display: none;
  }
  .monitor-page .chart-box h3 .datepicker-region input {
    opacity: 100;
    width: 110px;
    padding-left: 35px;
    &.custom-range {
      width: 280px;
      opacity: 100;
    }
  }
  .chart-box.form-box:not(.measurements-collapsed) .measurements-wrap {
    top: 51px;
    width: 200px;
    position: absolute;
    overflow: scroll;
    right: 15px;
  }
  .details-form-page {
    padding-top: 6px;
  }
  
  .invite-organization-form .form-box {
    .col-sm-6 {
      float: left;
      width: 50%;
    }
  }
  .profile-pop .popover .profile-info {
    width: 260px;
  }
  .grid-table .grid-container .card-container {
    float: left;
    width: 50%;
    min-width: 280px;
  }
  .monitor-page {
    padding-bottom: 0;
    .container {
      z-index: 500;
      .measurements-wrap {
        height: calc(100% - 51px);
        .recent_measurements {
          height: calc(100% - 51px);
        }
      }
      .monitor-page .chart-box h3 .datepicker-region input {
    opacity: initial;
    width: 110px;
    padding-left: 35px;
}
      .chart-box {
        position: static;
        z-index: 900;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .chart-wrap,
        .chart-table-widget-region,
        .chart-region,
        .chart_table_widget {
          position: relative;
          height: 100%;
        }
        .highcharts-container {
          height: 100% !important;
        }
        .page-tab-content {
          position: relative;
          height: calc(100% - 52px);
        }
        .datepicker-region input {
          opacity: initial;
          width: 110px;
          padding-left: 30px;
        }
        h3 > .row {
          > .col-sm-8 .btn-group {
            position: fixed;
            z-index: 505;
            top: 7px;
            left: 15px;
            .btn {
              padding: 8.5px 12px;
            }
          }
          .navtabs-region {
            display: none;
          }
          .timezone {
            margin: 0;
            line-height: 41px;
          }
          .download-chart {
            position: fixed;
            z-index: 505;
            top: 6px;
            padding: 8.5px 12px;
          }
          .print-chart {
            right: 220px;
          }
          .download-chart {
            right: 40px;
            position: absolute;
          }
        }
        .datepicker-region {
          //display: none;
        }
      }
    }
    .chart_table_widget .parameters-list-table {
      margin-bottom: 15px;
    }
    .chart-box .chart_table_widget {
      height: auto;
    }
    .chart_table_widget .tab-content {
      height: 100%;
    }
    .form-box .general-info-wrap {
      width: 100%;
    }
    .sensor_widget .sensors-region .col-sm-4 {
      float: left;
      max-width: 260px;
    }
    .chart-box {
      &.view-table {
        .thiamis-widget-content {
          height: 508px;
        }
        .select-all-wrap {
          right: 0;
          bottom: 8px;
          left: 0;
          margin: auto;
          text-align: center;
        }
      }
      .thiamis-widget-content {
        height: 100%;
      }
    }
    .thiamis-box,
    .sensor-boxes,
    .params-region {
      display: none;
    }
    .chart-box .measurements-wrap .datapoints-region {
      height: calc(100% - 51px);
    }
  }
  .thiamis-box.form-box .thiamis-left .form-group:last-child label {
    width: 33.33%;
  }
  .configuration-page .sensor-icon {
    float: left;
    max-width: 100px;
  }
  .configuration-page .form-box .col-sm-6:first-child ul {
    max-width: 250px;
  }
  .configuration-page {
    .thiamis-box.form-box {
      .thiamis-img {
        float: left;
        width: 20%;
        max-width: 70px;
        margin: 20px 10px 0;
      }
      .thiamis-info {
        width: 70%;
        padding: 10px 0;
      }
    }
  }
}

@media (max-width: 716px) {
  .organization-page {
    .brand-name {
      .col-xs-2 {
        width: 100%;
        text-align: left !important;
      }
      .col-xs-10 {
        width: 100%;
      }
    }
  }
}

@media (max-width: 400px) {
}

@media (max-width: 480px) {
  .monitor-page .sensor_widget .sensors-region .device-name .truncated {
    max-width: 310px;
  }
}
@media (max-width: 475px) {
  .configuration-page .sensors-region .device-name .truncated {
    max-width: 180px;
  }
  .configuration-page .thiamis-region .device-name .truncated {
    max-width: 235px !important;
  }
}
@media (max-width: 400px) {
  .monitor-page {
    .thiamis_info_widget .device-name .truncated {
      max-width: 300px;
    }
    .sensor_widget .sensors-region .device-name .truncated {
      max-width: 230px;
    }
  }
  .configuration-page .sensors-region .device-name .truncated {
    max-width: 128px;
  }
}

@media (max-width: 374px) {
  .configuration-page .sensors-region .device-name .truncated {
    max-width: 100px;
  }
  .configuration-page .thiamis-region .device-name .truncated {
    max-width: 175px !important;
  }
}

@media (width: 375px) {
  .monitor-page .sensor_widget .sensors-region .device-name .truncated {
    max-width: 285px;
  }
  .configuration-page .thiamis-region .device-name .truncated {
    max-width: 230px !important;
  }
}

@media (max-width: 480px) and (orientation: landscape) {
  .monitor-page {
    .download-chart {
      display: none;
    }
    .chart-box h3 > .row {
      > .col-sm-8 {
        width: 70%;
      }
      > .col-sm-4 {
        width: 30%;
      }
    }
  }
}