.organizations-container {
  height: 100%;
  min-height: 100%;
  .organizations-list-table {
    .img-rounded {
      background-color: transparent;
    }
  }
  .tr {
    .orgName {
      color: $header-icons-color;
    }
  }
  .card-container.col-sm-12 {
    width: 33.33%;
  }
  .list-table-container .list-table .table .th div {
    top: 135px;
  }
  .th,
  .td {
    &:first-child {
      width: 60px;
    }
    &:nth-child(2) {
      width: 15%;
      padding-left: 15px;
    }
    &:nth-child(3) {
      width: 40px;
    }
    &:nth-child(4) {
      width: 15%;
      text-align: left;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 6%;
      text-align: center;
    }
    &:nth-child(7) {
      width: 5%;
      text-align: center;
    }
    &:last-child {
      width: 60px;
    }
  }
  .th:nth-child(3) {
    margin-left: -28px;
  }
  .tab-pane#grid-region.active + .tab-pane#list-region{
    .organizations-list-table .thead {
      visibility: hidden;
    }
  }

  .organizations-list-table .td {
    &:nth-child(6) {
      span {
        float: left;
        min-width: 37px;
        margin-left: 15px;
      }
    }
  }
  .td, .th {
    width: 22%;
    min-width: 22%;
    max-width: 22%;
  }
  .formation-requests-list-table {
    th,
    td {
      &:first-child,
      &:nth-child(2) {
        width: 12%;
      }
      &:nth-child(5) {
        text-align: left;
      }
      &:nth-child(6) {
        text-align: left;
      }
    }
    td:nth-child(6) {
      span {
        padding-left: 14px;
      }
    }
  }
  .name-col {
    padding-right: 0;
  }
  .node-count-col > div {
    padding-left: 30px;
  }
  .gray-back {
    .location-col {
      opacity: 0;
    }
  }
  th.node-count-col > div {
    opacity: 0;
  }
  .more-info .node-count-col {
    padding-left: 3px;
  }
  .invitations-list-table {
    .more-info {
      height: 125px;
      .td {
        &.location-col {
          left: 45%;
        }
        &.contact {
          left: 30%;
        }
      }
    }
  }
  .more-info {
    position: relative;
    height: 85px;
    .actions-container .info {
      margin-bottom: 2px;
    }
    .td {
      position: absolute;
      max-height: 85px;
      overflow: auto;
      top: 0;
      &.actions-col {
        position: absolute;
        right: 0;
        text-align: right;
        a {
          float: none;
        }
      }
      &.contact {
        left: 27%;
      }
      &.gray-text {
        padding-right: 0;
        position: absolute;
        top: 0;
        left: 50px;
        ul {
          padding-left: 15px;
          li {
            border-bottom: 1px dashed $border-dark-gray;
            span {
              float: right;
            }
          }
        }
      }
      &.location-col {
        left: 51%;
      }
    }
  }
  .invitations-list-table {
    td,
    th {
      &:last-child {
        width: 95px;
      }
    }
    td:last-child {
      .info:nth-child(2) {
        margin-right: 3px;
      }
    }
  }
  .grid-table {
    .email {
      color: $text-color;
    }
    .contact-name {
      display: block;
      margin-bottom: 5px;
      color: #003c7f;
    }
    .user-card {
      height: 165px;
      .content ul li {
        width: auto;
        margin-bottom: 2px;
      }
    }
    .picture {
      margin-top: 5px;
    }
    .user-picture {
      text-align: center;
      &::before {
        font: normal normal normal 50px/1 FontAwesome;
        position: absolute;
        z-index: 1;
        top: 47px;
        right: 0;
        left: 0;
        content: '\f0f7';
        color: $border-light-gray;
      }
      &.logo-exist::before {
        content: none;
      }
      .img-responsive {
        position: relative;
        z-index: 200;
        top: 0;
        left: 0;
      }
    }
  }
}
#formation-requests-region.in {
  .expanded .user-info {
    align-items: flex-start;

    .td.actions{
      position: relative;
      top: 3px;
    }
  }
  .th.requester-organization-name-col{
    margin-left: 0px;
  }
  .td,.th{
    min-width: 17%;
    max-width: 17%;
    width: 17%;

    &.acceptance-state-col{
      min-width: 8%;
      max-width: 8%;
      width: 8%;
      text-align: left;
    }
    &.created-at-col{
      min-width: 6%;
      max-width: 6%;
      width: 6%;
    }
    &.actions{
      max-width: 55px;
      min-width: 55px;
      width: 55px;
      margin-left: auto;
    }
    &.location-col, &.contact-full-name-col{
      min-width: 22%;
      max-width: 22%;
      width: 22%;
    }
  }
}
#list-region.in .organizations-list-table{
  .node-count-col  {
    display: none;
  }
  .td, .th {
    max-width: 22%;
    min-width: 22%;
    width: 22%;
    position: relative;

    &:nth-child(1){
      max-width: 30%;
      min-width: 30%;
      width: 30%;
    }
    &:nth-child(3){
      max-width: 25%;
      min-width: 25%;
      width: 25%;
    }
    &.created-at-col {
      width: 11%;
      max-width: 11%;
      min-width: 11%;
    }
  }
  .more-info {
    .node-count-col{
      display: block;
      max-width: auto;
      min-width: auto;
      width: auto;
      padding: 0;
      margin: 0;
      position: relative;
      ul{
        position: absolute;
        left: -51px;
      }
    }
    .gray-text{
      padding-right: 25px;
    }
    .location-col ul{
      position: absolute;
      top: -26px;
      line-height: 1.5;
    }
  }
  .actions {
    max-width: 53px;
    min-width: 53px;
    margin-left: auto;
  }
  .more-info {
    align-items: flex-start;
  }
}

#organization-info,
#organization-address {
  .form {
    width: 100%;
    max-width: 690px;
    margin: auto;
    padding: 0 40px;
    .form-content {
      padding: 40px 20px;
    }
  }
  label {
    line-height: 25px;
    float: left;
    padding-left: 15px;
  }
}

#organization-address .form {
  padding-top: 50px;
}

.form .billing-same-as-shipping-group {
  max-width: 740px;
  margin: auto;
  label {
    font-weight: 400;
    margin: 0 auto 15px;
  }
  .checkbox [type='checkbox'] {
    float: left;
    width: 12px;
    margin: 5px 0 0;
  }
  span {
    padding-left: 20px;
  }
  .form-group {
    min-height: auto;
  }
}

.organization-profile {
  margin-top: 50px;

  .profile-picture{
    position: relative;
    background: none;

    &:after {
      font: normal normal normal 50px/1 FontAwesome;
      position: absolute;
      z-index: 1;
      top: 47px;
      right: 0;
      left: 0;
      content: '\f0f7';
      color: #ddd;
      display: none;
    }
    &.no-logo{
      background-color: #fbfbfb;

      &:after {
        display: block;
      }
    }
    img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.invite-organization-form {
  max-width: 770px;
  margin: auto;
  .col-sm-6 {
    &:nth-child(odd) {
      padding-right: 7.5px;
    }
    &:nth-child(even) {
      padding-left: 7.5px;
    }
  }
  .org-name .checkbox {
    margin-top: -10px;
    //TODO: remove once #1498 is done
    label {
      float: none;
    }
  }
  .form-box {
    box-shadow: none;
    border: 0;
    h3 {
      padding: 10px;
      border-radius: 5px;
      background: $select-gray;
      font-weight: 400;
      text-transform: none;
      .fa {
        margin-right: 3px;
      }
    }
  }
  .checkbox-styled-wrapper {
    margin-top: -10px;
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.contact-info .form-group {
  min-height: 63px;
}
