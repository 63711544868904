@media (max-width: 600px) and (orientation: portrait) {
  .monitor-page {
    .chart-box .navtabs-region {
      display: none;
    }
    .chart-box h3 .datepicker-region input {
      opacity: 100;
      width: 110px;
      padding-left: 35px;
      &.custom-range{
        width: 280px;
        opacity: 100;
      }
    }
    .tech-datapoints {
      .col-sm-7,
      .col-sm-5 {
        width: 50%;
        float: left;
      }
    }
  }
  .profile-tab {
    .col-sm-6 {
      &:nth-child(odd) {
        padding-right: 15px;
      }
      &:nth-child(even) {
        padding-left: 15px;
      }
    }
  }
  .tech-datapoints.row {
    > .col-sm-8 {
      width: 66.66%;
      float: left;
    }
    > .col-sm-4 {
      width: 33.33%;
      float: left;
    }
  }
  .profile-content-top .controls li span {
    display: none;
  }
  .monitor-page .chart-box h3 > .row > .col-sm-8 {
    width: 100%;
    padding: 10px 25px 0 15px;
    border-bottom: 1px solid $border-light-gray;
  }
  .monitor-page .chart-box h3 > .row .timezone,
  .monitor-page .chart-box h3 > .row .datepicker-region {
    right: 0;
    display: block;
  }
  .monitor-page .chart-box h3 > .row .timezone {
    float: left;
    width: 50%;
    display: none;
  }
  .users-email {
    display: block;
  }
  .conditions-table {
    tr td {
      display: block;
      width: 100% !important;
      padding-right: 0 !important;
    }
  }
  .profile-content-top .controls {
    li,
    a {
      min-width: 50px;
    }
  }

  .monitor-page .content-top .select-entity {
    width: 150px;
  }
  #profile {
    .form-group {
      width: 100%;
    }
    .col-sm-12:last-child .form-group:nth-child(n+3) {
      width: 100%;
    }
  }
  .invite-organization-form .form-box {
    .col-sm-6 {
      &:nth-child(even) {
        padding-left: 15px;
      }
      &:nth-child(odd) {
        padding-right: 15px;
      }
    }
  }
  .map-page {
    .custom-iw,
    .custom-iw > div:first-child > div:last-child {
      width: 310px !important;
    }
    .custom-iw {
      .tech-datapoints {
        .col-sm-8 {
          float: left;
          width: 66.66%;
        }
        .col-sm-4 {
          float: left;
          width: 33.33%;
        }
      }
    }
  }
  .configuration-page .sensor-info-list {
    //width: 100%;
    padding: 0;
  }
  .info-captions .sensor-icon {
    float: left;
  }
  .monitor-page .chart-box {
    h3 {
      padding: 0;
      border: 0;
      > .row {
        .col-sm-4 {
          display: none;
        }
        > .col-sm-8 .btn-group {
          position: fixed;
          z-index: 505;
          top: 75px;
          left: 60px;
          .btn {
            padding: 8.5px 12px;
          }
        }
      }
    }
    .chart-content-region {
      height: 320px;
    }
  }
  .content-top {
    .actions .trigger {
      position: relative;
      z-index: 200;
      width: 45px;
    }
    .actions-container {
      z-index: 100;
      left: -103px;
      width: 148px;
      border-top: 1px solid $border-dark-gray;
      border-top-left-radius: 5px;
    }
  }
  .configuration-page, .monitor-page {
    .nav-buttons-region {
      .btn-group .btn {
        display: none;
      }
      div[class$="container"] {
        width: 180px;
      }
      .select-entity {
        width: 150px;
      }
      div[class$="control"] {
        border-radius: 5px;
        border-left-width: 1px;
        border-right-width: 1px;
      }
    }
  }
  .configuration-page {
    .form-box {
      .btn-configure {
        display: block;
      }
      .device-name {
        padding: 0;
        line-height: 35px;
      }
      .thiamis-status .device-name {
        padding: 0 0 0 10px;
      }
    }
    .thiamis-region .thiamis-box {
      .reporting-interval {
        label {
          line-height: 23px;
        }
        .col-sm-4,
        .col-sm-8 {
          width: auto !important;
        }
      }
      .thiamis-img {
        height: 60px;
        margin: 10px 0 10px 20px;
        background-position: left center !important;
        background-size: contain;
      }
      .thiamis-info {
        width: auto;
        padding: 15px 0 !important;
        b {
          width: 70px;
          padding-left: 0;
        }
      }
    }
  }
  .configuration-page .sensors-region .form-box {
    position: relative;
    overflow: hidden;
    height: 45px;
    padding: 0 10px 10px;
    .settings {
      z-index: 500;
      .btn-default {
        position: absolute;
        top: 0;
        right: 30px;
      }
    }
    .sensor-extend {
      display: block;
      cursor: pointer;
    }
    &.extended,
    &.active {
      height: auto !important;
      .sensor-extend .fa:before {
        content: $fa-var-chevron-up;
      }
    }
  }
  .monitor-page {
    .params-region {
      display: none;
    }
    .thiamis_info_widget .widget-body label {
      width: 85px;
      padding-right: 0;
    }
    .thiamis-box {
      form .form-group span {
        position: relative;
        left: 15px;
        padding: 0;
      }
      .general-info-wrap {
        .thiamis-img {
          width: 22%;
          height: 85px;
          margin: 0;
          padding: 10px;
          background-position: 10px 30px;
          background-size: 70px;
        }
        .thiamis-info {
          width: 78%;
          margin-top: 0;
          padding: 15px;
          b {
            width: 70px;
          }
        }
      }
    }
    .recent_measurements_widget td p .value-wrap {
      float: right;
    }
    .chart-box {
      &.form-box:not(.measurements-collapsed) .measurements-wrap,
      &.form-box .measurements-wrap {
        position: relative;
        overflow: visible;
        width: 100%;
        border-bottom: 1px solid $border-light-gray;
      }
      .thiamis-widget-content {
        height: auto;
        border: 0;
        box-shadow: none;
      }
      .recent_measurements_widget {
        box-shadow: none;
        td {
          p {
            width: 75%;
            min-width: 50%;
            .name {
              width: auto;
            }
          }
          .sparkline {
            display: inline-block;
            overflow: hidden;
          }
        }
      }
    }
    .recent_measurements_widget,
    .measurements-wrap {
      position: relative;
    }
  }
  .monitor-page .sensor_widget .switch-button {
    right: 5px;
    left: auto;
  }
  .daterangepicker.opensright.dropdown-menu {
    right: 20px !important;
  }
}

@media (max-width: 460px) {
  .users-container {
    #grid-region .ReactTable .rt-tr-group {
      width: 100%;
    }
    .grid-table .picture {
      width: 110px;
      height: 130px;
      padding-top: 13px;

      .img-rounded {
        width: 85%;
      }
    }
    .grid-table .grid-container .card-container .content h2 {
      height: 19px;
    }
  }
}

@media (max-width: 400px) {
  .invitations-list-table .table .tr .td:last-child {
    width: 15%;
  }
}

@media (max-width: 480px) {
  .organizations-container .card-container {
    width: 100% !important;
  }
}
@media (max-width: 380px) {
  .monitor-page .select2-container {
    width: 178px !important;
  }
}
@media (max-width: 320px) {
  .monitor-page .thiamis-box .general-info-wrap {
    .thiamis-img {
      background-size: 55px;
    }
    .thiamis-info {
      li {
        text-align: left;
      }
      b {
        float: left;
        width: 60px;
        text-align: right;
      }
    }
  }
}
