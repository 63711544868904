.fa-custom {
  font: normal normal normal 14px/1 'icomoon';
}
[class^='icon-'],
[class*=' icon-'],
.fa-thiamis,
.fa-device,
.fa-thiamis-1000,
.fa-thiamis-1000-2,
.fa-share-alt,
.fa-building-plus,
.fa-link-plus,
.fa-firmware,
.fa-certificates {
  font-family: 'icomoon';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon {
  &.glyphicon-ok, &.glyphicon-remove {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.glyphicon-ok::before {
    content: "\f00c";
  }
  &.glyphicon-remove::before {
    content: "\f00d";
  }
}

.fa-firmware::before,
.icon-hdd::before {
  content: "\e90f";
}
.fa-calibration::before {
  content: $fa-var-flask;
}

.icon-building-plus::before,
.fa-building-plus::before {
  content: "\e911";
}
.icon-link-plus::before,
.fa-link-plus::before {
  content: "\e912";
}

.icon-gsm-signal-5::before {
  content: '\e906';
}

.icon-gsm-signal-4::before {
  content: '\e907';
}

.icon-gsm-signal-3::before {
  content: '\e908';
}

.icon-gsm-signal-2::before {
  content: '\e909';
}

.icon-gsm-signal-1::before {
  content: '\e90a';
}

.icon-gsm-signal-0::before {
  content: '\e90d';
}

.icon-satelite::before {
  content: "\e91b";
}

.icon-pin::before {
  content: "\e91d";
}

.icon-timezone::before {
  content: "\e91c";
}

.icon-wifi-signal-0::before {
  content: '\e90e';
}

.icon-wifi-signal-1::before {
  content: '\e902';
}

.icon-wifi-signal-2::before {
  content: '\e903';
}

.icon-wifi-signal-3::before {
  content: '\e904';
}

.icon-wifi-signal-4::before {
  content: '\e905';
}

.icon-wifi-signal-5::before {
  content: '\e905';
}

.icon-thiamis-1000::before {
  content: '\e90a';
}

.icon-sensor::before {
  content: '\e61e';
}

.icon-expansion::before {
  content: '\e61f';
}

.icon-thiamis::before {
  content: '\e620';
}

.icon-battery-1::before {
  content: '';
  display: inline-block;
  width: 22px;
  height: 12px;
  position: relative;
  top: 1px;
  background: url('#{$media-path}/images/icons/battery-low.svg');
  background-size: 100%;
  background-repeat: no-repeat;
}

.sensor-box .icon-battery-1::before {
  width: 19px;
  height: 12px;
}

.icon-battery-2::before {
  content: "\e915";
}

.icon-battery-3::before {
  content: "\e914";
}

.icon-battery-4::before {
  content: "\e913";
}

.icon-battery-5::before {
  content: '\e61b';
}

.icon-shared::before,
.fa-share-alt::before {
  content: '\e606';
}

.icon-leased::before {
  content: "\1f304";
}

.icon-battery::before {
  content: '\e916';
}

.icon-alert-plus::before,
.fa-alert-plus::before {
  font-family: 'icomoon';
  content: "\e918";
  font-size: 16px;
  position: relative;
  top: 2px;
}

.icon-sensor-plus::before,
.fa-sensor-plus::before {
  font-family: 'icomoon';
  content: "\e919";
  font-size: 16px;
  position: relative;
  top: 1px;
}

.left-panel {
  .fa.fa-thiamis,
  .fa.fa-thiamis-1000-2,
  .fa-device {
    font: normal normal normal 22px/1 'icomoon';
    margin-top: 9px;
  }
}

.fa-certificate::before {
  content: $fa-certificate;
}

.fa-thiamis::before {
  content: $fa-thiamis-1000-2;
}

.fa-thiamis-1000::before {
  content: $fa-thiamis-1000;
}

.fa-thiamis-1000-2::before,
.fa-device::before {
  content: $fa-thiamis-1000-2;
}

.fa.batt-voltage {
  font-family: 'icomoon';
  font-size: 21px;
  &::before {
    content: '\e61b';
  }
}

.fa-certificates {
  &::before {
    content: $fa-certificate;
  }
}

.fa-share::before {
  font-family: 'icomoon';
  content: $fa-share;
}

.fa-lease::before {
  font-family: 'icomoon';
  content: $fa-lease;
}

.fa-transfer::before {
  content: $fa-var-folder;
}

.fa-reset::before {
  content: $fa-var-remove;
}

.fa-icon-color {
  color: $header-icons-color;
}

.fa.current::before {
  content: '\f0e7';
}

.fa.memory::before {
  content: '\f1c0';
}

.fa-message::before {
  content: '\f0e0';
}

.fa-alert::before {
  content: '\f071';
}

.fa-user_file::before {
  content: '\f15b';
}

.fa-api::before {
  content: $fa-var-cloud;
}

.location-icon {
  display: inline-block;
  margin-right: 10px;
  .location-auto {
    font-family: 'icomoon';
    font-style: normal;
    &::before {
      content: "\e91e";
    }
    &.is-wifi {
      &::before {
        content: "\e902";
      }
    }
  }
  .location-manual {
    font-family: 'icomoon';
    font-style: normal;
    &::before {
      content: "\e91d";
    }
  }
}

// END OF CUSTOM ICONS
.#{$fa-css-prefix}-message::before,
.#{$fa-css-prefix}-messages::before {
  content: $fa-var-envelope;
}

.#{$fa-css-prefix}-node::before,
.#{$fa-css-prefix}-nodes::before {
  content: $fa-var-dot-circle-o;
}

.#{$fa-css-prefix}-organization::before,
.#{$fa-css-prefix}-organizations::before {
  content: $fa-var-building;
}

.#{$fa-css-prefix}-user-file::before,
.#{$fa-css-prefix}-user-files::before {
  content: $fa-var-file;
}

.#{$fa-css-prefix}-users::before {
  content: $fa-var-user;
}

.#{$fa-css-prefix}-dashboard::before {
  content: $fa-var-home;
}

.#{$fa-css-prefix}-analytics::before {
  content: $fa-var-bar-chart-o;
}

.#{$fa-css-prefix}-map::before {
  content: $fa-var-map-marker;
}

.#{$fa-css-prefix}-partnership::before {
  content: $fa-var-link;
}

.#{$fa-css-prefix}-user_invitation::before {
  content: $fa-var-random;
}

.#{$fa-css-prefix}-formation_request::before {
  content: $fa-var-share-square-o;
}

.#{$fa-css-prefix}-monitor::before {
  content: $fa-var-desktop;
}

.device-status {
  &.empty {
    color: $node-status-empty;
  }
  &.disabled {
    i::before {
      content: $fa-var-circle-o;
    }
  }
  &.hibernated,
  &.inactive,
  &.idle {
    color: $node-gray;
    i::before {
      content: $fa-var-circle;
    }
  }
  &.deactivated {
    color: $node-gray;
    i::before {
      content: $fa-var-circle-o;
    }
  }
  &.ok,
  &.online,
  &.active,
  &.resolved,
  &.reporting {
    color: $node-green;
    i::before {
      content: $fa-var-circle;
    }
  }
  &.activated,
  &.offline {
    color: $node-green;
    i::before {
      content: $fa-var-circle-o;
    }
  }
  &.error,
  &.active {
    color: $red-color;
  }
}

.thiamis-img {
  position: relative;
  width: 80px;
  height: 100px;
  margin: 0 20px;
  text-align: center;
  span {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    color: $icon-gray;
  }
  &.thiamis-820,
  &.thiamis-820g {
    background: url('#{$media-path}/images/icons/thiamis-820g.svg') no-repeat center 0;
    background-size: 100%;
  }
  &.thiamis-1000 {
    background: url('#{$media-path}/images/icons/thiamis_1000-2.svg') no-repeat center left;
    background-size: 86%;
  }
  &.thiamis, &.thiamis-1kx, &.thiamis-1000x {
    background: url('#{$media-path}/images/icons/thiamis-1000x.svg') no-repeat center left;
    background-size: 86%;
  }
  &.airthinx {
    background: url('#{$media-path}/images/icons/airthinx.svg') no-repeat center left;
    background-size: 86%;
  }
  &.airthinx2 {
    background: url('#{$media-path}/images/icons/airthinx2.svg') no-repeat center left;
    background-size: 86%;
  }
  &.airthinxpro {
    background: url('#{$media-path}/images/icons/airthinxpro.svg') no-repeat center left;
    background-size: 86%;
    height: 90px;
  }
  &.healthway {
    background: url('#{$media-path}/images/icons/healthway.svg') no-repeat center left;
    background-size: 50%;
  }
  &.airthinx-white {
    background: url('#{$media-path}/images/icons/airthinx_white.svg') no-repeat center left;
  }
}

.icon-arrow-left,
.icon-arrow-right {
  font: normal normal normal 14px/1 FontAwesome;
}

.icon-arrow-left::before {
  content: '\f060';
}

.icon-arrow-right::before {
  content: '\f061';
}

.icon-thiamis-1000-mob::before {
  content: '\e90b';
}



.param-info-icon {
  &.empty {
    color: $border-dark-gray;
    [class^='icon-battery-']::before,
    [class*=' icon-battery-']::before {
      content: '\e916';
      background: none;
    }
    i {
      color: #999;
    }
  }
}

// RIGHT PANEL FILTERS
.#{$fa-css-prefix}-filter-states::before {
  content: $fa-var-map-marker;
}

.#{$fa-css-prefix}-filter-partners::before {
  content: $fa-var-building;
}

.#{$fa-css-prefix}-filter-sensors::before {
  content: $fa-var-dot-circle-o;
}

.filter-shared,
.filter-leased,
.filter-online,
.filter-offline,
.filter-hibernated,
.filter-deactivated,
.filter-activated,
.filter-resolved,
.filter-active,
.filter-inventory {
  .truncated {
    width: 60%;
    &::before {
      font-family: 'FontAwesome';
      display: block;
      float: left;
      width: 14px;
      height: 40px;
      margin: 0 12px 0 5px;
      color: $node-gray;
    }
  }
}

.filter-offline .truncated::before {
  content: $fa-var-circle-o;
  color: $node-green;
}

.filter-hibernated {
  .truncated::before {
    content: $fa-var-circle;
    color: $node-gray;
  }
}

.filter-shared .truncated::before {
  font-family: 'icomoon';
  content: $fa-share;
  color: $node-gray;
}

.filter-leased .truncated::before,
.fa-lease-alt::before {
  font-family: 'icomoon';
  content: $fa-lease;
  color: $node-gray;
}

.filter-online,
.filter-active,
.filter-resolved {
  .truncated::before {
    content: $fa-var-circle;
    color: $node-green;
  }
}

.filter-deactivated .truncated::before {
  content: $fa-var-circle-o;
  color: $node-gray;
}

.filter-activated .truncated::before {
  content: $fa-var-check-circle-o;
  color: $node-green;
}

.filter-active .truncated::before {
  color: $red-color;
}

.right-panel {
  .filter-offline,
  .filter-online {
    .truncated {
      margin-left: 20px;
    }
  }
}

.sensor-icon {
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 50px;
  // SENSOR ICONS
  &[class*='rae'] {
    background-image: url('#{$media-path}/images/icons/MultiRAE.svg');
  }

  &.dusttrak,
  &.dusttrak-8530,
  &.dusttrak-8532,
  &.dusttrak-8533,
  &.dusttrak-8533ep,
  &.dusttrak-8530ep,
  &.dusttrak-8534,
  &.dusttrak-8543 {
    background-image: url('#{$media-path}/images/icons/DustTrak.svg');
  }
  &.dusttrak-8540,
  &.dusttrak-8543,
  &.dusttrakenv-8530,
  &.dusttrak-8540m,
  &.dusttrak-8542m,
  &.dusttrak-8543m {
    background-image: url('#{$media-path}/images/icons/DustTrak_Env.svg');
  }
  &.multirae,
  &.multirae-lite,
  &.multirae-pro {
    background-image: url('#{$media-path}/images/icons/MultiRAE.svg');
  }
  &.minirae-3000,
  &.ppbrae-3000,
  &.ultrarae-3000 {
    background-image: url('#{$media-path}/images/icons/miniRAE_3000.svg');
  }
  &.ppbrae-2000,
  &.minirae-2000,
  &.vrae {
    background-image: url('#{$media-path}/images/icons/ppbRAE_2000.svg');
  }
  &.multirae-plus,
  &.multirae-ir {
    background-image: url('#{$media-path}/images/icons/multirae-plus.svg');
  }
  &.lufft,
  &.ws501,
  &.ws502,
  &.ws500 {
    background-image: url('#{$media-path}/images/icons/ws500.svg');
  }
  &.ws510,
  &.ws310 {
    background-image: url('#{$media-path}/images/icons/ws510.svg');
  }
  &.ws304,
  &.ws303 {
    background-image: url('#{$media-path}/images/icons/ws303.svg');
  }
  &.ws600,
  &.ws400 {
    background-image: url('#{$media-path}/images/icons/ws400.svg');
  }
  &.ws401,
  &.ws601 {
    background-image: url('#{$media-path}/images/icons/ws401.svg');
  }
  &.ws503,
  &.ws504 {
    background-image: url('#{$media-path}/images/icons/ws503.svg');
  }
  &.ws800,
  &.ws700 {
    background-image: url('#{$media-path}/images/icons/ws800.svg');
  }
  &.ws301,
  &.ws302,
  &.ws300 {
    background-image: url('#{$media-path}/images/icons/ws300.svg');
  }
  &.ws200 {
    background-image: url('#{$media-path}/images/icons/ws200.svg');
  }
  &.manta2 {
    background-image: url('#{$media-path}/images/icons/Manta2.svg');
  }
  &.mts {
    background-image: url('#{$media-path}/images/icons/mts.svg');
  }
  &.thiamis-1000 {
    background-image: url('#{$media-path}/images/icons/thiamis_1000.svg');
  }
  &.soundpro {
    background-image: url('#{$media-path}/images/icons/soundpro.svg');
  }
  &[class*='questtemp'] {
    background-image: url('#{$media-path}/images/icons/questtemp.svg');
  }
  &[class*='dataram'] {
    background-image: url('#{$media-path}/images/icons/adr-1500.svg');
  }
  &[class*='cirrus'] {
    background-image: url('#{$media-path}/images/icons/cirrus.svg');
  }
  &.alphasense,
  &.opc-n2,
  &.opc_n2 {
    background-image: url('#{$media-path}/images/icons/alphasense.svg');
  }
  &.adr-1500 {
    background-image: url('#{$media-path}/images/icons/adr-1500.svg');
  }
  &.pdr-1500 {
    background-image: url('#{$media-path}/images/icons/pdr-1500.svg');
  }
  &.ebam {
    background-image: url('#{$media-path}/images/icons/ebam.svg');
  }
  &.davis-vantage-vue,
  &.vantage-vue {
    background-image: url('#{$media-path}/images/icons/davis-vue.svg');
  }
  &.davis-vantage-pro-2,
  &.davis,
  &.vantage-pro-2 {
    background-image: url('#{$media-path}/images/icons/davis-vintage-pro.svg');
  }
  &.diver-dcx,
  &.diverdcx,
  &.mini-diver,
  &.micro-diver,
  &.cera-diver,
  &.ctd-diver,
  &.baro-diver {
    background-image: url('#{$media-path}/images/icons/diver-dcx.svg');
  }
  &[class*='response-one'],
  &[class*='responseone'] {
    background-image: url('#{$media-path}/images/icons/response-one.svg');
  }
  &.macurco {
    background-image: url('#{$media-path}/images/icons/macurco.svg');
  }
  &.isco,
  &.isco-2150 {
    background-image: url('#{$media-path}/images/icons/isco.svg');
  }
  &.isco-signature {
    background-image: url('#{$media-path}/images/icons/isco-signature.svg');
  }
  &.partisol {
    background-image: url('#{$media-path}/images/icons/partisol.svg');
  }
  &.maximet,
  &.maximet-gmx531 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx531.svg');
  }
  &.maximet-gmx100 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx100.svg');
  }
  &.maximet-gmx200 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx200.svg');
  }
  &.maximet-gmx300 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx300.svg');
  }
  &.maximet-gmx301 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx301.svg');
  }
  &.maximet-gmx400 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx400.svg');
  }
  &.maximet-gmx500 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx500.svg');
  }
  &.maximet-gmx600 {
    background-image: url('#{$media-path}/images/icons/maximet-gmx600.svg');
  }
  &.pom {
    background-image: url('#{$media-path}/images/icons/pom.svg');
  }
  &.rad7 {
    background-image: url('#{$media-path}/images/icons/rad7.svg');
  }
  &.icount {
    background-image: url('#{$media-path}/images/icons/IcountPD.svg');
    background-position: center -15px;
    background-size: auto 70px;
  }
  &.fdpm {
    background-image: url('#{$media-path}/images/icons/FDPM.svg');
    background-position: center -15px;
    background-size: auto 70px;
  }
  &[class*='particlesplus'] {
    background-image: url('#{$media-path}/images/icons/ParticlesPlus.svg');
    background-position: right bottom;
    background-size: auto 65px;
  }
  &.gx6000,
  &.gx-6000,
  &.gm460,
  &.gm-460 {
    background-image: url('#{$media-path}/images/icons/gm460.svg');
  }
  &[class*='adam'] {
    background-image: url('#{$media-path}/images/icons/adam.svg');
  }
  &[class*='jerome'] {
    background-image: url('#{$media-path}/images/icons/jerome.svg');
  }
  &[class*='frog'] {
    background-image: url('#{$media-path}/images/icons/frog-4000.svg');
  }
  &[class*='es642'] {
    background-image: url('#{$media-path}/images/icons/es-642.svg');
  }
  &[class*='ysi'] {
    background-image: url('#{$media-path}/images/icons/ysi-exo.svg');
  }
  &[class*='exo'] {
    background-image: url('#{$media-path}/images/icons/ysi-exo.svg');
  }
  &.airmar,
  &.airmar-110wx,
  &.airmar-120wx,
  &.airmar-150wx,
  &.airmar-200wx,
  &.airmar-200wx-ipx7,
  &.airmar-220wx,
  &.airmar-120wxh,
  &.airmar-220wxh {
    background-image: url('#{$media-path}/images/icons/airmar.svg');
  }
  &.airmar-50wxrs,
  &.airmar-150wxs,
  &.airmar-110wxs {
    background-image: url('#{$media-path}/images/icons/airmar-150.svg');
  }
}

small[data-toggle=tooltip] {
  position: relative;
  top: -3px;
  left: 3px;
}

.icon-building-plus {
  width: 18px;
  height: 18px;
  background-image: url('#{$media-path}/images/icons/building-plus.svg');
}

.icon-link-plus {
  width: 18px;
  height: 18px;
  background-image: url('#{$media-path}/images/icons/link-plus.svg');
}

/* Needs proper fix with vector fonts */
.thiamis-box .thiamis-img.heraeus{
  background-position: 0 !important;
}

.thiamis-info-params .thiamis-img.heraeus{
  background-position: center !important;
}
.thiamis-region .thiamis-img.fluidmatix {
  height: 70px !important;
}

.thiamis-img {
  &.heraeus{
    background: url("#{$media-path}/images/heraeus-device-icon-large.svg") no-repeat center
    left;
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.fluidmatix{
    background: url("#{$media-path}/images/fluidmatix-big-device.svg");
    background-size: auto 80%;
    background-repeat: no-repeat;
    background-position: center 30% !important;
  }
  &.airthinx-white {
    background: url("#{$media-path}/images/icons/airthinx_white.svg") no-repeat
    center left;
  }
}
