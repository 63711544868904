.map-page {
  .filters-collapse {
    position: absolute;
    z-index: 100;
    top: 10px;
    right: $right-panel-width + 10px;
  }
  .right-panel {
    padding-top: 15px;
  }
  .content-region {
    position: relative;
    .filter-input-wrap {
      position: absolute;
      z-index: 100;
      top: 15px;
      left: 15px;
      opacity: .95;
    }
  }
}

.marker-tooltip {
  b {
    display: block;
  }
  border:thin 1px #eee;
  background-color:#FFFBF0;
  padding: 5px 10px;
  max-width: 250px;
}

.filters-collapsed .map-page .filters-collapse {
  right: 10px;
}

.node-infowindow {
  width: 400px;
  .datapoints-region {
    overflow: hidden;
    min-height: 100px;
    max-height: 285px;
    padding-left: 1px;
    .node-widget-content {
      overflow-y: auto;
      min-height: 100px;
      max-height: 285px;
      background: $white;
    }
    .data_points_table {
      min-height: 275px;
      tbody tr:nth-child(7)::after {
        @include gradient($white, $grey);
        position: absolute;
        z-index: 200;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        content: '';
        background-color: $white;
        display: none;
      }
      .dp-location {
        display: none;
      }
    }
    &.shadow-top .data_points_table tbody tr:nth-child(7)::after {
      display: none;
    }
  }
  .node_monitor {
    font-size: 14px;
    font-weight: bold;
    line-height: 44px;
    display: block;
    padding: 0 15px;
    a {
      color: $icon-gray;
    }
  }
  hr {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .tech-datapoints span {
    font-weight: bold;
    color: $text-dark;
  }
  .form-group {
    margin-bottom: 0;
    padding: 0 7.5px;
    border-bottom: 1px solid $border-light-gray;
  }
  .data_points_table {
    margin-bottom: 0;
    tr {
      &:first-child td {
        border-top: 0;
      }
      td {
        background-color: $white;
        .name {
          color: $text-dark;
          .device-name {
            font-weight: 300;
            i {
              font-size: 6px;
              position: relative;
              top: -2px;
            }
            b {
              font-weight: 300;
            }
          }
          &:hover {
            color: $default-link-hover-color;
          }
        }
        &.value {
          text-align: right;
        }
      }
      td:first-child {
        padding-left: 15px;
      }
      .sparkline {
        text-align: center;
      }
    }
  }
  .nav-tabs {
    > li > a {
      font-size: 12px;
      padding: 7px 10px;
      color: #555;
      &:hover {
        border: 1px solid $border-dark-gray;
        border-bottom-color: transparent;
        background-color: $white;
      }
    }
    > .active > a {
      font-weight: bold;
      background-color: $white;
    }
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .node-infowindow .datapoints-region .data_points_table tbody tr:nth-child(7)::after {
    top: -2px;
  }
}

@supports (-ms-accelerator:true) {
  .dashboard-page {
    .height-extended .data_points_table tbody tr:nth-child(12)::after {
      @include gradient($white, $grey);
      position: absolute;
      z-index: 200;
      top: -2px;
      left: 0;
      width: 100%;
      height: 5px;
      content: '';
      background-color: $white;
    }
    .height-extended .datapoints-region.shadow-top {
      .data_points_table tbody tr:nth-child(12)::after {
        display: none;
      }
    }
    .widget:not(.height-extended) {
      .data_points_table tbody tr:nth-child(5)::after {
        @include gradient($white, $grey);
        position: absolute;
        z-index: 200;
        top: -2px;
        left: 0;
        width: 100%;
        height: 5px;
        content: '';
        background-color: $white;
      }
      .datapoints-region.shadow-top {
        .data_points_table tbody tr:nth-child(5)::after {
          display: none;
        }
      }
    }
  }
}
