// POPOVER
.popover {
  max-width: 320px;
  p {
    line-height: 20px;
  }
  .popover-content {
    padding: 10px 14px;
    max-height: 250px;
    overflow: auto;
    .btn.btn-danger {
      margin-top: 0;
      display: inline;
    }
    .btn.btn-default {
      border: 1px solid $border-light-gray;
      display: inline;
    }
  }
  .popover-title {
    padding: 8px 14px;
    i {
      position: relative;
      right: -6px;
    }
  }
}

.profile-pop,
.messages-pop,
.notifications-pop {
  float: right;
}

.notifications-popover,
.messages-popover {
  z-index: 13;
  display: none;
}

.popover.bottom {
  padding-top: 5px;
  padding-bottom: 5px;
}

.header-popover {
  line-height: 1em;
  width: 370px;
  max-width: none;
  height: auto;
  padding: 0;
  border-radius: 5px;
  &.bottom {
    padding-bottom: 0;
  }
  .profile-info {
    float: left;
    width: 228px;
    padding-right: 15px;
    text-align: center;
    h1 {
      font-size: 20px;
      margin-top: 40px;
      margin-bottom: 5px;
    }
    span {
      font-size: 12px;
    }
  }
  .pp-bottom {
    @include border-bottom-radius(5px);
    width: 100%;
    border-top: 1px solid $border-dark-gray;
    background-color: $select-gray;
    a {
      font-size: 14px;
      display: block;
      padding: 15px;
      text-align: center;
      color: $text-gray;
    }
    .col-sm-6 {
      padding: 0;
      transition: all .1s linear;
      &:hover {
        background: $grey;
        a {
          color: $default-link-hover-color;
        }
      }
    }
  }
  li {
    &.show-all-messages {
      text-align: center;
      a {
        color: $logo-light-blue;
        font-size: 90%;
      }
    }
    a {
      font-size: $font-size-base;
      display: block;
      padding: 7px 15px;
    }
    &:last-child {
      border: 0;
    }
    span {
      display: block;
      padding: 5px 15px;
      text-align: center;
    }
  }
  .popover-content {
    padding: 0;
  }
}

.profile-pop {
  .profile-pic {
    float: left;
    max-width: 130px;
    margin: 15px 0;
    padding: 0 15px;
    img {
      width: 100%;
      border-radius: 50%;
      background: $light-gray;
    }
  }
  .truncated {
    display: inline-block;
    width: 175px;
  }
}

.messages-pop .popover {
  h4 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    color: $text-gray;
  }
  li {
    border-bottom: 1px solid $border-light-gray;
    &:nth-last-child(n-2) {
      border: 0;
    }
  }
}

.popover-controls {
  width: 300px;
}

.popover.left > .arrow {
  margin-top: -6px;
}

#accept-invite-popover .popover-content {
  overflow: visible;
}
