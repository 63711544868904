.password-tab {
  .form-actions {
    max-width: 585px;
    text-align: center;
  }
  .has-error {
    max-width: 500px;
    margin: 0 auto;
    .help-block {
      position: relative;
      right: auto;
      float: left;
      padding-left: calc(33.33% + 15px);
      text-align: left;
    }
  }
}

.notifications-tab .form.form-horizontal {
  max-width: 780px;
  margin: auto;
  .form-actions {
    padding-right: 0;
  }
  .notifications-group {
    margin-top: 30px;
    &:first-child {
      margin: 0;
      border: 0;
    }
  }
}

.profile-picture {
  overflow: hidden;
  max-width: 140px;
  height: 140px;
  margin-bottom: 10px;
  border-radius: 50%;
  background: $light-gray;
}

.profile-page {
  .right-panel,
  .tagedit-list {
    display: none;
  }
  .content-top {
    padding-right: 15px;
  }
  &.tabed-details-page {
    background: $white;
  }
  .tab-pane {
    min-height: 600px;
    padding-bottom: 25px;
    border-radius: 5px;
    background: $white;
    .has-error {
      .help-block {
        font-size: 12px;
      }
      &.error-bottom .help-block{
        bottom: -25px;
        top: auto;
        font-size: 11px !important;
      }
      &.error-top .help-block{
        top: -25px;
        font-size: 11px !important;
      }
    }
    h3 {
      font-size: 14px;
      margin-top: 0;
      padding: 10px;
      border-radius: 5px;
      background: $select-gray;
    }
  }
}

.profile-tab {
  .details-form {
    max-width: 590px;
    h3 {
      width: 100%;
    }
  }
  .form-actions {
    position: inherit;
    padding: 20px 0;
    text-align: right;
    border: 0;
  }
  .user-fields {
    .row {
      margin: 0;
    }
  }
  .col-sm-6 {
    &:nth-child(odd) {
      padding-right: 7.5px;
    }
    &:nth-child(even) {
      padding-left: 7.5px;
    }
  }
  .col-sm-4 {
    &:nth-child(2) {
      padding: 0;
    }
  }
}

.p-picture {
  padding-top: 20px;
}

.profile-page,
.details-form-page {
  overflow-y: auto;
  height: 100%;
  min-height: 100%;
}

#user-roles {
  .profile {
    padding: 0 15px;
  }
  .form-content p {
    max-width: 750px;
    width: 100%;
    margin: 0 auto 10px;
  }
}

.notification-list {
  li {
    padding: 2px 0;
  }
  .fa-check {
    float: right;
    color: #49b96d;
  }
}

.roles-form .form-horizontal {
  margin: 0 auto;
}

.projects-list {
  a, .add-new-role {
    display: none;
  }
}

.roles-form,
.roles, .projects {
  max-width: 780px;
  margin: auto;
  .col-sm-6 {
    float: none;
    padding: 0 15px 10px;
    border-radius: 5px;
  }
  p {
    // rewrite this later
    display: none;
    margin-top: 20px;
    text-align: center;
  }
  .role-org {
    font-weight: bold;
  }
  .form-group {
    margin-bottom: 5px;
    &:last-child p {
      display: block;
    }
  }
  .remove-role,
  .undo-remove {
    position: absolute;
    top: 8px;
    right: -16px;
  }
  .form-actions {
    max-width: 780px;
    padding: 20px 0;
  }
  .profile,
  .admin-roles {
    .col-sm-6 {
      float: left;
      &:first-child {
        padding-right: 7.5px;
      }
      &:nth-child(2) {
        padding-left: 7.5px;
      }
    }
  }
}

.user-details-form {
  .tab-pane {
    min-height: 300px;
  }
  #profile form {
    max-width: 780px;
    margin: auto;
  }
  .form-actions {
    max-width: 780px;
    padding: 20px 15px;
  }
}

.add-new-role {
  margin-bottom: 30px;
}
